import { forwardRef } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from 'prop-types';
import { useTranslations } from 'next-intl';

import { Avatar } from 'Components/Shared/UI';

const ListItem = forwardRef(props => {
  const {
    itemField: { value, text },
  } = props;
  const t = useTranslations();
  const currentTitle = props.itemField.title || props.itemField.value;
  const title = props.isInPlanner ? value / 3600 : currentTitle;

  return (
    <>
      {props.withAvatar || props.withIcon ? (
        <ListItemIcon>
          {props.withAvatar ? (
            <Avatar
              avatarBG={props.avatarBG}
              avatarColor={props.avatarColor}
              width={25}
              height={25}
              avatarSrc={props.itemField.imgPath}
              name={props.isInPlanner ? text : props.itemField.title}
            />
          ) : (
            <span className={`icon icon-${props.itemField.icon}`}></span>
          )}
        </ListItemIcon>
      ) : null}
      <ListItemText
        primary={
          props.isInPlanner
            ? `${t('update_planner_every')} ${title} ${t(
                'update_planner_hour',
              )}`
            : title
        }
      />
    </>
  );
});

ListItem.defaultProps = {
  withAvatar: false,
  withIcon: false,
  itemField: {},
};
ListItem.propTypes = {
  itemField: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  withAvatar: PropTypes.bool,
  withIcon: PropTypes.bool,
  avatarBG: PropTypes.string,
  avatarColor: PropTypes.string,
};

export default ListItem;
