import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  '@global': {
    footer: {
      backgroundColor: variables.$baseColorDark_90,
      borderTop: `2px solid ${variables.$baseRed}`,
      padding: '30px 0 50px 0',
    },
  },
  'main-container_footer': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ...MediaQuery.between({
      justifyContent: 'flex-start',
    }).sm_md,
  },
  'main-container_footer_item': {
    width: '20%',
    ...MediaQuery.between({
      width: '33.33%',
      '&:nth-child(5)': {
        order: 2,
      },
      '&:nth-child(3)': {
        order: 3,
        marginTop: '20px',
      },
      '&:nth-child(4)': {
        order: 4,
        marginTop: '20px',
      },
    }).sm_md,
    ...MediaQuery.between({
      width: '50%',
      '&:nth-child(3)': {
        marginTop: '20px',
      },
      '&:nth-child(4)': {
        marginTop: '20px',
      },
      '&:nth-child(5)': {
        width: '100%',
        marginTop: '20px',
      },
    }).xs_sm,
    ...MediaQuery.down({
      marginTop: '20px',
      width: '100%',
    }).xs,
    '& form': {
      marginBottom: '16px',
    },
    '& span, & p, & a': {
      display: 'block',
      marginBottom: '10px',
      cursor: 'pointer',
      fontSize: '13px',
      color: '#fff',
    },
  },
});
