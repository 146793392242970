import { memo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { unreadCountAsBadge } from 'utils/handlers';

const ShoppingCart = memo(
  ({ count }) => (
    <Link href="/my-sexjobs/cart" prefetch={false}>
      <span className="cart-wrapper">
        <span className="icon-Shopping-Cart"></span>
        {unreadCountAsBadge(count)}
      </span>
    </Link>
  ),
  (prevProps, nextProps) => nextProps.count === prevProps.count,
);

ShoppingCart.defaultProps = {
  count: 0,
};
ShoppingCart.propTypes = {
  count: PropTypes.number,
};
export default ShoppingCart;
