import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery } from 'static/styles/jss/abstracts/mixins';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  '@global': {
    header: {
      top: '0',
      position: 'absolute',
      transition: 'top 0.3s',
      zIndex: '999',
      left: '0',
      width: 'inherit',
      minHeight: variables.headerHeightMB,
      ...MediaQuery.down({
        overflow: 'hidden',
      }).xs,
      ...MediaQuery.up({
        minHeight: variables.headerHeightDT,
        position: 'fixed',
      }).sm,
      '& .row': {
        alignItems: 'center',
      },
    },
  },
  'header-container': {
    '& .discrete-header-wrapper': {
      display: 'none',
    },
    '& .base-header-wrapper': {
      padding: '19px 8px',
      background: 'linear-gradient(180deg, #0558A8 0%, #003F8F 100%)',
      borderBottom: `2px solid ${variables.$baseRed}`,
    },
  },
  'header-container--discrete': {
    padding: '19px 16px',
    borderBottom: '2px solid #fff',
    display: 'flex',
    alignItems: 'center',
    ...MediaQuery.down({
      height: variables.headerHeightMB,
    }).xs,
    ...MediaQuery.up({
      height: variables.headerHeightDT,
    }).sm,
    '& .base-header-wrapper': {
      display: 'none',
    },
    '& .discrete-header-wrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& i.icon-Home': {
        fontSize: '24px',
        color: 'var(--base-color)',
        cursor: 'pointer',
      },
      '& p': {
        fontSize: '24px',
        color: 'var(--base-color)',
        fontWeight: 'bold',
        margin: 0,
      },
    },
  },
  'mobile-toggle_search-bar': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .icon-Search': {
      fontSize: '20px',
      color: '#fff',
      marginRight: '20px',
      cursor: 'pointer',
      '&.opened-search-icon': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          left: '50%',
          width: 'calc(100% + 15px)',
          height: 'calc(100% + 15px)',
          background: variables.$baseColorDark_60,
          zIndex: '-1',
          borderRadius: '50%',
        },
      },
    },
  },
  'logo-wrapper': {
    display: 'flex',
    alignItems: 'center',
    '& .toggle-categories_menu': {
      display: 'none',
    },
    ...MediaQuery.down({
      '& .toggle-categories_menu': {
        display: 'block',
        fontSize: '20px',
        color: '#fff',
        cursor: 'pointer',
        marginRight: '16px',
      },
    }).sm,
    '& .logo-elem': {
      display: 'block',
      cursor: 'pointer',
      '& img': {
        height: '47px',
        width: '180px',
        ...MediaQuery.between({
          width: '133px',
          height: '38px',
        }).xss_sm,
        ...MediaQuery.down({
          width: '101px',
          height: '27px',
        }).xss,
      },
    },
  },
});
