import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import Popover from '@material-ui/core/Popover';
import { useDispatch } from 'react-redux';
import useMemoSelector from 'hooks/useMemoSelector';
import Image from 'next/image';

import { useDropDownPopoverStyles } from 'Components/Shared/UI/makeStylesUI';

import { languages } from 'utils/constants';

import { setDictionary } from 'store/actions';
import { getLanguage } from 'store/reselect';

const LanguageSwitcher = ({ isDialog }) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const lang = useMemoSelector(getLanguage);
  const [anchorEl, setAnchorEl] = useState(null);
  const language = languages.find(e => e.key === lang);

  const classesDropDown = useDropDownPopoverStyles({
    margin: isDialog ? '10px 0 0 0 ' : '16px 0 0 -10px',
  });

  const selectLanguage = e => {
    setAnchorEl(null);
    dispatch(setDictionary({ locale: e.key, cb: () => {} }));
  };

  return (
    <div className="language-wrap" data-icon-active={!!anchorEl}>
      <Image
        alt={lang}
        width="18px"
        height="18px"
        loading="lazy"
        src={language?.imgPath}
        onClick={event => setAnchorEl(event.currentTarget)}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        classes={classesDropDown}
        id={anchorEl && 'simple-popover'}
        onClose={() => setAnchorEl(null)}
        PaperProps={{ style: { width: '130px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: isDialog ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isDialog ? 'right' : 'left',
        }}
      >
        <ul className="language-dropdown_popover">
          {languages.map(lang => (
            <li
              key={lang.value}
              onClick={() => selectLanguage(lang)}
              className={clsx({
                'active-language': language?.value === lang.value,
              })}
            >
              <img src={lang.imgPath} alt="avatar" />
              {t(lang.title)}
            </li>
          ))}
        </ul>
      </Popover>
    </div>
  );
};

export default LanguageSwitcher;
