import { useEffect, useState } from 'react';

let resize_ob;
const UseResizeNodeObserver = node => {
  const [rect, setRect] = useState({ w: null, h: null });

  useEffect(() => {
    if (typeof window !== 'undefined' && window.ResizeObserver) {
      resize_ob = new ResizeObserver(entries => {
        // since we are observing only a single element, so we access the first element in entries array
        const rect = entries[0].contentRect;

        setRect({
          w: rect.width,
          h: rect.height,
        });
      });
      resize_ob.observe(node || document.body);
    }
  }, [node]);

  useEffect(
    () => () => {
      resize_ob?.unobserve(node || document.body);
    },
    [],
  );

  return {
    nodeWidth: rect.w,
    nodeHeight: rect.h,
  };
};
export default UseResizeNodeObserver;
