import { createSelector } from 'reselect';

import { _creditsState } from './selectors';

const getCredits = () =>
  createSelector([_creditsState], items => items.credits);

const getPaymentsMethods = () =>
  createSelector([_creditsState], items => items.paynlMethods);

export { getCredits, getPaymentsMethods };
