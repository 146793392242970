import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';

import { Select } from 'Components/Shared/UI';

import { adjustDays } from 'utils/handlers';

import useStyles from './styles';

const DateOfBirth = props => {
  const t = useTranslations();
  const styles = useStyles();
  const [monthMaxDay, setMonthMaxDay] = useState('31');
  const watchValueOfYear = props.watchYear;
  const watchValueOfMonth = props.watchMonth;

  const months = [
    { title: t('months_january'), value: '01' },
    { title: t('months_february'), value: '02' },
    { title: t('months_march'), value: '03' },
    { title: t('months_april'), value: '04' },
    { title: t('months_may'), value: '05' },
    { title: t('months_june'), value: '06' },
    { title: t('months_july'), value: '07' },
    { title: t('months_august'), value: '08' },
    { title: t('months_september'), value: '09' },
    { title: t('months_october'), value: '10' },
    { title: t('months_november'), value: '11' },
    { title: t('months_december'), value: '12' },
  ];

  const days = maxDay =>
    Array.from({ length: maxDay }, (_, i) => i + 1).reduce((acc, item) => {
      const changedItem = `0${item}`.slice(-2);

      acc.push({ title: item, value: changedItem });
      return acc;
    }, []);

  const isThereError = [
    props.errors[props.selects.day.name],
    props.errors[props.selects.month.name],
    props.errors[props.selects.year.name],
  ].some(e => e);

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const begin = currentYear - 21;
    const yearNow = new Date().getFullYear();
    return Array.from({ length: 79 }, (_, i) => begin - i).reduce(
      (acc, item) => {
        const changedItem = String(item);

        if (item <= yearNow) acc.push({ title: item, value: changedItem });
        return acc;
      },
      [],
    );
  }, []);

  const bottomWrap = useMemo(() => {
    if ((props.helperText || props.isRequired) && !isThereError) {
      const text =
        props.helperText || (props.isRequired ? t('required_field_text') : '');
      return text ? <p className="helper-text_bt">{text}</p> : null;
    }
    if (isThereError && props.bottomText)
      return (
        <span className="error-line_bt" style={{ color: 'red' }}>
          {t('required_field_text')}
        </span>
      );
    return null;
  }, [props.helperText, props.isRequired, isThereError, t]);

  useEffect(() => {
    if (watchValueOfYear || watchValueOfMonth) {
      adjustDays(watchValueOfYear, watchValueOfMonth, setMonthMaxDay);

      if (props.watchDay && props.watchDay > monthMaxDay) {
        props.setValue(props.selects.day.name, '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchValueOfYear, watchValueOfMonth, props.watchDay, monthMaxDay]);

  return (
    <div
      className={`${styles['date-of-birth-container']} ${
        isThereError ? 'with-error-fields' : ''
      }`}
    >
      <h5 className={clsx('like-field-label', { error: isThereError })}>
        {props.title || t('create_profile_title_1_text_3')}
      </h5>
      <div className="wrapper-fields">
        <Controller
          as={<Select />}
          errors={props.errors.day?.message}
          {...props.selects.day}
          control={props.control}
          options={days(monthMaxDay)}
        />
        <Controller
          as={<Select />}
          {...props.selects.month}
          errors={props.errors.month?.message}
          control={props.control}
          options={months}
        />
        <Controller
          as={<Select />}
          {...props.selects.year}
          errors={props.errors.year?.message}
          control={props.control}
          options={years}
        />
      </div>
      {bottomWrap}
    </div>
  );
};

DateOfBirth.defaultProps = {
  bottomText: true,
};

DateOfBirth.propTypes = {
  selects: PropTypes.object.isRequired,
  watchYear: PropTypes.any,
  watchMonth: PropTypes.any,
  isRequired: PropTypes.bool,
  helperText: PropTypes.string,
  control: PropTypes.object,
  errors: PropTypes.object,
};
export default React.memo(DateOfBirth);
