// import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery, Shadow } from 'static/styles/jss/abstracts/mixins';
import { makeStyles } from '@material-ui/core/styles';

export const useSelectStyle = makeStyles({
  searchSuggestions: {
    '& .suggestions-wrapper': {
      display: ({ isSuggestionsOpen, isMobileSearchOpen }) =>
        isSuggestionsOpen || isMobileSearchOpen ? 'block' : 'none',
    },
    '& .input-element-wrapper input': {
      '&:hover': {
        boxShadow: 'none !important',
      },
    },
    '& .distances-select': {
      '& .MuiInputBase-root.MuiInput-root': {
        '&:hover': {
          boxShadow: 'none',
        },
        borderRadius: 0,
        position: 'relative',
        right: '1px',
      },
    },
  },
});

export const useStyles = makeStyles({
  'search-mobile': {
    height: '100vh',
    background: 'white',
    padding: '10px 0',
  },
  'search-wrapper': {
    '-webkit-perspective': 1000,
    '-webkit-backface-visibility': 'hidden',
    ...MediaQuery.down({
      marginTop: '20px',
    }).md,
    ...MediaQuery.down({
      height: '0',
      overflow: 'hidden',
      marginTop: '0',
      zIndex: '-1',
      visibility: 'hidden',
      opacity: '0',
      transition: '.2s ease-in-out',
      '&.opened_mobile': {
        opacity: '1',
        zIndex: '1',
        visibility: 'visible',
        marginTop: '20px',
        height: '42px',
      },
    }).sm,
    '& .main-search-form': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      ...MediaQuery.down({
        flexDirection: 'column',
      }).xs,
      '& .search-input': {
        ...MediaQuery.up({
          width: 'calc(100% - 225px) !important',
        }).xs,
        '& input, & p': {
          border: 'none',
          ...MediaQuery.up({
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
          }).xs,
          borderRadius: '2px 0 0 2px !important',
        },
      },
      '& .search-select': {
        ...MediaQuery.down({
          width: '100%',
          marginTop: '8px !important',
        }).xs,
        '& .MuiInputBase-root': {
          borderLeft: '1px solid #D8D8D8',
          ...MediaQuery.up({
            borderRadius: `0 ${variables.$input.$radius} ${variables.$input.$radius} 0`,
          }).xs,
          '& .MuiSvgIcon-root': {
            top: 'calc(50% - 12px)',
            right: '4px',
            fontSize: '24px',
            ...MediaQuery.up({
              right: '90px',
            }).xs,
          },
          '&:hover': {
            boxShadow: 'none',
          },
        },
        '& .MuiSelect-root': {
          transition: '0s',
          '& > div': {
            overflow: 'hidden',
          },
          ...MediaQuery.up({
            paddingRight: '110px !important',
          }).xs,
          '&:hover': {
            ...Shadow('none'),
          },
        },
        '&.select-opened': {
          '& > div.Mui-focused': {
            ...Shadow('none !important'),
            '& .MuiSelect-root': {
              ...Shadow('none !important'),
            },
          },
        },
      },
      '& .search-button': {
        '& button': {
          borderRadius: '0 2px 2px 0',
        },
        zIndex: '222',
        right: '0',
        ...MediaQuery.down({
          width: '100%',
          marginTop: '8px !important',
          marginRight: 'auto !important',
        }).xs,
        ...MediaQuery.up({
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          position: 'absolute',
        }).xs,
      },
    },
  },
  'suggestions-list': {
    padding: 0,
    margin: '0',
    listStyleType: 'none',
    overflowX: 'hidden',
    // ...ScrollList(220),
  },
  'suggestions-wrapper': {
    '& > hr': {
      width: '98%',
      margin: '0px auto 10px auto',
      borderColor: '#C7C7C7',
    },
    backgroundColor: '#fff',
    paddingBottom: '6px',
    position: 'absolute',
    width: 'calc(100% - 1px)',
    borderRadius: '0 0 2px 2px',
  },
});

export const useSearchByTextStyles = makeStyles({
  searchByTextStyle: {
    padding: '9px 20px',
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
});

export const useLocationStyles = makeStyles({
  postalCodeStyle: { fontSize: '11px' },
  adCountStyle: { fontSize: '11px', color: 'gray' },
  countryStyle: {
    fontSize: '11px',
    color: 'gray',
    textTransform: 'capitalize',
  },
  locationWrapper: { display: 'flex', flexDirection: 'column' },
  locationIconStyle: { marginRight: '5px', marginBottom: '3px', color: 'gray' },
  settlementStyle: { fontWeight: 'bold', opacity: '70%', color: 'black' },
  settlementLetterStyle: {
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '9px 20px',
    opacity: '70%',
  },
  selectedProvinceStyle: {
    fontWeight: 'bold',
    opacity: '0.9',
    color: 'black',
    textTransform: 'capitalize',
  },
  provinceArrowIconStyle: { color: 'black' },
  provinceInfoTextStyle: { fontSize: '11px', color: 'gray' },
  provinceStyle: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    opacity: '70%',
    color: 'black',
  },
  dividerStyle: {
    borderBottom: '1px solid #e5e5e5',
    marginBottom: '5px',
    width: '95%',
    margin: '0 auto 5px',
    color: 'gray',
  },
});

export const useSuggestionItemStyle = makeStyles({
  suggestionItem: {
    '&.location-item': { padding: '4px 20px' },
    '&.provincesList': {
      justifyContent: 'space-between',
      padding: '9px 40px',
    },
    '& > div': {
      marginLeft: '15px',
    },
    display: 'flex',
    alignItems: 'center',
    padding: '9px 20px',
    cursor: 'pointer',
    '& .icon-Chevron---Right': {
      position: 'absolute',
      right: '5px',
      fontSize: '12px',
      color: variables.$darkColor80,
      top: '0',
      bottom: '0',
      margin: 'auto',
      height: '12px',
    },
    '& .location-item-description': {
      fontSize: '13px',
      marginLeft: '10px',
      color: variables.$darkColor80,
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },

  nearbyIcon: {
    marginRight: '5px',
    marginBottom: '3px',
    color: '#3478d1',
  },

  nearbyText: {
    fontSize: '14px',
    color: '#3478d1',
  },
});
