import { combineReducers } from '@reduxjs/toolkit';

import baseReducer from './reducers/base';
import homeReducer from './reducers/home';
import adsReducer from './reducers/ads';
import msgReducer from './reducers/messenger';
import authReducer from './reducers/auth';
import creditsReducer from './reducers/credits';
import toolkitSlices from './toolkit';

const rootReducers = combineReducers({
  base: baseReducer,
  home: homeReducer,
  ads: adsReducer,
  auth: authReducer,
  msg: msgReducer,
  credits: creditsReducer,
  ...toolkitSlices,
});

// eslint-disable-next-line import/prefer-default-export
export function createReducer(state, action) {
  // if you want to reset redux store
  // action.type === Types.USER_LOGOUT ? undefined : state
  return rootReducers(state, action);
}
