import { Portal } from '@material-ui/core';
import useLockBodyScroll from 'hooks/useLockBodyScroll';

import useStyles from './styles';

const AsideMenu = ({ children }) => {
  const { asideMenuContent, asideMenuContainer } = useStyles();

  useLockBodyScroll();

  return (
    <Portal>
      <div className={asideMenuContainer}>
        <div className={asideMenuContent}>{children}</div>
      </div>
    </Portal>
  );
};

export default AsideMenu;
