import clsx from 'clsx';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { useTranslations } from 'next-intl';

import HeaderBtn from 'Components/Dumb/HeaderButton';
import LanguageSwitcher from 'Components/LanguageSwitcher';

import { baseSelector } from 'store/reselect';

const NotLogged = () => {
  const t = useTranslations();
  const { pageData } = useSelector(baseSelector());

  return (
    pageData?.page && (
      <>
        {pageData.layout === 'auth' && (
          <span className="user-question">
            {t(
              pageData.page === 'register'
                ? 'header_login_question_text'
                : 'header_register_question_text',
            )}
          </span>
        )}
        <LanguageSwitcher />
        <Link
          prefetch={false}
          href={pageData.page === 'login' ? '/register' : '/login'}
        >
          <span
            className={clsx('user_icon', {
              user_icon__exist: pageData.page === 'any',
            })}
          >
            <span className="icon-My-Account" />
          </span>
        </Link>
        <HeaderBtn />
      </>
    )
  );
};

export default NotLogged;
