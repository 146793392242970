import { useCallback, useEffect, useState } from 'react';

import { isTouchDevice } from 'utils/handlers';

export default function useCanHover() {
  const [isMobileView, setMobileView] = useState(false);
  const isSSR = typeof window !== 'undefined';

  const matchHover = useCallback(() => {
    if (isSSR) {
      setMobileView(isTouchDevice());
    }
  }, [isMobileView, isSSR]);

  useEffect(() => {
    if (isSSR) {
      matchHover();
      isSSR && window.addEventListener('resize', matchHover);
    }
    return () => {
      isSSR && window.removeEventListener('resize', matchHover);
    };
  }, []);

  return { canHover: isMobileView, isSSR };
}
