import { initialState as ads } from 'store/reducers/ads';
import { initialState as auth } from 'store/reducers/auth';
import { initialState as base } from 'store/reducers/base';
import { initialState as credits } from 'store/reducers/credits';
import { initialState as home } from 'store/reducers/home';
import { initialState as messenger } from 'store/reducers/messenger';

export default {
  ads,
  auth,
  base,
  home,
  credits,
  messenger,
};
