import Types from '../types';

export const initialState = {
  credits: null,
  paynlMethods: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SAVE_CREDITS:
      return { ...state, credits: action.payload };
    case Types.SAVE_PAYMENT_METHODS:
      return { ...state, paynlMethods: action.payload.payment_methods };
    default:
      return state;
  }
};

export default reducer;
