import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { Shadow } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'main-wrapper-sj-menu': {
    '& .sj-menu_title': {
      border: '1px solid #003271',
      backgroundColor: variables.$baseColorDark_30,
      marginBottom: '15px',
      '& h3': {
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '0px',
        color: '#fff',
        padding: '13px 10px 13px 30px',
      },
    },
    '& .sj-main-list--block': {
      marginBottom: '16px',
      ...Shadow('0 2px 4px 0 rgba(0,0,0,0.2)'),
      '& .side-bar-dialog--title': {
        display: 'none',
      },
      '& .side-bar-dialog--title-container': {
        display: 'flex',

        justifyContent: ({ companyType }) =>
          companyType === 'couple' ? 'center' : 'flex-start',
        borderBottom: '2px solid #C5C5C5',
        '& .single-title, & .company-title': {
          paddingLeft: '20px',
        },
      },

      '&.side-bar-dialog': {
        width: '210px',
        borderRadius: '3px',
        marginBottom: '0px',
        ...Shadow('none'),
        '& .side-bar-dialog--title': {
          display: 'block',
          color: '#020202',
          fontSize: '16px',
          fontWeight: 'bold',
          background: '#fff',
          margin: '0',
          padding: '20px 0',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          '&.separator': {
            paddingLeft: '5px !important',
            paddingRight: '5px !important',
          },
        },
        '& .sj-main--list': {
          backgroundColor: '#fff',
          '& li': {
            '& span': {
              padding: '6px',
              color: '#0e0e0e',
              cursor: 'pointer',
              '&:not(.active-link-menu)': {
                '&:hover': {
                  background: 'rgba(0, 0, 0, 0.06)',
                },
              },
            },
            '& .active-link-menu': {
              backgroundColor: variables.$primaryColor,
              color: '#fff',
              '& > span': {
                color: '#fff',
              },
            },
          },
        },
      },
    },
    '& .count-badge': {
      width: '20px',
      height: '20px',
      display: 'flex',
      fontSize: '11px !important',
      background: variables.$baseRed,
      alignItems: 'center',
      lineHeight: '13px',
      borderRadius: '50%',
      justifyContent: 'center',
      marginLeft: '10px',
      color: '#fff !important',
    },
    '& .sj-main--list': {
      margin: '0px',
      listStyleType: 'none',
      padding: '0 0 5px 0',
      backgroundColor: variables.$contentBlueColor,
      '& li': {
        '& span': {
          padding: '6px',
          cursor: 'pointer',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          fontSize: '15px',
          '& > span[class^=icon-]': {
            color: variables.$helperTextColor,
            fontSize: '20px',
            marginRight: '10px',
          },
          '&:not(.active-link-menu)': {
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.06)',
            },
          },
        },
        '& a.active-link-menu': {
          backgroundColor: variables.$baseColorDark_60,
          color: '#B0F2F1',
          '& > span': {
            color: '#B0F2F1',
          },
        },
      },
    },
  },
});
