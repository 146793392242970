import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { useSelector } from 'react-redux';
import useOnClickOutside from 'hooks/use-onClick-outside';

import Image from 'Components/Shared/UI/Image';
import MySJMenu from 'Components/Dumb/MySJMenu';
import { useDropDownPopoverStyles } from 'Components/Shared/UI/makeStylesUI';

import { baseSelector } from 'store/reselect';

const UserAvatar = props => {
  const baseState = useSelector(baseSelector());
  const { deviceParams } = baseState;
  const { deviceType } = deviceParams;
  const menuRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = useCallback(
    (event, val) => {
      if (deviceType === 'mobile') {
        setOpenMenu(val);
      } else {
        setAnchorEl(event.currentTarget);
      }
    },
    [deviceType],
  );

  useOnClickOutside(menuRef, () => {
    if (openMenu && deviceType === 'mobile') {
      toggleMenu(null, false);
    }
  });

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classesDropDown = useDropDownPopoverStyles({
    margin: '10px 0 0 0px',
  });

  useEffect(() => {
    const isSSR = typeof window !== 'undefined';
    if (isSSR && deviceType === 'mobile') {
      document.body.classList[openMenu ? 'add' : 'remove']('body--fixed');
    }
  }, [openMenu, deviceType]);

  return (
    <div
      className={`avatar-wrapper ${openMenu ? 'menu-opened' : ''}`}
      data-intro="account-icon"
    >
      <Image
        src={props.src}
        defaultSrc="/images/avatar.png"
        onClick={event => toggleMenu(event, true)}
      />
      {deviceType === 'mobile' ? (
        <div className="user-menu-mobile">
          <Image
            src={props.src}
            defaultSrc="/images/avatar.png"
            onClick={event => toggleMenu(event, false)}
          />
          <MySJMenu
            dialog
            handleClick={e => toggleMenu(e, false)}
            refBind={menuRef}
            delay={400}
          />
        </div>
      ) : (
        <Popover
          classes={classesDropDown}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              width: '210px',
              borderRadius: '3px',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MySJMenu dialog handleClick={() => setAnchorEl(null)} />
        </Popover>
      )}
    </div>
  );
};
UserAvatar.propTypes = {
  src: PropTypes.string,
};
export default React.memo(UserAvatar);
