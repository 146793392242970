import isEmpty from 'utils/isEmpty';

const getApproveData = (item, name) => {
  if (Array.isArray(item[name])) {
    return (
      item.hasOwnProperty('_changes') &&
      item._changes.hasOwnProperty('fields') &&
      item._changes.fields.hasOwnProperty(name) &&
      item._changes.fields[name].map(({ value }) => ({
        id: value.id,
        name: value.value,
      }))
    );
  }
  const value = item[name]
    ? item[name]
    : item.hasOwnProperty('_changes') &&
      item._changes.hasOwnProperty('fields') &&
      item._changes.fields.hasOwnProperty(name) &&
      item._changes.fields[name][0].value.value;

  return value || '';
};

export const getChangesData = (item, name) => {
  if (isEmpty(item._changes?.fields)) {
    return item[name];
  }
  const changedResult = item._changes?.fields?.[name]?.[0];

  if (Array.isArray(item[name])) {
    return changedResult && changedResult.status !== 'declined'
      ? item._changes?.fields?.[name]?.map(({ value }) => ({
          id: value.id,
          name: value.value,
          value: value.value,
        }))
      : item[name];
  }
  return changedResult && changedResult.status !== 'declined'
    ? changedResult.value.value
    : item[name] || '';
};

export const getDeclinedData = (data, fieldName) => {
  if (isEmpty(data._changes?.fields)) {
    if (Array.isArray(data[fieldName])) {
      return [];
    } else {
      return '';
    }
  }

  if (Array.isArray(data[fieldName])) {
    return data._changes?.fields?.[fieldName].map(({ value }) => ({
      id: value.id,
      name: value.value,
      value: value.value,
    }));
  }

  return data._changes?.fields?.[fieldName]?.[0]?.value.value;
};

export default getApproveData;
