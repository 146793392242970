import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { Shadow } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'input-wrap': {
    userSelect: 'none',
    position: 'relative',
    width: 'fit-content',
    minWidth: 'fit-content',
    fontFamily: 'Arial',
    height: 'fit-content',
    '& label': {
      fontWeight: '600',
      paddingBottom: '7px',
      marginBottom: '0',
      color: props => props.labelColor,
    },
    '& .error-line': {
      fontFamily: 'Arial',
      position: 'absolute',
      left: '0',
      bottom: '0px',
      display: 'inline-block',
      transitionDuration: props => props.transitionDuration,
      fontSize: '11px',
      lineHeight: '14px',
      color: variables.$danger,
      width: '100%',
    },

    // Helper Text
    '& .bottom-wrap': {
      lineHeight: '14px',
      position: 'absolute',
      bottom: '0px',
      fontSize: '11px',
      color: variables.$helperTextColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& .helper-text': {
        margin: '0px',
      },
      '& .counter': {
        marginLeft: 'auto',
      },
    },

    // Input
    '& .input-element-wrapper': {
      flexWrap: 'nowrap',
      fontFamily: 'Arial',
      boxSizing: 'border-box',
      width: '100%',
      position: 'relative',
      borderRadius: variables.$input.$radius,
      zIndex: 2,
      display: 'flex',
      transitionProperty: 'margin-bottom, box-shadow',
      transitionTimingFunction: 'cubic-bezier(0.04, 1.1, 1, 0.99)',
      '&:focus': {
        ...variables.$input.$focus,
      },
      '&::-webkit-input-placeholder': {
        color: variables.$input.$placeholderColor,
      },
      // '&:read-only': { todo
      //     backgroundColor: variables.$input.$readOnly,
      //     pointerEvents: 'none',
      // }
      transitionDuration: props => props.transitionDuration,
      marginBottom: props => props.marginBottom,
      backgroundColor: props => props.backgroundColor,
      border: props => props.border,
      padding: props => props.padding,
      fontSize: props => props.fontSize,
      height: props => variables.$fieldsSizes[`$${props.size}`],
      lineHeight: props => variables.$fieldsSizes[`$${props.size}`],
      overflow: 'hidden',
      '&[data-disabled="true"]': {
        position: 'relative',
        '&::after': {
          content: '""',
          display: 'block',
          border: '0',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: props => props.disabledColor,
          zIndex: '22',
          left: '0',
          top: '0',
          transform: 'scale(1)',
        },
      },
      '& .placeholder-option': {
        color: props => props.placeholderColor,
        fontSize: props => props.fontSize,
      },
      '& > .every-option': {
        display: 'block',
        width: '100%',
        flexShrink: '0',
        position: 'absolute',
        transform: 'translateX(10px)',
        fontSize: props => props.fontSize,
        opacity: '0',
        color: props => props.textColor,
        '&.active-option': {
          transition: '.4s',
          opacity: '1',
          position: 'relative',
          transform: 'translateX(0px)',
        },
      },
      '&:hover': {
        ...variables.$input.$hover,
      },
      // '& input:disabled': { todo
      //     backgroundColor: variables.$input.$disabledColor,
      // },
      '&.input-md': {
        padding: variables.$input.$md.$padding,
        fontSize: variables.$input.$md.$fontSize,
        lineHeight: variables.$fieldsSizes.$md,
        height: variables.$fieldsSizes.$md,
      },
      '&.input-sm': {
        fontSize: variables.$input.$sm.$fontSize,
        padding: variables.$input.$sm.$padding,
        lineHeight: variables.$fieldsSizes.$sm,
        height: variables.$fieldsSizes.$sm,
      },
      '&.input-xs': {
        padding: variables.$input.$xs.$padding,
        fontSize: variables.$input.$xs.$fontSize,
        lineHeight: variables.$fieldsSizes.$xs,
        height: variables.$fieldsSizes.$xs,
      },
      '& .arrows-scroll-select': {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: '0px',
        paddingRight: '4px',
        top: '0',
        bottom: '0',
        backgroundColor: props => props.backgroundColor,
        margin: 'auto',
        '& svg': {
          cursor: 'pointer',
          fill: props => props.iconColor,
          '&.left-icon': {
            transform: 'rotate(90deg)',
            '&.disable-left': {
              cursor: 'not-allowed',
              opacity: '0.5',
            },
          },
          '&.right-icon': {
            transform: 'rotate(-90deg)',
            '&.disable-right': {
              cursor: 'not-allowed',
              opacity: '0.5',
            },
          },
        },
      },
    },
    '&.wrapper-md': {
      '& .arrows-scroll-select': {
        '& svg': {
          width: '30px',
        },
      },
    },
    '&.wrapper-sm': {
      '& .arrows-scroll-select': {
        '& svg': {
          width: '25px',
        },
      },
    },
    '&.wrapper-xs': {
      '& .arrows-scroll-select': {
        '& svg': {
          width: '20px',
        },
      },
    },

    '& label.label-md': {
      fontSize: variables.$input.$md.$fontSize,
    },
    '& label.label-sm': {
      fontSize: variables.$input.$sm.$fontSize,
    },
    '& label.label-xs': {
      fontSize: variables.$input.$xs.$fontSize,
    },
    '&.error-field': {
      '& label': {
        color: `${variables.$danger} !important`,
      },
      '& .input-element-wrapper': {
        marginBottom: props => props.marginBottom,
        ...Shadow(`0 0 1px 1px ${variables.$danger} inset !important`),
        paddingRight: '25px !important',
      },
    },
  },
  'theme-light': {
    '& .input-element-wrapper': {
      backgroundColor: variables.$input.$theme.$light,
      '& .arrows-scroll-select': {
        backgroundColor: variables.$input.$theme.$light,
      },
    },
  },
  'theme-dark': {
    '& .input-element-wrapper': {
      backgroundColor: variables.$input.$theme.$dark,
      color: variables.$input.$theme.$darkColor,
      '& .arrows-scroll-select': {
        backgroundColor: variables.$input.$theme.$dark,
      },
    },
    '& .input-icon': {
      color: `${variables.$input.$theme.$darkColor}!important`,
    },
  },
});
