import { memo } from 'react';

import Footer from 'Components/Shared/Footer';
import Header from 'Components/Shared/Header';

const Default = ({ children }) => (
  <>
    <Header />
    <main className="Content">{children}</main>
    <Footer />
  </>
);

export default memo(Default);
