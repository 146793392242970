export default [
  {
    title: 'profile_details_ads',
    key: '',
    link: '/my-sexjobs/ads',
    icon: 'icon-Advertenties',
    exact: [
      '/my-sexjobs/ads/create',
      '/my-sexjobs/ads/view/',
      '/my-sexjobs/ads/promote/',
      'my-sexjobs/ads/edit/',
    ],
  },
  {
    title: 'profile_details_profile',
    key: '',
    link: '/my-sexjobs/profile',
    icon: 'icon-My-Account',
    exact: [
      '/my-sexjobs/profile/create',
      '/my-sexjobs/profile/edit',
      '/my-sexjobs/profile/view',
    ],
  },
  {
    title: 'profile_details_notifications',
    key: 'notifications',
    link: '/my-sexjobs/notifications?page=1',
    icon: 'icon-Notification',
    exact: ['/my-sexjobs/notifications/settings'],
  },
  {
    title: 'profile_details_messages',
    key: 'msg',
    link: '/my-sexjobs/chat',
    icon: 'icon-Message-_-Mail',
    exact: ['/my-sexjobs/chat/settings'],
  },
  {
    title: 'profile_details_shop_cart',
    key: 'cart',
    link: '/my-sexjobs/cart',
    icon: 'icon-Shopping-Cart',
  },
  {
    title: 'profile_details_credits',
    key: 'credits',
    link: '/my-sexjobs/credits',
    icon: 'icon-Credits',
  },
  {
    title: 'profile_details_payments',
    key: 'billings',
    link: '/my-sexjobs/billings',
    icon: 'icon-ID',
  },
  {
    title: 'profile_details_settings',
    key: '',
    link: '/my-sexjobs/account/settings',
    icon: 'icon-Settings',
    exact: ['/my-sexjobs/account/settings', '/my-sexjobs/account/delete'],
  },
  {
    title: 'profile_details_customer',
    key: '',
    link: 'https://klantenservice.sexjobs.nl/nl/support/home',
    icon: 'icon-Customer-Service',
    target: '_blank',
  },
  {
    title: 'profile_details_logout',
    key: '',
    icon: 'icon-Logout',
    notVisible: 'my-sexjobs',
    onClick: 'logOut',
  },
];
