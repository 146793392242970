import { useState, useCallback, memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import useMemoSelector from 'hooks/useMemoSelector';
import { useTranslations } from 'next-intl';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';

import Types from 'store/types';
import { getUser } from 'store/reselect';

const StyledButton = withStyles({
  root: {
    color: 'white',
    '&, &:hover': {
      background: 'linear-gradient(180deg, #7AD100 0%, #5C9E00 100%)',
    },
  },
})(Button);

const StyledDialogTitle = withStyles({
  root: {
    '& .MuiTypography-root': {
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
})(DialogTitle);

function RecoveredDialog() {
  const dispatch = useDispatch();
  const t = useTranslations();
  const user = useMemoSelector(getUser);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (user.is_recovered) {
      setOpen(true);
    }
  }, [user.is_recovered]);

  const handleClose = useCallback(
    async reason => {
      if (reason === 'backdropClick') {
        return false;
      }

      dispatch({ type: Types.SET_ACCEPT_RECOVER });
      setOpen(false);
    },
    [dispatch],
  );

  return (
    <Dialog open={open} disableEscapeKeyDown onClose={handleClose}>
      <StyledDialogTitle id="alert-dialog-title">
        {t('recover_account_popup_title')}
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText
          style={{ fontWeight: 'bold' }}
          id="alert-dialog-description"
        >
          {t('recover_account_popup_content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', margin: '10px 15px' }}>
        <StyledButton
          fullWidth
          color="secondary"
          variant="contained"
          onClick={handleClose}
        >
          {t('recover_account_popup_button')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}

export default memo(RecoveredDialog);
