import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import Popover from '@material-ui/core/Popover';
import usePrevious from 'hooks/use-previous-state';
import variables from 'static/styles/jss/abstracts/variables';
import { useTranslations } from 'next-intl';

import { useDropDownPopoverStyles } from 'Components/Shared/UI/makeStylesUI';

import { COUNTRY_PHONES } from 'utils/constants';

import useStyles from './styles';

const Phone = props => {
  const t = useTranslations();

  const [value, setValue] = useState(props.value);
  const [country, setCountry] = useState(COUNTRY_PHONES[0]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [focused, setFocused] = useState(false);
  const [marginBottom, setMarginBottom] = useState(null);
  const [error, setError] = useState('');
  const [popoverWidth, setPopoverWidth] = useState('auto');
  const prevWidthOfPopover = usePrevious(popoverWidth);

  const styles = useStyles({
    marginBottom,
    border: 'none',
  });

  const inputRef = useRef();
  const errorLineRef = useRef();
  const popoverStyle = {
    backgroundColor:
      props.theme === 'dark' ? variables.$input.$theme.$dark : '#fff',
    color: props.theme === 'dark' ? variables.$input.$theme.$darkColor : '#000',
  };
  const classesDropDown = useDropDownPopoverStyles({
    margin: '3px 0 0 0px',
    zIndex: '9999999 !important',
  });
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const selectedCountryCode = props.currentNumberCountryCode;

    if (selectedCountryCode) {
      const correctCode = COUNTRY_PHONES.find(
        e => e.phone_country_code === Number(selectedCountryCode),
      );

      setCountry(correctCode);
    }
  }, [props.currentNumberCountryCode]);

  const changeCountry = phone => {
    setCountry(phone);
    setAnchorEl(null);
    setValue('');
    setError('');
    props.onChange({
      value: '',
      phone_country_code: null,
      phone_number: null,
    });
  };

  const maskChangeEvent = e => {
    setError('');
    setValue(e.target.value);

    const phoneNumber = e.target.value
      .replace(`(+${country.phone_country_code}) `, '')
      .split(' ')
      .join('');

    const correctPhoneNumber =
      phoneNumber.length === 10 && phoneNumber.startsWith('0')
        ? phoneNumber.slice(1)
        : phoneNumber;

    const isNumberExistInList = props.phoneData?.some(
      el => el === `(+${country.phone_country_code})${correctPhoneNumber}`,
    );

    if (isNumberExistInList) {
      setError(t('phone_number_already_exists_in_the_list'));

      props.onChange({
        value: e.target.value,
        phone_country_code: country.phone_country_code,
        phone_number: correctPhoneNumber,
      });
      return;
    }

    if (phoneNumber.length === 10 && !phoneNumber.startsWith('0')) {
      setError(t('phone_error_10_numbers'));
    }

    const isValidPhoneNumber = (countryCode, phoneNumber) => {
      const normalizedPhoneNumber = phoneNumber.startsWith('0')
        ? phoneNumber.slice(1)
        : phoneNumber;

      const isValid =
        (countryCode === 31 && normalizedPhoneNumber.length === 9) ||
        (countryCode === 32 && [8, 9].includes(normalizedPhoneNumber.length));

      return isValid;
    };

    if (
      /^00/.test(phoneNumber) ||
      !e.target.value ||
      !isValidPhoneNumber(country.phone_country_code, correctPhoneNumber)
    ) {
      if (phoneNumber.length === 9 && phoneNumber.startsWith('0')) {
        setError(t('phone_error_9_numbers'));
      } else {
        setError(t('invalid_phone_number_error'));
      }
      props.emitError(true);
    } else if (error && !props.error) {
      setError('');
    }

    props.onChange({
      value: e.target.value,
      phone_country_code: country.phone_country_code,
      phone_number: correctPhoneNumber,
    });
  };

  const onSubmit = e => e.key === 'Enter' && props.onSubmit();

  useEffect(() => {
    if (anchorEl) setPopoverWidth(anchorEl.getBoundingClientRect().width);
  }, [anchorEl]);

  useEffect(() => {
    if (errorLineRef.current && error) {
      setMarginBottom(`${errorLineRef.current.offsetHeight + 2}px`);
    } else {
      setMarginBottom(null);
    }
  }, [errorLineRef, error]);

  useEffect(() => {
    props.emitError(error);
  }, [error]);

  return (
    <div
      className={`${styles['phone--block']} phone--block__${props.theme} ${
        props.likeMaterialInput ? 'phone--block__material' : ''
      } ${focused ? 'phone--block__material-focused' : ''}`}
    >
      <div className="input-line--block" ref={inputRef}>
        <img src={country.flagSrc} alt="flag" />
        <InputMask
          className={`phone-mask--block input-${props.size}`}
          value={value}
          mask={country.phoneCode}
          onKeyPress={onSubmit}
          onChange={e => {
            if (e.target.value.length <= country.validationLength.max) {
              maskChangeEvent(e);
            }
          }}
          disabled={props.disabled || false}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder={country.phoneCode?.replaceAll('\\', '')}
          maskChar=""
          permanents={[0, 1, 2, 3, 4, 5]}
        />
        {!props.disabled && (
          <svg
            className="arrow-icon"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            onClick={() => setAnchorEl(inputRef.current)}
          >
            <path d="M7 10l5 5 5-5z"></path>
          </svg>
        )}
      </div>
      {error ? (
        <p className="error-line" ref={errorLineRef}>
          {error}
        </p>
      ) : (
        ''
      )}
      <Popover
        classes={classesDropDown}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: { width: popoverWidth || prevWidthOfPopover },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ul className="phone-mask-dropdown--block" style={popoverStyle}>
          {COUNTRY_PHONES.map(phone => (
            <li
              key={phone.key}
              className={phone.key === country.value ? 'active-phone-mask' : ''}
              onClick={() => changeCountry(phone)}
            >
              <img src={phone.flagSrc} alt="flag" />
              {t(phone.title)}
            </li>
          ))}
        </ul>
      </Popover>
    </div>
  );
};

Phone.defaultProps = {
  size: 'md',
  theme: 'light',
  onChange: () => void 0,
  onSubmit: () => void 0,
  emitError: () => void 0,
  likeMaterialInput: false,
};
Phone.propTypes = {
  size: PropTypes.string,
  error: PropTypes.string,
  theme: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  emitError: PropTypes.func,
  likeMaterialInput: PropTypes.bool,
};
export default React.memo(Phone);
