import { call, put, all, takeEvery, select } from 'redux-saga/effects';
import { merge } from 'immutable';

import { Cookie } from 'utils/handlers';
import { getFromStorage } from 'utils/persist';

import { _authState, _baseState } from 'store/reselect/selectors';

import API from 'services/api';

import Types from '../types';

export function* getAccountConfigs() {
  try {
    const { account_configs } = yield select(_baseState);
    if (!account_configs.received) {
      const { data } = yield call(API.fetchAccountConfigs);
      yield put({
        type: Types.SET_ACCOUNT_CONFIGS,
        payload: merge(data, { received: true }),
      });
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* getProfileConfigs() {
  try {
    const { profile_configs } = yield select(_baseState);
    if (!profile_configs.received) {
      const { data } = yield call(API.fetchProfileConfigs);
      yield put({
        type: Types.SET_PROFILE_CONFIGS,
        payload: merge(data, { received: true }),
      });
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* getMediaConfigs() {
  try {
    const { media_configs } = yield select(_baseState);
    if (!media_configs.received) {
      const { data } = yield call(API.fetchMediaConfigs);
      yield put({
        type: Types.SET_MEDIA_CONFIGS,
        payload: merge(data, { received: true }),
      });
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* changeLanguage({ payload }) {
  try {
    const {
      data: { result },
      message,
    } = yield call(API.changeLanguage, { lang: payload.lang });
    if (message) throw new Error(message);
    if (result) {
      const refreshToken = getFromStorage('refreshToken');
      const {
        data: { access_token },
      } = yield call(() => API.getAuthRefreshToken(refreshToken));

      Cookie.setToken(access_token);
      Cookie.setCookieByKey('lang', payload.lang);

      const { data } = yield call(API.getDictionary, payload.lang);

      Cookie.setCookieByKey('lang', payload.lang);

      yield put({
        type: Types.FETCH_DICTIONARY,
        payload: { dictionary: data, lang: payload.lang },
      });
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* getNotificationsList({ payload }) {
  try {
    const { data, message } = yield call(API.getNotificationList, payload);
    if (message) throw new Error(message);
    yield put({
      type: Types.SAVE_NOTIFICATION_LIST,
      payload: data,
      addMore: payload.page > 0,
    });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* getNotifications({ payload }) {
  try {
    const { limit, dataType, getUnreadCount, cb, page: reqPage } = payload;
    const {
      user: { id },
    } = yield select(_authState);
    let {
      notifications: { unread_count = 0 },
    } = yield select(_baseState);
    yield put({
      type: Types.SET_NOTIFICATIONS,
      payload: {
        unread_count,
        reqPage,
        fetchingStatus: 'fetching',
      },
    });
    if (getUnreadCount) {
      const {
        data: { count },
      } = yield call(API.getNotificationsUnreadCount, id);

      unread_count = count;
    }
    const { data, message } = yield call(API.getNotifications, {
      account_id: id,
      limit: limit,
      page: reqPage,
    });
    if (message) throw new Error(message);
    cb?.(data.still_left);
    yield put({
      type: Types.SET_NOTIFICATIONS,
      payload: {
        data:
          dataType === 'page'
            ? [...(reqPage || []), ...data.result]
            : data.result,
        key: dataType,
        unread_count,
        fetchingStatus: 'fetched',
      },
    });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* actionNotification(props) {
  try {
    const { data, message } = props.actionType
      ? yield call(API.setNotificationAsRead, {
          account_id: props.accountId,
          _id: props.id,
        })
      : yield call(API.deleteNotification, {
          account_id: props.accountId,
          _id: props.id,
        });

    if (message) throw new Error(message);
    if (data.result) {
      props.actionType
        ? yield put({ type: Types.UPDATE_NOTIFICATION, id: props.id })
        : props.cb();
      props.getUnreadCount?.();
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* crudNotification({ payload }) {
  try {
    const { notificationId, actionType } = payload;
    const {
      user: { id },
    } = yield select(_authState);
    const {
      notifications: { page },
    } = yield select(_baseState);
    const {
      data: { result },
      message,
    } = yield call(
      API[
        actionType === 'read' ? 'setNotificationAsRead' : 'deleteNotification'
      ],
      { account_id: id, _id: notificationId },
    );
    if (message) throw new Error(message);
    if (result) {
      const notificationIndex = page.findIndex(e => e._id === notificationId);
      if (notificationIndex > -1) {
        if (actionType === 'read') {
          page[notificationIndex].is_read = true;
        } else {
          page.splice(notificationIndex, 1);
        }
      }
      yield put({
        type: Types.SET_NOTIFICATIONS,
        payload: {
          data: [...page],
          key: 'page',
        },
      });
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* fetchGeographyConfigs({ payload }) {
  try {
    const { data } = yield call(API.fetchGeographyConfigs);
    if (data) {
      yield put({ type: Types.SET_GEOGRAPHY_CONFIGS, payload: data });
    } else {
      yield put({
        type: Types.SET_GEOGRAPHY_CONFIGS,
        payload: {
          context: ['nld', 'bel'],
          country: ['NLD', 'BEL'],
          language: ['nl-NL', 'en-US', 'de-DE', 'en-GB'],
          session_header: { valid_pattern: '^[a-z\\d\\-_.]{8,64}$' },
          term: {
            cleanup_pattern: '[^,A-Za-z0-9_ ]',
            min_length: 2,
            max_length: 50,
          },
        },
      });
    }
  } catch (error) {
    yield put({
      type: Types.SET_GEOGRAPHY_CONFIGS,
      payload: {
        context: ['nld', 'bel'],
        country: ['NLD', 'BEL'],
        language: ['nl-NL', 'en-US', 'de-DE', 'en-GB'],
        session_header: { valid_pattern: '^[a-z\\d\\-_.]{8,64}$' },
        term: {
          cleanup_pattern: '[^,A-Za-z0-9_ ]',
          min_length: 2,
          max_length: 50,
        },
      },
    });
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  } finally {
    payload?.cb?.();
  }
}

export function* fetchDictionary({ payload }) {
  try {
    const { data } = yield call(
      async () => await API.getDictionary(payload.locale),
    );

    Cookie.setCookieByKey('lang', payload.locale);

    yield put({
      type: Types.FETCH_DICTIONARY,
      payload: { dictionary: data, lang: payload.locale },
    });
  } catch (error) {
    console.error(error);
  } finally {
    payload.cb();
  }
}

export function* setDictionaryErrors() {
  try {
    const { data } = yield call(API.getDictionaryErrors);

    yield put({
      type: Types.SET_DICTIONARY_ERROR,
      payload: data.errors,
    });
  } catch (error) {
    console.error(error);
  }
}

export function* getLocations({ payload }) {
  const { cb, categoryId } = payload;
  try {
    const { data } = yield call(() => API.fetchProvinces(categoryId));

    yield put({ type: Types.SET_LOCATIONS, payload: data });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  } finally {
    cb();
  }
}

export function* getPopularCities({ payload }) {
  const { cb, categoryId } = payload;

  try {
    const { data } = yield call(() => API.fetchPopularCities(categoryId));

    yield put({ type: Types.SET_POPULAR_CITIES, payload: data });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  } finally {
    cb();
  }
}

export default function* baseSaga() {
  yield all([
    takeEvery(Types.GET_MEDIA_CONFIGS, getMediaConfigs),
    takeEvery(Types.FETCH_LOCATIONS, getLocations),
    takeEvery(Types.FETCH_POPULAR_CITIES, getPopularCities),
    takeEvery(Types.GET_DICTIONARY_ERROR, setDictionaryErrors),
    takeEvery(Types.CHANGE_LANGUAGE, changeLanguage),
    takeEvery(Types.GET_PROFILE_CONFIGS, getProfileConfigs),
    takeEvery(Types.GET_ACCOUNT_CONFIGS, getAccountConfigs),
    takeEvery(Types.GET_NOTIFICATIONS, getNotifications),
    takeEvery(Types.GET_NOTIFICATIONS_LIST, getNotificationsList),
    takeEvery(Types.CRUD_NOTIFICATION, crudNotification),
    takeEvery(Types.FETCH_GEOGRAPHY_CONFIGS, fetchGeographyConfigs),
    takeEvery(Types.ACTION_NOTIFICATION, actionNotification),
    takeEvery(Types.SET_DICTIONARY, fetchDictionary),
  ]);
}
