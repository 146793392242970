import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'btn-global': {
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    color: '#fff',
    backgroundColor: variables.$baseColor,
    borderRadius: variables.$button.$radius,
    justifyContent: 'center',
    margin: 0,
    padding: variables.$button.$padding,
    transition: '.1s',
    '&.mobile-full-width': {
      ...MediaQuery.down({
        width: '100% !important',
        marginLeft: '0px !important',
        marginRight: '0px !important',
      }).sm,
    },
    '& .icon-Message-_-Mail': {
      marginRight: '7px',
    },
    '&.custom-icon-dir-right': {
      '& i[class^="icon-"], & .loading-icon': {
        marginLeft: '7px',
      },
    },
    '&.custom-icon-dir-left': {
      flexDirection: 'row-reverse',
      '& i[class^="icon-"], & .loading-icon': {
        marginRight: '7px',
      },
    },
  },
  'btn-default': {
    backgroundColor: variables.$button.$default.$bg,
    border: variables.$button.$default.$border,
    color: variables.$button.$default.$color,
    '&:hover': {
      ...variables.$button.$default.$hover,
    },
    '&:disabled': {
      pointerEvents: 'none',
      ...variables.$button.$default.$disabled,
    },
  },
  'btn-cta': {
    background: variables.$button.$cta.$bg,
    border: variables.$button.$cta.$border,
    color: variables.$button.$cta.$color,
    '&:hover': {
      ...variables.$button.$cta.$hover,
    },
    '&:disabled': {
      pointerEvents: 'none',
      ...variables.$button.$cta.$disabled,
    },
  },
  'btn-primary': {
    backgroundColor: variables.$button.$primary.$bg,
    border: variables.$button.$primary.$border,
    color: variables.$button.$primary.$color,
    '&:hover': {
      ...variables.$button.$primary.$hover,
    },
    '&:disabled': {
      pointerEvents: 'none',
      ...variables.$button.$primary.$disabled,
    },
  },
  'btn-alt': {
    backgroundColor: variables.$button.$alt.$bg,
    boxShadow: `0px 0px 0px 2px ${variables.$button.$alt.$border} inset`,
    border: 'none',
    color: variables.$button.$alt.$color,
    '&:hover': {
      ...variables.$button.$alt.$hover,
    },
    '&:disabled': {
      pointerEvents: 'none',
      ...variables.$button.$alt.$disabled,
    },
  },
  'btn-white-bg': {
    backgroundColor: variables.$button.$whiteBG.$bg,
    border: variables.$button.$whiteBG.$border,
    color: variables.$button.$whiteBG.$color,
    '&:hover': {
      ...variables.$button.$whiteBG.$hover,
    },
    '&:disabled': {
      pointerEvents: 'none',
      ...variables.$button.$whiteBG.$disabled,
    },
  },
  'btn-alert': {
    backgroundColor: variables.$button.$alert.$bg,
    border: variables.$button.$alert.$border,
    color: variables.$button.$alert.$color,
    '&:hover': {
      ...variables.$button.$alert.$hover,
    },
    '&:disabled': {
      pointerEvents: 'none',
      ...variables.$button.$alert.$disabled,
    },
  },
  'hover-from-transparent': {
    '&:hover': {
      backgroundColor: `${variables.$baseColor} !important`,
      color: '#fff !important',
    },
  },
  'btn-md': {
    padding: variables.$button.$md.$padding,
    height: variables.$fieldsSizes.$md,
    lineHeight: variables.$fieldsSizes.$md,
  },
  'btn-sm': {
    padding: variables.$button.$sm.$padding,
    height: variables.$fieldsSizes.$sm,
    lineHeight: variables.$fieldsSizes.$sm,
  },
  'btn-xs': {
    padding: variables.$button.$xs.$padding,
    height: variables.$fieldsSizes.$xs,
    lineHeight: variables.$fieldsSizes.$xs,
  },
});
