import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'block-row': {
    borderStyle: 'solid',
    borderWidth: '3px',
    borderColor: props => props.color,
    width: '100%',
    height: 'auto',
    minHeight: '50px',
    color: props => props.color,
    backgroundColor: props => props.backgroundColor,
    fontSize: '17px',
    fontWeight: '600',
    padding: '10px',
  },
});
