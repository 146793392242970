import { makeStyles } from '@material-ui/core/styles';
import { MainContent } from 'static/styles/jss/abstracts/extends';

export default makeStyles({
  'no-result--block': {
    color: 'var(--bold-text-color)',
    textAlign: 'center',
    '&:not(.no-bg-shadow)': {
      ...MainContent,
    },
    '& p': {
      margin: 0,
    },
    '& span': {
      fontSize: '25px',
      margin: '10px 0',
      display: 'inline-block',
    },
  },
});
