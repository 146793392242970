import { useRef, useEffect } from 'react';

function usePrevious(value, notNullish) {
  const ref = useRef();
  useEffect(() => {
    if (!notNullish) ref.current = value;
    else if (value) {
      ref.current = value;
    }
  }, [value]);
  return ref.current;
}
export default usePrevious;
