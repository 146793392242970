import { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import useDebounce from 'hooks/use-debounce';
import useMemoSelector from 'hooks/useMemoSelector';

import AsideMenu from 'Components/AsideMenu';

import getValidRoute from 'utils/getValidRoute';
import { convertCapitalize } from 'utils/handlers';

import {
  deviceParams,
  getLocations,
  adCategories,
  getIsMobileSearchOpen,
  getSelectedSettlement,
  getSelectedSubCategory,
} from 'store/reselect';
import { setSearchSuggestionsOpen, setSelectedProvince } from 'store/actions';

import API from 'services/api';

import { useStyles } from './styles';
import SearchWithSuggestions from './searchWithSuggestions';

const Search = ({ headerHeight }) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const router = useRouter();
  const styles = useStyles();
  const {
    deviceType,
    selectedSettlement,
    isMobileSearchOpen,
    categories,
    locationsData,
    selectedSubCategory,
  } = useMemoSelector(store => ({
    deviceType: deviceParams()(store).deviceType,
    selectedSettlement: getSelectedSettlement(store),
    isMobileSearchOpen: getIsMobileSearchOpen(store),
    categories: adCategories(store),
    locationsData: getLocations(store),
    selectedSubCategory: getSelectedSubCategory(store, router.query.slug),
  }));

  const [searchValue, setSearchValue] = useState();
  const [suggestions, setSuggestions] = useState();
  const [suggestionsPayload, setSuggestionsPayload] = useState({});
  const [hasDistancesOption, setHasDistancesOption] = useState(false);

  const debouncedValue = useDebounce(searchValue, 500);

  useEffect(() => {
    const urlStructure = getValidRoute({
      slug: router.query.slug,
      queryType: router.query.type,
      settlement: router.query.settlement,
      province: router.query.province,
      categories,
      locationsData,
      selectedSubCategory,
    });

    const {
      selectedCity,
      settlement,
      province,
      selectedProvince,
      isNearby,
      category_id,
      label_ids = [],
    } = urlStructure || {};

    setSuggestionsPayload({
      category_id,
      label_id: label_ids[0],
    });

    const withDistance =
      !!selectedCity ||
      !!settlement ||
      !!province ||
      !!selectedProvince ||
      !!isNearby;

    setHasDistancesOption(withDistance);

    const searchedValueFromUrl =
      selectedCity || settlement || province || selectedProvince;

    setSearchValue(
      convertCapitalize(searchedValueFromUrl) ||
        router.query.term ||
        t(router.query.type),
    );
  }, [
    categories,
    locationsData,
    router.query.province,
    router.query.settlement,
    router.query.slug,
    router.query.term,
    router.query.type,
    selectedSubCategory,
    setSearchValue,
    t,
  ]);

  useEffect(() => {
    setSearchValue(selectedSettlement);
  }, [selectedSettlement]);

  useEffect(() => {
    if (!debouncedValue || !searchValue) return;

    const getSuggest = async () => {
      try {
        const { data } = await API.fetchSearchSuggestions({
          term: debouncedValue,
          ...suggestionsPayload,
        });

        setSuggestions(data);
      } catch (error) {
        setSuggestions([]);
        console.error(error);
      }
    };

    getSuggest();
  }, [debouncedValue, searchValue, suggestionsPayload]);

  useEffect(() => {
    if (!searchValue) {
      setSuggestions([]);
    }
  }, [searchValue]);

  useLayoutEffect(() => {
    if (isMobileSearchOpen) {
      document.body.classList.add('body--fixed__overflow--no-scroll');
    } else {
      document.body.classList.remove('body--fixed__overflow--no-scroll');
    }
  }, [isMobileSearchOpen]);

  const isMobile = deviceType === 'mobile';

  return (
    <div
      id="search-input-wrapper"
      className={clsx(styles['search-wrapper'], 'opened_mobile')}
    >
      {isMobileSearchOpen ? (
        <AsideMenu>
          <div className={styles['search-mobile']} id="search-aside-wrapper">
            <SearchWithSuggestions
              autoFocus
              isMobile={isMobile}
              searchValue={searchValue}
              suggestions={suggestions}
              headerHeight={headerHeight}
              setSearchValue={setSearchValue}
              setSuggestions={setSuggestions}
              hasDistancesOption={hasDistancesOption}
              isMobileSearchOpen={isMobileSearchOpen}
              onFocus={() => dispatch(setSearchSuggestionsOpen(true))}
              onClickOutside={() => {
                dispatch(setSelectedProvince(''));
                dispatch(setSearchSuggestionsOpen(false));
              }}
            />
          </div>
        </AsideMenu>
      ) : (
        <SearchWithSuggestions
          isMobile={isMobile}
          searchValue={searchValue}
          suggestions={suggestions}
          headerHeight={headerHeight}
          setSearchValue={setSearchValue}
          setSuggestions={setSuggestions}
          hasDistancesOption={hasDistancesOption}
          isMobileSearchOpen={isMobileSearchOpen}
          onFocus={() => dispatch(setSearchSuggestionsOpen(true))}
          onClickOutside={() => {
            dispatch(setSelectedProvince(''));
            dispatch(setSearchSuggestionsOpen(false));
          }}
        />
      )}
    </div>
  );
};

export default Search;
