import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslations } from 'next-intl';
import { formatDistanceToNowStrict } from 'date-fns';
import Popover from '@material-ui/core/Popover';
import { Col, Container, Row } from 'reactstrap';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { useDropDownPopoverStyles } from 'Components/Shared/UI/makeStylesUI';
import { Avatar, Button } from 'Components/Shared/UI';

import { NOTIFICATIONS } from 'utils/constants';

import { getNotifications, getNotificationsList } from 'store/actions';
import Types from 'store/types';

import useStyles from './styles';

const NotificationItem = ({
  account_id: accountID,
  _id: id,
  is_read: isRead,
  body,
  layout_type: layoutType,
  date_created: createdDate,
  isDialogPopUp,
}) => {
  const notificationRef = useRef();
  const { pathname } = useRouter();
  const inInNotificationsPage = pathname.includes('notifications');
  const t = useTranslations();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const classesDropDown = useDropDownPopoverStyles({
    margin: '15px 0 0 24px',
    triangle: 'block',
    overflow: 'initial',
    triangleRight: '22px',
  });

  const notificationAction = readed => {
    dispatch({
      type: Types.ACTION_NOTIFICATION,
      actionType: readed,
      id: id,
      accountId: accountID,
      getUnreadCount: () => {
        dispatch(
          getNotifications({
            dataType: 'popup',
            page: 0,
            limit: NOTIFICATIONS.countLimit.popup,
            getUnreadCount: true,
          }),
        );
      },
      cb: () => {
        dispatch(
          getNotificationsList({
            accountId: accountID,
            page: 0,
            limit: 10,
          }),
        );
        setAnchorEl(null);
      },
    });
  };

  const getDateWithUnitLetters = distance => {
    const [value, unit] = distance.split(' ');
    const letter = t(`${unit}_letter`);

    return `${value}${letter}`;
  };

  useEffect(() => {
    const notificationItem = notificationRef?.current;
    if (notificationItem && !isRead) {
      const handleNotificationLinkClick = () => notificationAction(true);

      notificationItem
        .getElementsByTagName('a')?.[0]
        ?.addEventListener('click', handleNotificationLinkClick);

      return () =>
        notificationItem
          .getElementsByTagName('a')?.[0]
          ?.removeEventListener('click', handleNotificationLinkClick);
    }
  }, [isRead, notificationAction, notificationRef]);

  return (
    <div
      className={clsx(
        classes['notification-item-block'],
        classes['notification-container'],
        { 'notification-item-block--need-seen': !isRead },
      )}
    >
      <Container className="p-0">
        <Row>
          <Col xs={2} sm={1} md={1} lg={1} className="text-left">
            {!isRead && <span className="unread" />}
            <div className="notification-img-block">
              <Avatar
                avatarSrc="/images/avatar.png"
                radius="50%"
                defaultSrc="/images/.jpg"
                width={36}
                height={36}
              />
            </div>
          </Col>

          <Col xs={9} sm={10} md={10} lg={10}>
            <p
              className={clsx(
                { 'notification-txt': inInNotificationsPage && !isDialogPopUp },
                { 'notification-txt-dialog': isDialogPopUp },
                { isRead },
              )}
            >
              <span
                ref={notificationRef}
                dangerouslySetInnerHTML={{ __html: body }}
              ></span>
            </p>

            {layoutType !== 'info' && (
              <div className={classes['notification-btns-block']}>
                {layoutType === 'call_to_action' ? (
                  <Button
                    className="mr-4"
                    width={70}
                    size={'xs'}
                    text="CTA"
                    typeButton="alt"
                  />
                ) : (
                  <>
                    <Button
                      className="mr-4"
                      icon={{ className: 'icon-Block', direction: 'left' }}
                      width={100}
                      size="xs"
                      text="Decline"
                      typeButton="alt"
                    />
                    <Button
                      icon={{ className: 'icon-checkmark', direction: 'left' }}
                      width={100}
                      margin={[0, 10, 0, 0]}
                      size="xs"
                      text="Accept"
                      typeButton="primary"
                    />
                  </>
                )}
              </div>
            )}
          </Col>
          <Col xs={1} className="text-right p-0">
            <div>
              <span
                className="icon-Overflow-menu pr-1"
                onClick={evt => {
                  setAnchorEl(evt.currentTarget);
                }}
              ></span>
              <Popover
                classes={classesDropDown}
                id={id}
                open={!!anchorEl}
                anchorEl={anchorEl}
                PaperProps={{
                  className: 'popover-container notification-popover__block',
                  style: { width: '150px' },
                }}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {!isRead && (
                  <p
                    onClick={() => notificationAction(true)}
                    style={{ cursor: 'pointer', marginBottom: '10px' }}
                  >
                    {t('notifications_read')}
                  </p>
                )}
                <p
                  onClick={() => notificationAction(false)}
                  style={{ cursor: 'pointer', marginBottom: '0' }}
                >
                  {t('ads_actions_delete')}
                </p>
              </Popover>
            </div>

            <div className={classes['date-notification']}>
              <span>
                <i className="icon-Time"></i>
                {` `}
                {getDateWithUnitLetters(
                  formatDistanceToNowStrict(new Date(createdDate)),
                )}
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default NotificationItem;
