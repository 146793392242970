import { useLayoutEffect } from 'react';

const useLockBodyScroll = (deps = []) => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';

    // eslint-disable-next-line no-return-assign
    return () => (document.body.style.overflow = originalStyle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useLockBodyScroll;
