import { useState, useEffect, useCallback, memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import useMemoSelector from 'hooks/useMemoSelector';

import { getUser } from 'store/reselect';

import DeleteAccount from './DeleteAccount';
import PrivacyStatement from './PrivacyStatement';
import TermsAndConditions from './TermsAndConditions';
import RegistrationPermissions from './RegistrationPermissions';

const personalInformationSections = {
  DeleteAccount,
  PrivacyStatement,
  TermsAndConditions,
  RegistrationPermissions,
};

function PersonalInformation() {
  const user = useMemoSelector(getUser);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState('RegistrationPermissions');

  const handleClose = useCallback((_, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }

    setOpen(false);
  }, []);

  const handleGoBack = useCallback(() => {
    setPage('RegistrationPermissions');
  }, []);

  useEffect(() => {
    if (user.id && user.cookies_accepted === false) {
      const timerId = setTimeout(() => setOpen(true), 1500);

      return () => clearTimeout(timerId);
    }
  }, [user.cookies_accepted, user.id]);

  const Component = personalInformationSections[page];

  return (
    <Dialog fullWidth open={open} disableEscapeKeyDown onClose={handleClose}>
      <Component
        setPage={setPage}
        handleClose={handleClose}
        handleGoBack={handleGoBack}
      />
    </Dialog>
  );
}

export default memo(PersonalInformation);
