export default [
  {
    title: 'Amsterdam',
    value: 'amsterdam',
    count: 1100,
    popular: true,
  },
  {
    title: 'Rotterdam',
    value: 'rotterdam',
    count: 100,
    popular: true,
  },
  {
    title: 'Den Haag',
    value: 'den-haag',
    count: 150,
    popular: true,
  },
  {
    title: 'Utrecht',
    value: 'utrecht',
    count: 1220,
    popular: true,
  },
  {
    title: 'Eindhoven',
    value: 'eindhoven',
    count: 150,
    popular: true,
  },
  {
    title: 'City 2',
    value: 'city-2',
    count: 120,
  },
  {
    title: 'City 1',
    value: 'city-1',
    count: 150,
  },
];
