import { memo } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useTranslations } from 'next-intl';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

import { deleteAccount } from 'store/actions';

import { StyledDialogTitle, useLegalInformationStyles } from './styles';

function DeleteAccount({ handleGoBack, handleClose }) {
  const router = useRouter();
  const t = useTranslations();
  const dispatch = useDispatch();
  const { button } = useLegalInformationStyles();

  const handleDeleteAccount = () => {
    dispatch(
      deleteAccount({
        cb: async () => {
          await router.push(
            {
              pathname: '/login',
            },
            null,
            { shallow: true },
          );
        },
      }),
    );
    handleClose();
  };

  return (
    <>
      <StyledDialogTitle id="alert-dialog-title">
        {t('delete_account_popup_title')}
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText
          style={{ fontWeight: 'bold' }}
          id="alert-dialog-description"
        >
          {t('delete_account_popup_content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: 'space-between', margin: '10px 15px' }}
      >
        <Button
          color="secondary"
          variant="contained"
          onClick={handleDeleteAccount}
          className={clsx(button, 'red')}
        >
          {t('delete_account_popup_delete_button')}
        </Button>
        <Button
          variant="contained"
          onClick={handleGoBack}
          className={clsx(button, 'agree')}
        >
          {t('delete_account_popup_back_button')}
        </Button>
      </DialogActions>
    </>
  );
}

export default memo(DeleteAccount);
