import { createAsyncThunk } from '@reduxjs/toolkit';

import Types from 'store/types';

const asyncAction = (name, callback) =>
  createAsyncThunk(name, async (params, { dispatch }) => {
    try {
      const res = await callback(params, dispatch);
      return res;
    } catch (e) {
      dispatch({
        type: Types.SOMETHING_WENT_WRONG,
        payload: { err_message: e.message || e },
      });
    }
  });

export default asyncAction;
