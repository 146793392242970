import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from 'next-intl';

import useStyles from './styles';

const NoResult = ({ noBg, style = {} }) => {
  const t = useTranslations();
  const styles = useStyles();

  return (
    <div
      style={{ ...style }}
      className={`${styles['no-result--block']} ${noBg ? 'no-bg-shadow' : ''}`}
    >
      <span className="icon-Block"></span>
      <p>{t('no_result_text')}</p>
    </div>
  );
};
NoResult.propTypes = {
  noBg: PropTypes.bool,
  style: PropTypes.object,
};
export default memo(NoResult);
