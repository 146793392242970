import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useTranslations } from 'next-intl';

import { Button } from 'Components/Shared/UI';

import { baseSelector } from 'store/reselect';

const HeaderBtn = ({ closeAsideMenu, isLoggedIn }) => {
  const t = useTranslations();
  const { pageData } = useSelector(baseSelector());
  const router = useRouter();

  const goToRoute = async () => {
    try {
      if (pageData.layout !== 'auth') {
        isLoggedIn
          ? await router.push({
              pathname: '/my-sexjobs/ads/create',
              query: { step: '1' },
            })
          : await router.push('/login');
        closeAsideMenu();
      } else if (pageData.page === 'any') {
        await router.push('/login');
      } else if (pageData.page) {
        await router.push(pageData.page === 'login' ? '/register' : '/login');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err.message);
    }
  };

  return (
    <Button
      className={'ads-button'}
      icon={
        pageData.layout !== 'auth'
          ? { className: 'icon-Add', direction: 'left' }
          : {}
      }
      attr={{ 'data-appear': !pageData.page }}
      text={
        pageData.layout !== 'auth'
          ? t('header_place_ad_button')
          : pageData.page === 'login'
          ? t('header_register_button')
          : t('header_login_button')
      }
      typeButton="primary"
      onClick={goToRoute}
    />
  );
};
export default HeaderBtn;
