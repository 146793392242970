import variables from 'static/styles/jss/abstracts/variables';
import variablesJSS from 'static/styles/jss/abstracts/variables';

export const getSharedPropsOfClasses = ({
  props,
  isOpen,
  isMobile,
  marginBottom,
  allowTransition,
}) => {
  const backgroundColor =
    props.theme === 'dark'
      ? variablesJSS.$input.$theme.$dark
      : variablesJSS.$input.$theme.$light;
  const boxShadow =
    props.theme === 'dark'
      ? `${variablesJSS.$select.$dark.$borderColor} 0px 0px 0px 1px`
      : 'none';
  return {
    backgroundColor,
    boxShadow,
    transition: allowTransition
      ? 'margin-bottom 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)'
      : '0s',
    marginBottom: marginBottom || '0px',
    isDark: props.theme === 'dark',
    listItemColor:
      props.theme === 'dark'
        ? variables.$select.$dark.$textColor
        : variables.$select.$textColor,
    selectedItemColor:
      props.theme === 'dark'
        ? variables.$select.$dark.$selected.$color
        : variables.$select.$selected.$color,
    selectedItemBG:
      props.theme === 'dark'
        ? variables.$select.$dark.$selected.$bgColor
        : variables.$select.$selected.$bgColor,
    valueColor:
      props.theme === 'dark'
        ? variablesJSS.$datepicker.$dark.$textColor
        : variablesJSS.$datepicker.$textColor,
    actionsColor:
      props.theme === 'dark'
        ? variablesJSS.$datepicker.$dark.$actionsColor
        : variablesJSS.$datepicker.$actionsColor,
    weekColor:
      props.theme === 'dark'
        ? variablesJSS.$datepicker.$dark.$weekColor
        : variablesJSS.$datepicker.$weekColor,
    placeholderColor:
      props.theme === 'dark'
        ? variablesJSS.$select.$dark.$emptyTextColor
        : variablesJSS.$select.$emptyTextColor,
    size: props.size,
    isOpened: isOpen && !isMobile,
    iconColor:
      props.theme === 'dark' ? variablesJSS.$input.$theme.$darkColor : '#000',
  };
};

export const convertNaturalSize = (prop, what) => {
  if (Number.isNaN(+prop)) return prop;
  return prop + what;
};

export const getCurrentTimeOption = (options, time, isNext) => {
  const idx = options.findIndex(el => el.title >= time);

  const currentIdx = isNext ? idx : idx - 1;

  return options[currentIdx]?.value;
};
