import { all, call, put, takeEvery, select } from 'redux-saga/effects';

import { _msgState } from 'store/reselect/selectors';

import API from 'services/api';

import Types from '../types';

export function* getUnreadCount({ payload }) {
  try {
    const { data } = yield call(API.getUnreadCount, { accountId: payload });
    yield put({ type: Types.SET_UNREAD_COUNT, payload: data.count });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* getMessengerConfigs() {
  try {
    const { data } = yield call(API.fetchMessengerConfigs);
    yield put({ type: Types.SET_MESSENGER_CONFIGS, payload: data });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* getSettings({ payload }) {
  try {
    yield call(getMessengerConfigs);
    const {
      data: { result: data },
    } = yield call(API.fetchMsgSettings, { accountId: payload });
    yield put({ type: Types.SET_DISCRETE_COLOR, payload: data?.discrete });
    yield put({ type: Types.SET_MSG_SETTINGS, payload: data || {} });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* updateSettings({ payload }) {
  const { newSettings, cloneSettings, cb } = payload;
  try {
    const { message } = yield call(API.updateMsgSettings, { newSettings });
    if (message) throw message;
    yield put({
      type: Types.SET_DISCRETE_COLOR,
      payload: newSettings.discrete,
    });
    yield put({ type: Types.SET_MSG_SETTINGS, payload: newSettings });
    cb?.();
  } catch (error) {
    yield put({
      type: Types.SET_MSG_SETTINGS,
      payload: cloneSettings || newSettings,
    });
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* getFilters() {
  try {
    const {
      data: { message, result },
    } = yield call(API.fetchFilters);
    if (message) throw message;
    yield put({ type: Types.SET_FILTERS, payload: result });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* updateFilters({ payload }) {
  try {
    const { cb, _page, _limit, data, account_id } = payload;
    const {
      data: { message, threads, still_left },
    } = yield call(API.updateFilters, { data, account_id, _page, _limit });
    if (message) throw message;
    const msgState = yield select(_msgState);
    let mixedData = threads;
    if (_page > 1) {
      mixedData = [...msgState.threads, ...threads];
    }
    yield put({ type: Types.SET_FILTERS, payload: data });
    yield put({ type: Types.SET_THREADS, payload: mixedData });
    yield put({ type: Types.TOGGLE_THREADS_LOADING, payload: false });
    cb(still_left);
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export default function* msgSaga() {
  yield all([
    takeEvery(Types.GET_MSG_SETTINGS, getSettings),
    takeEvery(Types.UPDATE_MSG_SETTINGS, updateSettings),
    takeEvery(Types.GET_FILTERS, getFilters),
    takeEvery(Types.UPDATE_FILTERS, updateFilters),
    takeEvery(Types.GET_UNREAD_COUNT, getUnreadCount),
  ]);
}
