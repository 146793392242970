import { memo } from 'react';
import { BsFillGeoAltFill } from 'react-icons/bs';
import { useTranslations } from 'next-intl';

import { useLocationStyles } from '../styles';

import Location from './Location';

const SelectedProvince = ({ provinces, selectedProvince }) => {
  const {
    selectedProvinceStyle,
    locationWrapper,
    adCountStyle,
    locationIconStyle,
  } = useLocationStyles();

  const t = useTranslations();
  return (
    <Location
      locationKey="province"
      name={selectedProvince}
      itemData={{ selectedProvince }}
      adCount={provinces[selectedProvince]?.count}
    >
      <BsFillGeoAltFill className={locationIconStyle} />
      <div>
        <div>
          <div className={locationWrapper}>
            <span className={selectedProvinceStyle}>{`${t(
              'select_province_text',
            )} ${selectedProvince}`}</span>
            <span className={adCountStyle}>
              {`${provinces[selectedProvince]?.count} ads`}
            </span>
          </div>
        </div>
      </div>
    </Location>
  );
};

export default memo(SelectedProvince);
