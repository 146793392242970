import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { fromJS, merge } from 'immutable';

import { logout } from 'utils/handlers';

import Types from 'store/types';
import { getUserAccountId } from 'store/reselect';
import { _authState } from 'store/reselect/selectors';

import API from 'services/api';

export function* getProfiles({ payload }) {
  if (!payload?.id || payload.processLogin) return;
  try {
    const dataProfiles = [];
    yield put({ type: Types.FETCHING_PROFILES, payload: 'fetching' });

    if (payload.role === 'company') {
      const { data } = yield call(API.fetchProfiles, {
        accountId: payload.id,
        url: 'profile/company',
      });
      if (data) {
        dataProfiles.push(data);
        const profiles = yield call(API.fetchProfiles, {
          accountId: payload.id,
          url: 'profile',
        });
        dataProfiles.push(...profiles.data);
      }
    } else {
      const { data } = yield call(API.fetchProfiles, {
        accountId: payload.id,
        url: 'profile',
      });
      dataProfiles.push(...data);
    }

    yield put({ type: Types.SET_ACCOUNT_PROFILES, payload: dataProfiles });
    yield put({ type: Types.FETCHING_PROFILES, payload: 'fetched' });
    yield put({
      type: Types.SET_LANGUAGE,
      payload: payload.preferences.language,
    });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* updateProfile({ payload }) {
  try {
    const { profile_id, type, formData, cb } = payload;
    const { message, data } = yield call(
      API[type === 'company' ? 'updateCompanyProfile' : 'updateProfile'],
      {
        profile_id,
        formData,
      },
    );
    if (message) throw new Error(message);
    const { accountProfiles } = yield select(_authState);
    const newAccountProfiles = fromJS(accountProfiles).toJS();
    const index = newAccountProfiles.findIndex(
      profile => profile._id === profile_id,
    );
    if (index > -1) {
      newAccountProfiles[index] = merge(newAccountProfiles[index], formData);
      yield put({
        type: Types.SET_ACCOUNT_PROFILES,
        payload: newAccountProfiles,
      });
    }
    cb({ profileUpdated: data }); // todo should make socket for receiving emitter when admin will approve the changes
  } catch (error) {
    payload.cb({ error });
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* changeEmail({ payload }) {
  try {
    const { data, cb } = payload;
    const { user } = yield select(_authState);
    const { message } = yield call(API.changeEmail, {
      account_id: user.id,
      data,
    });
    if (message) throw new Error(message);
    const newUserData = merge(user, { email: data.email });
    yield put({ type: Types.SET_USER_DATA, payload: newUserData });
    cb({ data: newUserData });
  } catch (error) {
    payload.cb({
      error: { err_message: error.message || error } || 'Something went wrong!',
    });
  }
}

export function* deleteProfile({ payload }) {
  try {
    const { profile_id, cb } = payload;
    const { message } = yield call(API.deleteProfile, { profile_id });
    if (message) throw new Error(message);
    const authState = yield select(_authState);
    const accountProfiles = [...authState.accountProfiles];
    const profileIndex = accountProfiles.findIndex(e => e._id === profile_id);
    cb();
    if (profileIndex > -1) {
      accountProfiles.splice(profileIndex, 1);
      yield put({ type: Types.SET_ACCOUNT_PROFILES, payload: accountProfiles });
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* acceptCookies() {
  try {
    const accountId = yield select(getUserAccountId);
    const { data } = yield call(API.acceptCookies, accountId);

    if (data) {
      yield put({ type: Types.ACCEPT_COOKIES });
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* acceptRecover() {
  try {
    const accountId = yield select(getUserAccountId);
    const { data } = yield call(API.acceptRecover, accountId);

    if (data) {
      yield put({ type: Types.ACCEPT_RECOVER });
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* createProfile({ payload }) {
  try {
    const { accountType, data, cb } = payload;
    const {
      data: { message, result },
    } = yield call(
      API[accountType === 'company' ? 'createCompanyProfile' : 'createProfile'],
      { data },
    );
    if (message) throw new Error(message);
    const authState = yield select(_authState);
    const accountProfiles = [...authState.accountProfiles];
    result.forEach(res => accountProfiles.push(res));
    cb({ result });
    yield put({ type: Types.SET_ACCOUNT_PROFILES, payload: accountProfiles });
  } catch (error) {
    payload.cb({ error: { err_message: error.message || error } });
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* deleteAccount({ payload }) {
  try {
    const { cb } = payload;
    const {
      data: { result },
      message,
    } = yield call(API.deleteAccount);
    if (message) throw new Error(message);
    if (result) {
      cb();
      logout(0, '/deleted-account');
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* addPW({ payload }) {
  try {
    const { key, data } = payload;
    const { user } = yield select(_authState);
    const _user = fromJS(user).toJS();
    if (key === 'website') {
      const {
        data: { result },
        message,
      } = yield call(API.addWebSite, {
        account_id: user.id,
        data,
      });
      if (message) throw new Error(message);
      if (result) {
        _user.contact?.websites.push({ url: data.url });
      }
    } else if (key === 'phone') {
      const existPhoneNumber = _user.contact?.phone_numbers.find(
        item =>
          data.phone_country_code === item.phone_country_code &&
          data.phone_number === item.phone_number,
      );
      if (!existPhoneNumber) {
        yield call(API.addPhone, {
          account_id: user.id,
          data,
        });
      }
      if (existPhoneNumber) {
        _user.contact?.phone_numbers.forEach(item => {
          if (
            data.phone_country_code === item.phone_country_code &&
            data.phone_number === item.phone_number
          ) {
            item.is_verified = true;
          }
        });
      } else {
        !existPhoneNumber &&
          _user.contact?.phone_numbers.push({
            is_verified: false,
            ...data,
          });
      }
    }
    yield put({ type: Types.SET_USER_DATA, payload: { ..._user } });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  } finally {
    payload?.cb();
  }
}

export function* deletePW({ payload }) {
  try {
    const { cb, key, data } = payload;
    const { user } = yield select(_authState);
    const _user = fromJS(user).toJS();
    if (key === 'website') {
      const {
        data: { result },
        message,
      } = yield call(API.deleteWebSite, {
        account_id: user.id,
        url: data.url,
      });
      if (message) throw new Error(message);
      if (result) {
        _user.contact.websites = _user.contact?.websites.filter(
          e => e.url !== data.url,
        );
      }
    } else if (key === 'phone') {
      const {
        data: { result },
        message,
      } = yield call(API.deletePhone, {
        account_id: user.id,
        ...data,
      });
      if (message) throw new Error(message);
      if (result) {
        _user.contact.phone_numbers = _user.contact?.phone_numbers.filter(
          e =>
            +(e.phone_country_code + e.phone_number) !==
            +(data.phone_country_code + data.phone_number),
        );
      }
    }
    yield put({ type: Types.SET_USER_DATA, payload: { ..._user } });
    cb();
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export default function* authSaga() {
  yield all([
    takeEvery(Types.SET_USER_DATA, getProfiles),
    takeEvery(Types.UPDATE_PROFILE_DATA, updateProfile),
    takeEvery(Types.DELETE_PROFILE, deleteProfile),
    takeEvery(Types.CREATE_PROFILE, createProfile),
    takeEvery(Types.CHANGE_EMAIL, changeEmail),
    takeEvery(Types.DELETE_ACCOUNT, deleteAccount),
    takeEvery(Types.ADD_P_W, addPW),
    takeEvery(Types.DELETE_P_W, deletePW),
    takeEvery(Types.SET_ACCEPT_COOKIES, acceptCookies),
    takeEvery(Types.SET_ACCEPT_RECOVER, acceptRecover),
  ]);
}
