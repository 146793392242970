import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  'tag-wrapper': {
    border: '2px solid #3378D1',
    borderRadius: '3px',
    minWidth: '60px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&.md-wrapper-tag': {
      padding: '11px 10px',
    },
    '&.sm-wrapper-tag': {
      padding: '8px 10px',
    },
    '&.with-actions': {
      justifyContent: 'space-between',
      '& p': {
        textAlign: 'center',
      },
    },
    '&.selected-tag': {
      backgroundColor: '#3378D1',
      '& p, & .count-ads, & .tag-content, & .with-x': {
        color: '#fff',
      },
    },
    '&[data-disabled="true"]': {
      opacity: 0.5,
      pointerEvents: 'none',
      userSelect: 'none',
    },
    '& span.tag-content': {
      textAlign: 'center',
      color: variables.$greyTxtColor,
      fontFamily: 'Arial',
      fontSize: '12px',
      marginBottom: '0',
      letterSpacing: '0',
      flex: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
      '& .count-ads': {
        fontSize: '12px',
        fontWeight: 'bold',
        marginLeft: '5px',
        color: variables.$greyTxtColor,
      },
      '& .with-x': {
        marginLeft: '10px',
        fontSize: '13px',
        color: variables.$helperTextColor,
      },
    },
  },
});
