import { memo } from 'react';
import Switch from '@material-ui/core/Switch';

import { useSwitchStyles } from '../makeStylesUI';

const CustomSwitch = props => {
  const classesSwitch = useSwitchStyles({});

  return <Switch {...props} classes={classesSwitch} />;
};
export default memo(CustomSwitch);
