import React, { memo } from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const WarningAndErrorBlock = props => {
  const styles = useStyles({
    color: props.type ? 'rgb(170 98 93)' : 'rgb(220 155 65)',
    backgroundColor: 'rgb(239 223 222)',
  });

  return (
    <div className={`${styles['block-row']}`}>
      <span className="icon-Lock mr-2"></span>
      {props.text}
    </div>
  );
};

WarningAndErrorBlock.propTypes = {
  text: PropTypes.string,
  type: PropTypes.bool,
};
export default memo(WarningAndErrorBlock);
