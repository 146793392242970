import React, { useEffect, useState } from 'react';

import { BREAKPOINTS, DEVICE_CHECKING } from 'utils/constants';

const useWidth = width => {
  const keys = Object.keys(BREAKPOINTS.keys).reverse();
  const devices = keys.filter(key => width <= BREAKPOINTS.keys[key]);
  return devices.pop() || 'xl';
};

export default function useMediaDevice() {
  const isSSR = typeof window !== 'undefined';
  const _width = isSSR ? window.innerWidth : null;
  const _height = isSSR ? window.innerHeight : null;
  const [deviceSize, setDeviceSize] = useState(useWidth(_width));
  const [windowWidth, setWindowWidth] = useState(_width);
  const [windowHeight, setWindowHeight] = useState(_height);
  const [deviceType, setDeviceType] = useState(null);

  function changeWindowSize() {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }

  useEffect(() => {
    const _winWidth = isSSR ? window.innerWidth : 1200;
    const _winHeight = isSSR ? window.innerHeight : 976;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const _winType = useWidth(_winWidth);
    if (deviceSize !== _winType) {
      setDeviceSize(_winType);
    }
    if (_winWidth !== windowWidth || _winHeight !== windowHeight) {
      changeWindowSize();
    }
    if (BREAKPOINTS.keys[_winType] <= DEVICE_CHECKING.mobile)
      setDeviceType('mobile');
    else if (
      BREAKPOINTS.keys[_winType] >= DEVICE_CHECKING.tablet[0] &&
      BREAKPOINTS.keys[_winType] <= DEVICE_CHECKING.tablet[1]
    )
      setDeviceType('tablet');
    else setDeviceType('desktop');
  }, [deviceSize, isSSR, windowWidth, windowHeight]);

  React.useEffect(() => {
    isSSR && window.addEventListener('resize', changeWindowSize);

    return () => {
      isSSR && window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  return {
    windowWidth,
    windowHeight,
    deviceSize,
    deviceType,
  };
}
