import { makeStyles } from '@material-ui/core/styles';
import { MediaQuery, Shadow } from 'static/styles/jss/abstracts/mixins';

const useStyles = makeStyles(() => ({
  root: { zIndex: '99999 !important' },
  paper: {
    margin: '20px 0 0 42px',
    transform: 'scale(1) !important',
    ...Shadow('0 2px 4px 0 rgba(0,0,0,0.5)'),
    borderRadius: '2px',
    overflow: 'initial',
    '&.custom-notification-popover': {
      ...MediaQuery.down({
        margin: '20px 10px 0px 10px !important',
        left: '0px !important',
        right: '0px !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: 'calc(100% - 20px) !important',
        top: '50px !important',
        '&::after': {
          right: '63px',
        },
      }).xss,
    },
    '&::after': {
      display: 'block',
      position: 'absolute',
      content: '""',
      top: '-8px',
      right: '42px',
      width: '0',
      height: '0',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #fff',
    },
  },
}));

export default useStyles;
