import { createSlice } from '@reduxjs/toolkit';

import asyncAction from 'store/actions/asyncActions';

import API from 'services/api';

const initialState = {
  shoppingCartCount: null,
};

export const getShoppingCartCount = asyncAction(
  'payments/getShoppingCartCount',
  async id => {
    const {
      data: { count },
    } = await API.getProductsCountInCart(id);

    return count;
  },
);

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  extraReducers: builder => {
    builder.addCase(getShoppingCartCount.fulfilled, (state, action) => {
      state.shoppingCartCount = action.payload;
    });
  },
});

export default paymentsSlice.reducer;
