import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { Shadow } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'error-field-picker': {
    '& input.MuiInputBase-input': {
      boxShadow: `0px 0px 1px 1px ${variables.$danger}`,
    },
  },
  'bottom-wrap': {
    lineHeight: '14px',
    position: 'absolute',
    bottom: '0px',
    fontSize: '11px',
    color: variables.$helperTextColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& .helper-text': {
      margin: '0px',
    },
  },
  'error-line': {
    fontFamily: 'Arial',
    left: '0',
    bottom: '0px',
    display: 'inline-block',
    transition: props => props.transition,
    fontSize: '11px',
    lineHeight: '14px',
    color: variables.$danger,
    width: '100%',
  },
  'timepicker-dialog': {
    zIndex: '999999 !important',
    backgroundColor: props => props.overlayBG,
  },
  'mobile-wheel-picker': {
    height: '290px',
    width: '280px',
    borderRadius: '4px',
    fontFamily: 'Arial',
    backgroundColor: props => props.backgroundColor,
    border: props => props.border,
    ...Shadow(
      '0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 11px 15px -7px rgb(0 0 0 / 20%)',
    ),
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& h4': {
      color: props => props.textColor,
      fontSize: '16px',
      fontWeight: 'bold',
    },
    '& .pickers-wrapper': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      height: '139px',
      overflow: 'hidden',
      '&::after': {
        content: '":"',
        position: 'absolute',
        left: '0',
        bottom: '0',
        right: '0',
        margin: 'auto',
        width: '8px',
        fontSize: '24px',
        fontWeight: 'bold',
        height: '37px',
        top: '0px',
        color: props => props.textColor,
      },
      '& .rmc-picker': {
        height: '141px',
        width: '50%',
        flex: 'initial',
        '& .rmc-picker-indicator': {
          height: '47px',
          borderTop: '2PX solid #3B83D7',
          borderBottom: '2PX solid #3B83D7',
          right: '0',
          margin: 'auto',
          width: '60%',
          top: '47px !important',
        },
        '& .rmc-picker-item': {
          height: '47px',
          lineHeight: '47px',
          transition: '.1s',
          fontSize: '24px',
          fontWeight: 'bold',
          color: props => props.textColor,
        },
      },
    },
    '& .actions-wrap': {
      textAlign: 'right',
      marginTop: '20px',
      '& > button': {
        outline: 'none',
        color: props => props.actionsColor,
        fontSize: '13px',
        fontWeight: 'bold',
        background: 'none',
        border: 'none',
        textTransform: 'uppercase',
        cursor: 'pointer',
      },
    },
  },
});
