import { memo } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContentText from '@material-ui/core/DialogContentText';

import LanguageSwitcher from 'Components/LanguageSwitcher';

import safeTranslate from 'utils/safeTranslate';

import { StyledDialogTitle, useLegalInformationStyles } from './styles';

const LegalInformation = ({
  setPage,
  handleClose,
  cookiesToggle,
  setCookiesToggle,
}) => {
  const t = useTranslations();
  const { root, linkBlock, labelSwitch, button } = useLegalInformationStyles();

  const handleChange = event => {
    setCookiesToggle(prevCookiesToggle => ({
      ...prevCookiesToggle,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <div className={root}>
      <StyledDialogTitle id="alert-dialog-title">
        {t('agree_popup_title')}
        <LanguageSwitcher isDialog />
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('agree_popup_content')}
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <div>
          <FormControlLabel
            control={
              <Switch
                className={labelSwitch}
                checked={cookiesToggle.legalAge}
                onChange={handleChange}
                name="legalAge"
              />
            }
          />
          <span>{t('agree_popup_toggle_1_text')}</span>
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                className={labelSwitch}
                checked={cookiesToggle.privacy}
                name="privacy"
                onChange={handleChange}
              />
            }
          />
          <span className={linkBlock}>
            {safeTranslate('agree_popup_toggle_2_text', (_, id) => setPage(id))}
          </span>
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                name="cookies"
                className={labelSwitch}
                checked={cookiesToggle.cookies}
                onChange={handleChange}
              />
            }
          />

          <span className={linkBlock}>
            {safeTranslate('agree_popup_toggle_3_text', (_, id) => setPage(id))}
          </span>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          color="secondary"
          variant="contained"
          onClick={handleClose}
          className={clsx(button, 'agree')}
          disabled={Object.values(cookiesToggle).some(value => !value)}
        >
          {t('agree_popup_button_text')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default memo(LegalInformation);
