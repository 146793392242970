import Types from '../types';

export const initialState = {
  dictionary: {},
  dictionaryErrors: {},
  lang: null,
  notifications: {
    fetchingStatus: null,
    unread_count: 0,
    popup: null,
    page: null,
  },
  notificationsList: null,
  headerRect: null,
  topPositionMySJBar: 0,
  categoriesMobileToggle: false,
  pageData: {
    page: 'any',
    layout: 'default',
  },
  disableErrorToast: false,
  errorMessage: null,
  asideMenuInPage: false,
  deviceParams: {
    deviceSize: null,
    deviceType: null,
    deviceWidth: null,
    deviceHeight: null,
  },
  account_configs: {},
  profile_configs: {},
  media_configs: {},
  geographyConfigs: {},
  isSearchSuggestionsOpen: false,
  isMobileSearchOpen: false,
  selectedProvince: '',
  selectedSettlement: '',
  userGeolocation: null,
  locations: [],
  popularCities: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_GEOGRAPHY_CONFIGS:
      return { ...state, geographyConfigs: action.payload };
    case Types.SET_LOCATIONS:
      return { ...state, locations: action.payload };
    case Types.SET_POPULAR_CITIES:
      return { ...state, popularCities: action.payload };
    case Types.SET_USER_GEOLOCATION:
      return { ...state, userGeolocation: action.payload };
    case Types.SET_DICTIONARY_ERROR:
      return { ...state, dictionaryErrors: action.payload };
    case Types.SET_SEARCH_SUGGESTIONS_OPEN:
      return { ...state, isSearchSuggestionsOpen: action.payload };
    case Types.SET_SELECTED_SETTLEMENT:
      return { ...state, selectedSettlement: action.payload };
    case Types.SET_MOBILE_SEARCH_OPEN:
      return { ...state, isMobileSearchOpen: action.payload };
    case Types.SET_SELECTED_PROVINCE:
      return { ...state, selectedProvince: action.payload };
    case Types.FETCH_DICTIONARY:
      return {
        ...state,
        dictionary: action.payload.dictionary,
        lang: action.payload.lang,
      };
    case Types.SET_LANGUAGE:
      return { ...state, lang: action.payload };
    case Types.SET_MEDIA_CONFIGS:
      return { ...state, media_configs: action.payload };
    case Types.DISABLE_ERROR_MESSAGE:
      return {
        ...state,
        disableErrorToast: action.payload,
        errorMessage: !action.payload ? null : state.errorMessage,
      };
    case Types.SOMETHING_WENT_WRONG:
      return { ...state, errorMessage: action.payload };
    case Types.SET_PAGE_DATA:
      return { ...state, pageData: action.payload };
    case Types.TOGGLE_CATEGORIES_MENU:
      return { ...state, categoriesMobileToggle: action.payload };
    case Types.SET_DEVICE_PARAMS:
      return { ...state, deviceParams: action.payload };
    case Types.SET_TOP_MY_SJ_BAR:
      return { ...state, topPositionMySJBar: action.payload };
    case Types.SET_HEADER_RECT:
      return { ...state, headerRect: action.payload };
    case Types.SAVE_NOTIFICATION_LIST:
      return {
        ...state,
        notificationsList: {
          total: action.payload.total,
          result: action.payload.result,
        },
      };
    case Types.UPDATE_NOTIFICATION:
      const index = state.notificationsList.result.findIndex(
        not => not._id === action.id,
      );
      const newNotificationsList = { ...state.notificationsList };
      newNotificationsList.result[index].is_read = true;
      return { ...state, notificationsList: newNotificationsList };
    case Types.SET_NOTIFICATIONS:
      state.notifications[action.payload.key] = action.payload.data;
      state.notifications.unread_count = action.payload.unread_count || 0;
      state.notifications.fetchingStatus =
        action.payload.fetchingStatus || state.notifications.fetchingStatus;
      return { ...state, notifications: { ...state.notifications } };
    case Types.SET_ACCOUNT_CONFIGS:
      return { ...state, account_configs: action.payload };
    case Types.SET_PROFILE_CONFIGS:
      return { ...state, profile_configs: action.payload };
    default:
      return state;
  }
};

export default reducer;
