import { memo } from 'react';
import PropTypes from 'prop-types';

import { Image } from 'Components/Shared/UI';

import getMergedName from 'utils/getMergedName';

import useStyles from './styles';

const Avatar = props => {
  const classes = useStyles({
    avatarBG: props.avatarBG,
    avatarColor: props.avatarColor,
    radius: props.radius,
    wrapperWidth: props.width,
    wrapperHeight: props.height,
  });

  const initials = getMergedName(props.name, true);

  return (
    <div
      className={`${classes['avatar-wrapper']} ${props.className}`}
      {...props.events}
    >
      {props.avatarSrc || props.defaultSrc ? (
        <Image
          loadOnlySrc={props.loadOnlySrc}
          src={props.avatarSrc}
          defaultSrc={props.defaultSrc}
        />
      ) : (
        <span className="like-avatar">{initials}</span>
      )}
    </div>
  );
};

Avatar.defaultProps = {
  radius: '50%',
  avatarBG: 'var(--base-dark-color-90)',
  avatarColor: '#fff',
  events: {},
};

Avatar.propTypes = {
  avatarSrc: PropTypes.string.isRequired,
  defaultSrc: PropTypes.string,
  className: PropTypes.string,
  events: PropTypes.object,
  loadOnlySrc: PropTypes.bool,
  name: PropTypes.string,
  radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  avatarBG: PropTypes.string,
  avatarColor: PropTypes.string,
};
export default memo(Avatar);
