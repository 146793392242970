import Types from '../types';

export const setToggleProfileDetails = payload => ({
  type: Types.SET_PROFILE_DETAILS_TOGGLE,
  payload,
});

export const setNeedToUpdate = payload => ({
  type: Types.NEED_TO_UPDATE,
  payload,
});

export const getMsgSettings = payload => ({
  type: Types.GET_MSG_SETTINGS,
  payload,
});

export const updateMsgSettings = payload => ({
  type: Types.UPDATE_MSG_SETTINGS,
  payload,
});

export const toggleThreadsLoading = payload => ({
  type: Types.TOGGLE_THREADS_LOADING,
  payload,
});

export const updateFilters = payload => ({
  type: Types.UPDATE_FILTERS,
  payload,
});

export const setFilters = payload => ({
  type: Types.SET_FILTERS,
  payload,
});

export const getFilters = payload => ({
  type: Types.GET_FILTERS,
  payload,
});

export const getUnreadCount = payload => ({
  type: Types.GET_UNREAD_COUNT,
  payload,
});

export const setDiscreteColor = payload => ({
  type: Types.SET_DISCRETE_COLOR,
  payload,
});
