import PropTypes from 'prop-types';
import { useTranslations } from 'next-intl';
import { useSelector } from 'react-redux';
import MenuList from 'dummyData/MySJMenuList';
import useMemoSelector from 'hooks/useMemoSelector';
import Link from 'next/link';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { getShowName, unreadCountAsBadge } from 'utils/handlers';
import { logout } from 'utils/handlers';

import { unreadCounts, deviceParams } from 'store/reselect';

import useStyles from './styles';

const MySJMenu = props => {
  const t = useTranslations();
  const router = useRouter();
  const { counts, deviceType, accountProfiles } = useMemoSelector(store => ({
    counts: unreadCounts()(store),
    accountProfiles: store.auth.accountProfiles,
    deviceType: deviceParams()(store).deviceType,
  }));

  const companyType = accountProfiles[0]?._meta.type;

  const classes = useStyles({ companyType });

  const profileNames = {
    couple: (
      <>
        <span className="side-bar-dialog--title">
          {getShowName(accountProfiles[0], 7)}
        </span>
        <span className="side-bar-dialog--title separator">&</span>
        <span className="side-bar-dialog--title">
          {getShowName(accountProfiles[1], 7)}
        </span>
      </>
    ),
    single: (
      <span className="side-bar-dialog--title single-title">
        {getShowName(accountProfiles[0], 15)}
      </span>
    ),
    company: (
      <span className="side-bar-dialog--title company-title">
        {getShowName(
          accountProfiles.find(({ _meta }) => _meta.type === 'company'),
          15,
        )}
      </span>
    ),
  };

  if (deviceType === 'mobile' && !props.dialog) {
    return null;
  }

  return (
    <div
      className={`${classes['main-wrapper-sj-menu']} external-binding-menu`}
      ref={props.refBind}
    >
      {!props.dialog && (
        <div className="sj-menu_title">
          <h3>{t('profile_navigation_title')}</h3>
        </div>
      )}
      <div
        className={`sj-main-list--block ${
          props.dialog ? 'side-bar-dialog' : 'side-bar'
        }`}
      >
        {props.dialog && (
          <div className="side-bar-dialog--title-container">
            {profileNames[companyType]}
          </div>
        )}
        <ul className="sj-main--list">
          {MenuList.map(listItem => (
            <li key={listItem.title} onClick={e => props.handleClick(e)}>
              {listItem.link ? (
                <Link
                  href={listItem.link}
                  as={listItem.link}
                  exact={listItem.exact}
                  delay={props.delay}
                >
                  <a>
                    <span
                      className={clsx({
                        'active-link-menu': router.pathname.includes(
                          listItem.link,
                        ),
                      })}
                    >
                      <span
                        className={listItem.icon}
                        style={{
                          color: 'rgb(199, 199, 199)',
                          fontSize: '20px',
                          marginRight: '10px',
                        }}
                      />
                      {t(listItem.title)}
                      {!!counts[listItem.key] &&
                        unreadCountAsBadge(
                          counts[listItem.key],
                          9,
                          'count-badge',
                        )}
                    </span>
                  </a>
                </Link>
              ) : (
                <span onClick={logout}>
                  <span
                    style={{
                      color: 'rgb(199, 199, 199)',
                      fontSize: '20px',
                      marginRight: '10px',
                    }}
                    className={listItem.icon}
                  />
                  {t(listItem.title)}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
MySJMenu.defaultProps = {
  dialog: true,
  userName: 'Name',
  delay: 0,
  handleClick: () => void 0,
};
MySJMenu.propTypes = {
  delay: PropTypes.number,
  refBind: PropTypes.object,
  userName: PropTypes.string,
  dialog: PropTypes.bool,
  handleClick: PropTypes.func,
};
export default MySJMenu;
