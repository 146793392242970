import { memo } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FaArrowLeft } from 'react-icons/fa';
import { useTranslations } from 'next-intl';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Accordion from '@material-ui/core/Accordion';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { RiArrowDownSLine } from 'react-icons/ri';

import {
  necessaryCookieInfo,
  functionalCookieInfo,
  marketingCookieInfo,
} from './config';
import {
  useCookiesStatementStyles,
  StyledDialogTitle,
  useFormControlStyle,
} from './styles';

const CookiesInfo = ({ cookies, title }) => {
  const t = useTranslations();
  const { root, cookieBody, heading } = useCookiesStatementStyles();

  if (!cookies.length) {
    return null;
  }

  return (
    <div className={root}>
      <DialogContentText>{title}</DialogContentText>
      {cookies.map(([cookieKey, info]) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<RiArrowDownSLine />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={heading}>{cookieKey}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className={cookieBody}>
                <Typography className="bold">Placed By</Typography>
                <Typography>{info.placedBy}</Typography>
              </div>
              <div className={cookieBody}>
                <Typography className="bold">Expires after</Typography>
                <Typography>{info.expiresAfter}</Typography>
              </div>
              <div className={cookieBody}>
                <Typography className="bold">Description</Typography>
                <Typography>{t(info.description)}</Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

function CustomizeCookies({
  handleGoBack,
  customizeCookies,
  setCustomizeCoolies,
}) {
  const t = useTranslations();
  const { formControl } = useFormControlStyle();
  const { necessary, functional, marketing } = customizeCookies;

  const necessaryCookies = Object.entries(necessaryCookieInfo);
  const marketingCookies = Object.entries(marketingCookieInfo);
  const functionalCookies = Object.entries(functionalCookieInfo);

  const handleChange = event => {
    setCustomizeCoolies(prevCustomizeCookies => ({
      ...prevCustomizeCookies,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <>
      <StyledDialogTitle id="alert-dialog-title">
        <FaArrowLeft style={{ marginBottom: 5 }} onClick={handleGoBack} />{' '}
        {t('customize_cookies_title')}
      </StyledDialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={formControl}>
          <DialogContentText>Choose cookies</DialogContentText>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  name="necessary"
                  checked={necessary}
                  onChange={handleChange}
                />
              }
              label={t('customize_necessary_cookies_label')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={functional}
                  onChange={handleChange}
                  name="functional"
                />
              }
              label={t('customize_functional_cookies_label')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={marketing}
                  onChange={handleChange}
                  name="marketing"
                />
              }
              label={t('customize_marketing_cookies_label')}
            />
          </FormGroup>
        </FormControl>
        <CookiesInfo
          title={t('customize_necessary_cookies_label')}
          cookies={necessaryCookies}
        />
        <CookiesInfo
          title={t('customize_functional_cookies_label')}
          cookies={functionalCookies}
        />
        <CookiesInfo
          title={t('customize_marketing_cookies_label')}
          cookies={marketingCookies}
        />
      </DialogContent>
    </>
  );
}

export default memo(CustomizeCookies);
