import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { Shadow } from 'static/styles/jss/abstracts/mixins';

const radioCheckboxExtend = {
  '& .checkbox-wrap': {
    display: 'flex',
  },
  '& label': {
    paddingBottom: '0 !important',
    marginBottom: '0 !important',
    fontSize: variables.$input.$fontSize,
    cursor: 'pointer',
    fontWeight: 400,
    '& a': {
      color: variables.$labelLink,
    },
  },
  '& input': {
    opacity: '0',
    position: 'absolute',
    zIndex: '22',
  },
  marginBottom: variables.$input.$marginBottom,
  display: 'flex',
  flexDirection: 'row-reverse !important',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '&.error-field': {
    '& .checkbox-wrap': {
      '&::after': {
        borderColor: variables.$danger,
      },
    },
    '& label': {
      color: 'inherit !important',
    },
    '& .input-icon': {
      display: 'none',
    },
  },
  '&.md-parent-wrapper': {
    '& .input-element-wrapper': {
      height: '18px',
      '& .checkbox-wrap, & > input': {
        width: '18px !important',
        height: '18px',
        minWidth: '18px',
        minHeight: '18px',
      },
    },
  },
  '&.sm-parent-wrapper': {
    '& .input-element-wrapper': {
      height: '14px',
      '& .checkbox-wrap, & > input': {
        width: '14px !important',
        height: '14px',
        minWidth: '14px',
        minHeight: '14px',
      },
    },
  },
};

export default makeStyles({
  'wrap-with-checkbox': {
    border: 'none !important',
    color: props => props.labelColor,
    '&:not(.material-input-wrapper)': {
      ...radioCheckboxExtend,
      '& .checkbox-wrap': {
        marginRight: '13px',
        position: 'relative',
        '&:not(.selected-half-part)::before': {
          display: 'none',
          content: '""',
          top: '0px',
          bottom: '3px',
          left: '0',
          margin: 'auto',
          right: '0',
          width: '6px',
          height: '12px',
          border: 'solid white',
          borderWidth: '0 2px 2px 0',
          '-webkit-transform': 'rotate(45deg)',
          '-ms-transform': 'rotate(45deg)',
          transform: 'rotate(45deg)',
          position: 'absolute',
          zIndex: '2',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          border: ({ disabled }) =>
            `2px solid ${
              disabled
                ? variables.$input.$checkbox.$disabledBorderColor
                : variables.$input.$checkbox.$borderColor
            }`,
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          borderRadius: variables.$input.$radius,
        },
      },
    },
  },
  'wrap-with-radio': {
    border: 'none !important',
    color: props => props.labelColor,
    '&:not(.material-input-wrapper)': {
      ...radioCheckboxExtend,
      '& .checkbox-wrap': {
        marginRight: '13px',
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
          content: '""',
          border: `2px solid ${variables.$input.$checkbox.$borderColor}`,
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          boxSizing: 'border-box',
        },
        '&::after': {
          display: 'none',
          content: '""',
          position: 'absolute',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          boxSizing: 'border-box',
        },
      },
    },
  },
  'input-wrap': {
    userSelect: 'none',
    '& .input-element-wrapper': {
      display: 'flex',
      position: 'relative',
    },
    '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: '10px',
      },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '& .material-input-wrapper': {
      '& > input': {
        boxShadow: 'none !important',
        borderBottom: '1px solid #D8D8D8 !important',
        '&:focus': {
          outline: 'none',
        },
        '&::before, &::after': {
          display: 'none',
        },
      },
    },
    position: 'relative',
    width: 'fit-content',
    fontFamily: 'Arial',
    height: 'fit-content',
    '& label': {
      fontWeight: '600',
      paddingBottom: '7px',
      marginBottom: '0',
      color: props => props.labelColor,
    },
    '& .input-icon': {
      '&.input-icon-left': {
        left: '20px',
        '& > .icon-Search': {
          opacity: '0.5',
          color: 'gray',
        },
        '& > .icon-Back': {
          color: '#3478d1',
        },
      },
      '& > .icon-Close': {
        color: 'gray',
      },
      cursor: 'pointer',
      textAlign: 'left',
      width: '30px',
      height: '30px',
      position: 'absolute',
      right: 0,
      top: '30%',
      zIndex: '22',
      lineHeight: 'normal',
      fontSize: `${variables.$input.$iconSize}px`,
      transform: props => props.iconTop.translate,
      '&.loading-icon': {
        height: `${variables.$input.$iconSize}px`,
        width: `${variables.$input.$iconSize}px`,
        marginTop: props => props.iconTop.marginTop,
      },
      '& .toggle-password': {
        color: '#c3cbd5',
      },
    },
    '& .error-line': {
      fontFamily: 'Arial',
      position: 'absolute',
      left: '0',
      bottom: ({ maxLength }) => (maxLength ? '0' : '-12px'),
      display: 'inline-block',
      transition: 'all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
      fontSize: '11px',
      lineHeight: '14px',
      color: variables.$danger,
      width: '100%',
    },

    // Helper Text
    '& .bottom-wrap': {
      lineHeight: '14px',
      marginTop: 5,
      bottom: '0px',
      fontSize: '14px',
      color: variables.$helperTextColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& .helper-text': {
        margin: '0px',
        fontSize: '11px',
      },
      '& .counter': {
        marginLeft: 'auto',
      },
    },
    '&:not(.material-input-wrapper)': {
      '& .input-element-wrapper:hover input': {
        ...variables.$input.$hover,
        '&:read-only': {
          ...Shadow('none'),
        },
      },
      '& .icon-Lock': {
        color: `${variables.$input.$readOnlyColor} !important`,
      },
      // Input
      '& input': {
        transitionDuration: props => props.transitionDuration,
        '&[type="checkbox"], &[type="radio"]': {
          height: '18px',
          minHeight: '18px',
          minWidth: '18px',
          width: '18px !important',
        },
        '&[type="text"], &[type="password"], &[type="email"], &[type="number"]':
          {
            fontFamily: 'Arial',
            boxSizing: 'border-box',
            width: '100%',
            position: 'relative',
            borderRadius: variables.$input.$radius,
            border: 'none',
            zIndex: 2,
            display: 'flex',
            padding: ({ iconLeft }) => (iconLeft ? '0 52px' : '0 10px'),
            transitionProperty: 'margin-bottom, box-shadow',
            transitionTimingFunction: 'cubic-bezier(0.04, 1.1, 1, 0.99)',
            '&:hover': {
              ...variables.$input.$hover,
            },
            '&:focus': {
              ...variables.$input.$focus,
            },
            '&::-webkit-input-placeholder': {
              color: variables.$input.$placeholderColor,
            },
          },
        backgroundColor: props => props.backgroundColor,
        border: props => props.border,
        '&[fakereadonly="true"]': {
          pointerEvents: 'unset',
          backgroundColor: '#fff !important',
          color: '#000 !important',
        },
        '&[type="text"][readonly]': {
          backgroundColor: variables.$input.$readOnly,
          pointerEvents: 'none',
          color: `${variables.$input.$readOnlyColor} !important`,
          ...Shadow('none'),
        },
      },
      '& input[type="checkbox"], & input[type="radio"]': {
        cursor: 'pointer',
        margin: '0',
        minWidth: 'fit-content !important',
        padding: '0 !important',
      },
      '& input:disabled': {
        opacity: 1,
        backgroundColor: variables.$input.$disabledColor,
      },
    },
    '& input.input-md': {
      padding: variables.$input.$md.$padding,
      fontSize: variables.$input.$md.$fontSize,
      height: variables.$fieldsSizes.$md,
      lineHeight: variables.$fieldsSizes.$md,
    },
    '& input.input-sm': {
      fontSize: variables.$input.$sm.$fontSize,
      padding: variables.$input.$sm.$padding,
      height: variables.$fieldsSizes.$sm,
      lineHeight: variables.$fieldsSizes.$sm,
    },
    '& input.input-xs': {
      padding: variables.$input.$xs.$padding,
      fontSize: variables.$input.$xs.$fontSize,
      height: variables.$fieldsSizes.$xs,
      lineHeight: variables.$fieldsSizes.$xs,
    },

    '& label.label-md': {
      fontSize: variables.$input.$md.$fontSize,
    },
    '& label.label-sm': {
      fontSize: variables.$input.$sm.$fontSize,
    },
    '& label.label-xs': {
      fontSize: variables.$input.$xs.$fontSize,
    },
    '&.error-field': {
      '& label': {
        color: `${variables.$danger} !important`,
      },
      '& .input-element-wrapper input, & .input-element-wrapper textarea': {
        marginBottom: props => props.marginBottom,
        ...Shadow(`0 0 1px 1px ${variables.$danger} inset !important`),
        paddingRight: '25px !important',
      },
      '& .material-input-wrapper': {
        '& > input': {
          boxShadow: 'none !important',
          borderBottom: `1px solid ${variables.$danger} !important`,
          '&:focus': {
            outline: 'none',
            boxShadow: 'none !important',
          },
          '&::before, &::after': {
            display: 'none',
          },
        },
      },
      '& i.error-icon': {
        color: `${variables.$danger} !important`,
        top: '38%',
      },
    },
  },
  'with-icon': {
    '& input, & textarea': {
      paddingRight: '25px !important',
    },
    '& i[class^="icon-"]': {
      '&.toggle-password': {
        cursor: 'pointer',
      },
      '&.clickable': {
        cursor: 'pointer',
      },
      '&.icon-eye-blocked': {
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '20px',
          height: '1px',
          backgroundColor: variables.$input.$iconColor,
          transform: 'rotate(-45deg)',
          left: '-3px',
          top: '7px',
        },
      },
      // color: variables.$input.$iconColor,
    },
  },
  'theme-light': {
    '&:not(.material-input-wrapper)': {
      '& textarea': {
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#EFEFEF',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgb(199, 199, 199)',
        },
      },
      '& input, & textarea': {
        backgroundColor: variables.$input.$theme.$light,
      },
      // CheckBox
      '& input[type="checkbox"]': {
        '&:checked + .checkbox-wrap, &[checked="true"] + .checkbox-wrap': {
          '&::before': {
            display: 'block',
          },
          '&.selected-half-part': {
            '&::before': {
              backgroundColor: `${variables.$input.$checkbox.$onLightBG} !important`,
              zIndex: '2',
            },
            '&::after': {
              backgroundColor: '#fff !important',
            },
          },
        },
        '&:checked + .checkbox-wrap::after, &[checked="true"] + .checkbox-wrap::after':
          {
            backgroundColor: variables.$input.$checkbox.$onLightBG,
            borderColor: variables.$input.$checkbox.$onLightBG,
          },
      },
      // Radio
      '& input[type="radio"]': {
        '&:checked + .checkbox-wrap::before, &[checked="true"] + .checkbox-wrap::before':
          {
            borderColor: variables.$input.$checkbox.$onLightBG,
          },
        '&:checked + .checkbox-wrap::after, &[checked="true"] + .checkbox-wrap::after':
          {
            display: 'block',
            backgroundColor: variables.$input.$checkbox.$onLightBG,
          },
      },
    },
  },
  'theme-dark': {
    '&:not(.material-input-wrapper)': {
      border: `1px solid ${variables.$input.$darkBorderColor}`,
      '& textarea': {
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#07224D',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#98A4B3',
        },
      },
      '& input, & textarea': {
        backgroundColor: variables.$input.$theme.$dark,
        color: variables.$input.$theme.$darkColor,
      },
      '& .input-icon': {
        color: `${variables.$input.$theme.$darkColor}!important`,
      },
      // CheckBox
      '& input[type="checkbox"]': {
        '&:checked + .checkbox-wrap::before, &[checked="true"] + .checkbox-wrap::before':
          {
            display: 'block',
            borderColor: variables.$baseColor,
          },
        '&:checked + .checkbox-wrap::after, &[checked="true"] + .checkbox-wrap::after':
          {
            backgroundColor: variables.$input.$checkbox.$onDarkBG,
            borderColor: variables.$input.$checkbox.$onDarkBG,
          },
      },

      // Radio
      '& input[type="radio"]': {
        '&:checked + .checkbox-wrap::before, &[checked="true"] + .checkbox-wrap::before':
          {
            borderColor: variables.$input.$checkbox.$onDarkBG,
          },
        '&:checked + .checkbox-wrap::after, &[checked="true"] + .checkbox-wrap::after':
          {
            display: 'block',
            backgroundColor: variables.$input.$checkbox.$onDarkBG,
          },
      },
    },
  },
  'transparent-input-wrap': {
    '&:not(.material-input-wrapper)': {
      flexDirection: 'row !important',
      alignItems: 'center',
      borderBottom: `1px solid ${variables.$input.$transparentInput.$borderColor}`,
      padding: '4px 0',
      height: 'auto',
      '& .input-icon': {
        color: `${variables.$input.$theme.$darkColor}!important`,
      },
      '& .bottom-wrap': {
        left: '110px',
        bottom: '1px',
        '& p.helper-text': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          fontSize: '11px',
          color: variables.$helperTextColor,
        },
        '& p.helper-text + .counter': {
          marginLeft: '10px',
        },
      },
      '&.error-field': {
        borderBottom: `2px solid ${variables.$danger}`,
        transition: 'all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s',
        paddingBottom: props => props.marginBottom,
        '& .error-line': {
          bottom: '0px',
          left: '110px',
          width: 'calc(100% - 110px)',
        },
        '& .input-element-wrapper input, & .input-element-wrapper textarea': {
          ...Shadow('none !important'),
        },
      },
      '& label': {
        padding: '0px !important',
        width: '110px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      '& .input-element-wrapper': {
        '& textarea, & input': {
          width: '100%',
          border: 'none !important',
          background: 'none !important',
          margin: '0 !important',
          paddingLeft: '0 !important',
          '&:read-only': {
            color: '#fff !important',
          },
        },
        '&:hover input, &:hover textarea': {
          ...Shadow('none !important'),
        },
        '& input:focus, & textarea:focus': {
          ...Shadow('none !important'),
        },
      },
    },
  },
});
