import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import nlLocale from 'date-fns/locale/nl';
import { isToday, isYesterday, isTomorrow, addDays, isPast } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { InputAdornment } from '@material-ui/core';
import variablesJSS from 'static/styles/jss/abstracts/variables';
import { useTranslations } from 'next-intl';
import useMemoSelector from 'hooks/useMemoSelector';

import { UiGenerateMargin, UIGetMarginLeftRight } from 'utils/handlers';

import { getLanguage } from 'store/reselect';

import { useDatePickerStyles, usePickerPaperStyles } from '../makeStylesUI';

import Actions from './components/actions';
import useStyles from './styles';

const DatePicker = props => {
  const t = useTranslations();
  const [isMobile, setIsMobile] = useState(false);
  const [disablePrevBtn, setDisablePrevBtn] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    changed: null,
    selected: null,
  });
  const [datePickerOwnError, setDatePickerOwnError] = useState(null);
  const [marginBottom, setMarginBottom] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [allowTransition, setAllowTransition] = useState(false);
  const parentRef = useRef();
  const errorRef = useRef();
  const bottomWrapRef = useRef();
  const inputWrapperRef = useRef();
  const classes = useStyles();
  const userLanguage = useMemoSelector(getLanguage);

  const convertDateToDays = date => {
    if (isYesterday(date)) return t('date_picker_yesterday');
    if (isToday(date)) return t('date_picker_today');
    if (isTomorrow(date)) return t('date_picker_tomorrow');
    return null;
  };

  // Get position of Main Wrapper
  const generateMarginDiv = useCallback(
    () => UiGenerateMargin(props.margin, props.direction),
    [props.direction, props.margin],
  );

  const currentDate = useCallback(
    () => selectedDate.selected || props.value || new Date(),
    [selectedDate, props.value],
  );

  const sharedPropsOfClasses = useMemo(() => {
    const backgroundColor =
      props.theme === 'dark'
        ? variablesJSS.$input.$theme.$dark
        : variablesJSS.$input.$theme.$light;
    const boxShadow =
      props.theme === 'dark'
        ? `${variablesJSS.$select.$dark.$borderColor} 0px 0px 0px 1px inset`
        : 'none';
    return {
      backgroundColor,
      boxShadow,
      transition: allowTransition
        ? 'margin-bottom 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)'
        : '0s',
      marginBottom: marginBottom || '0px',
      isDark: props.theme === 'dark',
      valueColor:
        props.theme === 'dark'
          ? variablesJSS.$datepicker.$dark.$textColor
          : variablesJSS.$datepicker.$textColor,
      actionsColor:
        props.theme === 'dark'
          ? variablesJSS.$datepicker.$dark.$actionsColor
          : variablesJSS.$datepicker.$actionsColor,
      weekColor:
        props.theme === 'dark'
          ? variablesJSS.$datepicker.$dark.$weekColor
          : variablesJSS.$datepicker.$weekColor,
      placeholderColor:
        props.theme === 'dark'
          ? variablesJSS.$select.$dark.$emptyTextColor
          : variablesJSS.$select.$emptyTextColor,
      size: props.size,
      isOpened: isOpen,
      iconColor:
        props.theme === 'dark' ? variablesJSS.$input.$theme.$darkColor : '#000',
      dayNearly: convertDateToDays(currentDate()),
    };
  }, [
    isOpen,
    props.size,
    props.theme,
    currentDate,
    marginBottom,
    selectedDate,
    props.value,
    allowTransition,
  ]);

  const classesDatePaper = usePickerPaperStyles(sharedPropsOfClasses);

  const classesDatePicker = useDatePickerStyles(sharedPropsOfClasses);

  const handleDateChange = date => {
    setSelectedDate({
      selected: selectedDate.selected,
      changed: date,
    });
  };

  const setBottomActions = container => {
    // The DatePicker doesn't have cancel or ok button for mobile that is why we should make by custom
    const cancelPicker = () => {
      setIsOpen(false);
    };
    const selectPicker = () => {
      if (!selectedDate.changed) {
        setSelectedDate({
          selected: props.value || new Date(),
          changed: null,
        });
      } else {
        setSelectedDate({
          selected: selectedDate.changed,
          changed: selectedDate.changed,
        });
      }
      setIsOpen(false);
      props.change(selectedDate.changed);
    };
    if (isOpen && container) {
      const actionsWrap = document.createElement('DIV');
      actionsWrap.className = 'datepicker-actions-wrap';
      container.appendChild(actionsWrap);
      ReactDOM.render(
        <Actions
          t={t}
          onClick={() => cancelPicker()}
          onSelect={() => selectPicker()}
        />,
        container.querySelector('.datepicker-actions-wrap'),
      );
    }
  };

  const inlineStylesParent = useMemo(
    () =>
      // Set inline styles on the Main Wrapper
      ({
        ...generateMarginDiv(),
        width: props.fullWidth
          ? `calc(100% - ${UIGetMarginLeftRight(props.margin)}px)`
          : props.width,
        ...(props.label && {
          display: 'flex',
          flexDirection: 'column',
        }),
        ...(!props.label && {
          display: 'block',
        }),
        ...props.customStylesDiv,
      }),
    [
      props.customStylesDiv,
      props.label,
      props.fullWidth,
      props.direction,
      props.width,
      props.margin,
    ],
  );

  const generateClassNameParent = useMemo(
    () =>
      `${
        classes[props.theme ? `theme-${props.theme}` : '']
      } ${`wrapper-${props.size}`} ${classes['input-wrap']} ${
        props.errors ? 'error-field' : ''
      }`,
    [props.errors, props.theme, props.size],
  );

  const arrows = useMemo(() => {
    const changeDirection = dir => {
      const date = addDays(currentDate(), dir === 'prev' ? -1 : 1);
      if (
        !isToday(date) &&
        isPast(date) &&
        props.shouldDisablePastDate &&
        dir !== 'next'
      )
        return;
      setSelectedDate({
        selected: date,
        changed: date,
      });
      props.change(date);
    };
    return (
      <div className={'arrows-slide-datepicker'}>
        <svg
          onClick={() => changeDirection('prev')}
          className={`left-icon ${disablePrevBtn ? 'disable-btn' : ''}`}
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path d="M7 10l5 5 5-5z"></path>
        </svg>
        <svg
          onClick={() => changeDirection('next')}
          className={'right-icon'}
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path d="M7 10l5 5 5-5z"></path>
        </svg>
      </div>
    );
  }, [
    currentDate,
    disablePrevBtn,
    selectedDate.selected,
    props.value,
    props.options,
  ]);

  const bottomWrap = useMemo(() => {
    if (
      (props.helperText || props.required) &&
      !props.errors &&
      !datePickerOwnError
    ) {
      const text =
        props.helperText || (props.required && t('required_field_text'));
      return (
        <div className={classes['bottom-wrap']} ref={bottomWrapRef}>
          {text && <p className="helper-text">{text}</p>}
        </div>
      );
    }
    if (props.errors || datePickerOwnError)
      return (
        <span ref={errorRef} className={classes['error-line']}>
          {props.errors || datePickerOwnError}
        </span>
      );
  }, [props.helperText, props.errors, errorRef, datePickerOwnError]);

  useEffect(() => {
    // Detect if device is Mobile
    if (props.deviceType === 'mobile') {
      setIsMobile(true);
    } else setIsMobile(false);
  }, [props.deviceType]);

  useEffect(() => {
    // Calculate Margin Bottom
    const bottomElementHeight = errorRef.current
      ? errorRef.current.offsetHeight + 2
      : bottomWrapRef.current
      ? bottomWrapRef.current.offsetHeight + 2
      : 0;
    setMarginBottom(`${bottomElementHeight /* marginTopBottom */}px`);
  }, [
    props.required,
    props.errors,
    errorRef.current,
    props.helperText,
    bottomWrapRef.current,
    datePickerOwnError,
  ]);

  useEffect(() => {
    // Disable the prev/next buttons
    const date = currentDate();
    if (isToday(date) || (isPast(date) && props.shouldDisablePastDate)) {
      setDisablePrevBtn(true);
    } else setDisablePrevBtn(false);
  }, [selectedDate, currentDate, props.value, props.shouldDisablePastDate]);

  useEffect(() => {
    if (marginBottom) {
      setAllowTransition(true);
    }
  }, [marginBottom]);

  useEffect(() => {
    if (selectedDate.selected) {
      datePickerOwnError && setDatePickerOwnError(null);
    }
  }, [selectedDate.selected]);

  useEffect(() => {
    props.change(props.value || new Date());
  }, []);

  return (
    <div
      style={inlineStylesParent}
      className={generateClassNameParent}
      ref={parentRef}
    >
      <MuiPickersUtilsProvider
        {...(userLanguage === 'nl' && { locale: nlLocale })}
        utils={DateFnsUtils}
      >
        <div
          ref={inputWrapperRef}
          className="input-element-wrapper"
          style={props.customStylesInput}
        >
          <KeyboardDatePicker
            InputAdornmentProps={{ position: 'start' }}
            keyboardIcon={<span className="icon-Event"></span>}
            classes={classesDatePicker}
            onError={err => err && setDatePickerOwnError(err)}
            InputLabelProps={{
              disableAnimation: true,
              variant: 'filled',
              focused: false,
              shrink: true,
            }}
            placeholder={props.format}
            disableToolbar
            KeyboardButtonProps={{
              onFocus: () => {
                setIsOpen(true);
              },
              'aria-label': 'change date',
            }}
            PopoverProps={{
              key: selectedDate.changed,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              classes: classesDatePaper,
              className: `datePicker ${
                isMobile ? 'mobile-mode_datePicker' : ''
              }`,
              disableRestoreFocus: true,
              onClose: () => {
                setIsOpen(false);
              },
              TransitionProps: {
                onEnter: e => setBottomActions(e),
              },
            }}
            InputProps={{
              endAdornment: props.slideByArrows ? (
                <InputAdornment position="end">{arrows}</InputAdornment>
              ) : null,
              onFocus: () => {
                setIsOpen(true);
              },
            }}
            disablePast={props.shouldDisablePastDate}
            fullWidth
            disabled={props.disabled}
            open={isOpen}
            inputVariant="filled"
            variant={'inline'}
            format={props.format}
            margin="normal"
            label={
              props.label && props.label.title + (props.required ? ' *' : '')
            }
            value={currentDate()}
            onChange={handleDateChange}
          />
          {bottomWrap}
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};
DatePicker.defaultProps = {
  size: 'md',
  type: 'text',
  fullWidth: false,
  margin: 0,
  required: false,
  theme: 'light',
  customStylesInput: {},
  customStylesDiv: {},
  format: 'MM/dd/yyyy',
  width: 310,
  shouldDisablePastDate: true,
  slideByArrows: false,
  deviceType: 'desktop',
};
DatePicker.propTypes = {
  format: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  slideByArrows: PropTypes.bool,
  theme: PropTypes.string,
  helperText: PropTypes.string,
  direction: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  label: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.string,
  change: PropTypes.func,
  customStylesDiv: PropTypes.object,
  customStylesInput: PropTypes.object,
  shouldDisablePastDate: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  deviceType: PropTypes.string.isRequired,
};
export default React.memo(DatePicker);
