import { makeStyles } from '@material-ui/core/styles';

import variables from '../abstracts/variables';
import {
  MediaQuery,
  Shadow,
  SubTitleInsideMainContent,
  TitleInsideMainContent,
  ScrollList,
} from '../abstracts/mixins';
import {
  CheckedIconBefore,
  FlexInputsWrapper,
  MainContent,
  StickerBadgeGold,
  StickerBadgeSilver,
} from '../abstracts/extends';

const style = makeStyles({
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  '@global': {
    ':root': {
      '--body-color': variables.$bodyColor,
      '--base-color': variables.$baseColor,
      '--base-light-color-30': variables.$baseColorLight_30,
      '--divider-color': variables.$dividerColor,
      '--bold-text-color': '#fff',
      '--bold-text-selected-color': '#fff',
      '--text-color-discrete-white': '#fff',
      '--selected-bg': variables.$baseColorDark_30,
      '--not-seen-count-bg': variables.$danger,
      '--text-selected': variables.$helperTextColor,
      '--white-grey-text-color': variables.$helperTextColor,
      '--base-dark-color-30': variables.$baseColorDark_30,
      '--base-dark-color-50': variables.$baseColorDark_50,
      '--base-dark-color-60': variables.$baseColorDark_60,
      '--base-dark-color-90': variables.$baseColorDark_90,
      '--white-base-dark-color-90': variables.$baseColorDark_90,
    },
    '.Toastify': {
      zIndex: 99999,
      ...MediaQuery.down({
        '& .Toastify__toast': {
          borderRadius: '50px',
          minHeight: '50px',
          fontSize: '14px',
          width: '90%',
          margin: '0 auto 1rem',
          textAlign: 'center',
          opacity: 0.9,
          '& .Toastify__close-button': {
            display: 'none',
          },
        },
      }).sm,
    },
    '.sticky-top.sticky-mobile': {
      ...MediaQuery.up({
        top: 'auto',
        zIndex: 'initial',
        position: 'initial',
      }).sm,
    },
    '.container': {
      maxWidth: '1290px',
      '&.nested-container': {
        paddingRight: '8px',
        paddingLeft: '8px',
      },
    },
    a: {
      textDecoration: 'none !important',
    },
    body: {
      backgroundColor: '#003f8f !important',
      '&.discrete-mode': {
        backgroundColor: '#fff !important',
      },
      '&.cant-be-discrete-mode': {
        '& #__next': {
          backgroundColor: 'var(--body-color)',
        },
      },
      '&.cant-hover--device': {
        paddingRight: '0px !important',
      },
      '&.aside-menu-is-open': {
        '&::after': {
          right: '0',
          zIndex: '99999',
          content: '""',
          position: 'fixed',
          top: '0',
          width: '100%',
          height: '100%',
          background: 'rgba(0,0,0,0.2)',
        },
      },
      '&.body--fixed__overflow--no-scroll': {
        overflow: 'hidden',
        paddingRight: '0px',
        position: 'fixed',
        width: '100vw',
      },
      '&.body--fixed__overflow': {
        overflow: 'hidden',
        paddingRight: variables.$bodyPaddingRight,
        '&.cant-hover--device': {
          paddingRight: '0px',
        },
      },
      '&.aside-menu-is-open, &.modal-is-open, &.body--fixed': {
        overflow: 'hidden',
        position: 'fixed',
        width: '100%',
        paddingRight: variables.$bodyPaddingRight,
        '&.cant-hover--device': {
          paddingRight: '0px',
        },
      },
    },
    'body, *': {
      margin: '0px',
      boxSizing: 'border-box',
      fontFamily: 'Arial',
    },
    main: {
      flex: '1 0 auto',
      padding: '16px 8px',
      '&.Content': {
        backgroundColor: 'var(--body-color)',
        '&.discrete-view': {
          backgroundColor: '#fff',
        },
      },
    },
    '#__next': {
      minHeight: '100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      ...MediaQuery.up({
        paddingTop: variables.headerHeightDT,
      }).sm,
    },
    '.global-pages-links': {
      fontSize: '13px',
      cursor: 'pointer',
      color: '#fff',
      '&:hover': {
        color: '#fff',
      },
    },
    '.combined-elements-shadow': {
      ...MediaQuery.down({
        ...Shadow('0 2px 4px 0 rgba(0,0,0,0.5)'),
        '& .with-mobile-shadow': {
          ...Shadow('none !important'),
        },
      }).sm,
    },
    '.flex-btns': {
      display: 'flex',
      alignItems: 'baseline',
      flexWrap: 'wrap',
    },
    'ul.list-of-checked': {
      listStyleType: 'none',
      paddingLeft: '0',
      '& li': {
        color: variables.$helperTextColor,
        paddingLeft: '20px',
        position: 'relative',
        fontSize: '13px',
        margin: '5px 0',
        ...CheckedIconBefore,
      },
    },
    '.form-flex-inputs-title': {
      ...TitleInsideMainContent({
        fontSize: '13px',
        marginBottom: '0px',
        paddingBottom: '7px',
      }),
    },
    '.like-helper-text': {
      ...SubTitleInsideMainContent({
        color: variables.$helperTextColor,
        fontSize: '11px',
      }),
    },
    '.like-error-text': {
      fontSize: '12px',
      marginTop: '5px',
      marginBottom: '10px',
      color: variables.$danger,
    },
    '.like-label-input-filed': {
      marginBottom: '16px',
      position: 'relative',
      '& .title-content': {
        cursor: 'pointer',
        marginBottom: '0px',
        paddingBottom: '7px',
        fontSize: '13px',
      },
      '& .error-line_bt': {
        left: '0',
        color: variables.$danger,
        width: '100%',
        bottom: '0px',
        display: 'inline-block',
        position: 'absolute',
        fontSize: '11px',
        fontFamily: 'Arial',
        lineHeight: '14px',
      },
      '& .helper-text_bt': {
        position: 'relative',
        bottom: '0',
        margin: '0',
        fontSize: '11px',
        color: variables.$helperTextColor,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '14px',
        justifyContent: 'space-between',
      },
      '& .flexible-wrap': {
        display: 'flex',
      },
    },
    '.flex-inputs': {
      ...FlexInputsWrapper,
    },
    '.like-form-secondary-title, .title-content': {
      ...TitleInsideMainContent(),
      '&.title-content--secondary': {
        fontSize: '13px',
      },
    },
    '.sub-title-content': {
      ...SubTitleInsideMainContent(),
    },
    '.location-by-country-container': {
      display: 'flex',
    },
    '.main-content-of-section, .content-with-shadow': {
      ...MainContent,
    },
    '.bg-light-section': {
      backgroundColor: variables.$lightBlue,
      '&.with-margin-negative': {
        margin: '0 -8px',
      },
    },
    '.popup-proper-container': {
      backgroundColor: '#fff',
      ...Shadow('0 2px 4px 0 rgba(0,0,0,0.5)'),
      borderRadius: '2px',
      zIndex: '999',
    },
    'span[class^="icon-"]': {
      '&[data-icon-active="true"]': {
        position: 'relative',
        '&::after': {
          position: 'absolute',
          content: '""',
          width: 'calc(100% + 15px)',
          height: 'calc(100% + 15px)',
          background: variables.$baseColorDark_60,
          left: '50%',
          transform: 'translate(-50%, -50%)',
          top: '50%',
          borderRadius: '50%',
        },
      },
    },
    '#loader-infinite': {
      margin: '30px 0',
      fontSize: '15px',
      fontWeight: '600',
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      '& > img': {
        maxWidth: '20px',
        marginRight: '6px',
      },
    },
    '#loading-indicator': {
      margin: '5px 0 0',
      position: 'absolute',
      top: 10,
      zIndex: 9,
      marginInline: 'auto',
      left: 0,
      right: 0,
      height: 40,
      width: 40,
      background: 'var(--base-dark-color-90)',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...Shadow('0 3px 6px 0 rgb(0 0 0 / 15%)'),
      '&:after': {
        content: '""',
        width: '22px',
        height: '22px',
        border: '2px solid #fff',
        borderRadius: '50%',
        borderTopColor: 'transparent',
        animation: '$spin 1s linear infinite',
      },
    },
    '.popover-container': {
      marginTop: '13px',
      padding: '10px',
    },
    '.bar-options-popup_block': {
      '& .bar-options__list-wrapper': {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        '& li': {
          '& i[class^=icon-]': {
            color: variables.$iconColor,
            marginRight: '6px',
          },
          cursor: 'pointer',
          fontSize: '13px',
        },
      },
    },
    '.scroll-list-250': {
      ...ScrollList(250),
    },
    '.scroll-list-200': {
      ...ScrollList(200),
    },
    '.loading-icon': {
      width: '13px',
      height: '13px',
      display: 'inline-block',
      boxSizing: 'border-box',
      border: '1px solid #e2dede',
      borderRadius: '50%',
      borderRightColor: '#555',
      animation: '$spin 1s linear infinite',
      '&.width-2x': {
        width: '20px',
        height: '20px',
      },
    },
    '.sticker__tile': {
      '&.sticker__tile--gold': {
        ...StickerBadgeGold,
      },
      '&.sticker__tile--silver': {
        ...StickerBadgeSilver,
      },
      width: 'fit-content',
    },
    '.mt-20': {
      marginTop: '20px',
    },
    '.mb-20': {
      marginBottom: '20px',
    },
    'div[class*="col-"]': {
      paddingRight: '8px',
      paddingLeft: '8px',
    },
    '.nested-grid--row': {
      marginRight: '-8px',
      marginLeft: '-8px',
    },
  },
});
export default style;
