import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useMemoSelector from 'hooks/useMemoSelector';
import useMount from 'hooks/use-mount';

import { MEDIA } from 'utils/constants';

import { getShoppingCartCount } from 'store/toolkit/payments';
import { getProfileValuesForHeader, getLoggedUserData } from 'store/reselect';

import API from 'services/api';

import ShoppingCart from './elements/shopping-cart';
import Notifications from './elements/notifications';
import UserAvatar from './elements/user-avatar';
import Messages from './elements/messages';

const Logged = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);

  const { userData, count, accountID } = useMemoSelector(store => ({
    userData: getProfileValuesForHeader()(store),
    count: store.payments?.shoppingCartCount,
    accountID: getLoggedUserData()(store)?.id,
  }));

  useMount(() => {
    dispatch(getShoppingCartCount(accountID));
  });

  useEffect(() => {
    if (userData) {
      API.getImage(userData)
        .then(res => {
          const newPath = res.data.path ? res.data.path.split('.') : '';
          setUser({
            cartCount: 10,
            notifications: [{}, {}, {}],
            avatarSrc: res.data.path
              ? `${MEDIA.storagePath}/${newPath[0]}_thumb_100.jpg`
              : '',
          });
        })
        .catch(() => {
          setUser({
            cartCount: 10,
            notifications: [{}, {}, {}],
            avatarSrc: '',
          });
        });
    } else {
      setUser({
        cartCount: 10,
        notifications: [{}, {}, {}],
        avatarSrc: '',
      });
    }
  }, [userData]);

  return (
    user && (
      <>
        <ShoppingCart count={count} />
        <Notifications data={user.notifications} />
        <Messages />
        <UserAvatar src={user.avatarSrc} />
      </>
    )
  );
};
Logged.propTypes = {};
export default React.memo(Logged);
