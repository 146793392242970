const getMergedName = (name, isShort) => {
  const names = Array.isArray(name) ? name : [name];

  return names.reduce((acc, name, idx) => {
    if (name) {
      const initial = isShort ? name.charAt(0).toUpperCase() : name;

      if (idx !== 0) {
        acc += isShort ? '&' : ' & ';
      }
      acc += initial;
    }

    return acc;
  }, '');
};

export default getMergedName;
