import parse from 'html-react-parser';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import DOMPurify from 'dompurify';

export default (entryKey, handleNodeClick) => {
  const t = useTranslations();

  try {
    return parse(DOMPurify.sanitize(t.raw(entryKey)), {
      replace: node => {
        if (node.name === 'a' && node.attribs.href.startsWith('/')) {
          return <Link href={node.attribs.href}>{node.children[0].data}</Link>;
        }

        if (node.name === 'span' && typeof handleNodeClick === 'function') {
          return (
            <span onClick={e => handleNodeClick(e, node.attribs.id)}>
              {node.children[0].data}
            </span>
          );
        }

        if (node.name === 'br') {
          return <br />;
        }

        return node;
      },
    });
  } catch (err) {
    console.error('err');
    return <p>ERROR IN TRANSLATION!</p>;
  }
};
