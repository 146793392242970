import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'notification-item-block': {
    padding: '10px 10px 5px 5px',
    display: 'flex',
    position: 'relative',
    '& .icon-Overflow-menu': {
      position: 'absolute',
      right: '6px',
      color: variables.$iconColor,
      cursor: 'pointer',
    },
    '& .row': {
      margin: 0,
    },
    '& .unread': {
      border: '1px solid red',
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      backgroundColor: 'red',
      position: 'absolute',
      left: '34px',
    },
    '& .notification-img-block': {
      width: '50px',
    },
    '& .notification-details-block': {
      flex: 1,
    },
    '& .notification-txt': {
      fontSize: '12px',
      color: variables.$helperTextColor,
      marginLeft: '10px',
      marginBottom: '12px',
      '&  a': {
        color: variables.$labelLink,
        paddingLeft: '7px',
        wordBreak: 'break-all',
      },
      '&.isRead > span > div': {
        color: variables.$helperTextColor,
      },
      '&:not(.isRead)': {
        color: '#5D5D5D',
      },
      '& > span': {
        color: 'white',
      },
    },
    '& .notification-txt-dialog': {
      fontSize: '12px',
      color: variables.$helperTextColor,
      marginLeft: '10px',
      marginBottom: '12px',
      '&  a': {
        color: '#3778D1',
        paddingLeft: '7px',
        wordBreak: 'break-all',
      },
      '&.isRead > span > div': {
        color: '#5D5D5D',
        fontWeight: 'normal',
      },
      '&:not(.isRead)': {
        color: '#5D5D5D',
        fontWeight: 'bold',
      },
      '& > span': {
        color: '#5D5D5D',
      },
    },
  },
  'notification-btns-block': {
    display: 'flex',
  },
  'date-notification': {
    width: '50px',
    top: '25px',
    right: '5px',
    position: 'absolute',
    marginRight: '5px',
    color: variables.$borderGrey,
    fontSize: '12px',
    textAlign: 'right',
    ...MediaQuery.down({
      right: '0px',
    }).sm,
  },
  'notification-container': {
    borderBottom: `1px solid ${variables.$baseColorDark_30}`,
    fontWeight: '600',
    '& .notification-txt': {
      '& .date-notification': {
        position: 'relative',
        right: 'initial',
        top: 'initial',
        color: variables.$borderGrey,
      },
    },
  },
});
