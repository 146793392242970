import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { Shadow } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  '@global': {
    '.phone-mask-dropdown--block': {
      padding: '5px 10px',
      margin: '0',
      listStyleType: 'none',
      '& li': {
        margin: '10px 5px',
        fontSize: '14px',
        cursor: 'pointer',
        '&.active-phone-mask': {
          fontWeight: '600',
        },
        '& img': {
          marginRight: '10px',
          width: '30px',
          height: '20px',
        },
      },
    },
  },
  'phone--block': {
    position: 'relative',
    transition: '.4s',
    paddingBottom: props => props.marginBottom,
    '& .input-line--block': {
      position: 'relative',
      '& > img': {
        width: '30px',
        position: 'absolute',
        left: '10px',
        height: '20px',
        bottom: '0',
        top: '0',
        margin: 'auto',
        zIndex: '22',
      },
      '& .phone-mask--block': {
        fontFamily: 'Arial',
        boxSizing: 'border-box',
        width: '100%',
        position: 'relative',
        borderRadius: variables.$input.$radius,
        zIndex: 2,
        display: 'flex',
        transitionProperty: 'margin-bottom, box-shadow',
        backgroundColor: props => props.backgroundColor,
        border: props => props.border,
        lineHeight: 'inherit',
        paddingLeft: '50px !important',
        '&:hover': {
          ...variables.$input.$hover,
        },
        '&:focus': {
          ...variables.$input.$focus,
        },
        '&::-webkit-input-placeholder': {
          color: variables.$input.$placeholderColor,
        },
        '&:disabled': {
          opacity: 1,
        },
        '&:read-only': {
          backgroundColor: variables.$input.$readOnly,
          pointerEvents: 'none',
          color: `${variables.$input.$readOnlyColor} !important`,
        },
        '&.input-md': {
          padding: variables.$input.$md.$padding,
          fontSize: variables.$input.$md.$fontSize,
          lineHeight: variables.$fieldsSizes.$md,
          height: variables.$fieldsSizes.$md,
        },
        '&.input-sm': {
          fontSize: variables.$input.$sm.$fontSize,
          padding: variables.$input.$sm.$padding,
          lineHeight: variables.$fieldsSizes.$sm,
          height: variables.$fieldsSizes.$sm,
        },
        '&.input-xs': {
          padding: variables.$input.$xs.$padding,
          fontSize: variables.$input.$xs.$fontSize,
          lineHeight: variables.$fieldsSizes.$xs,
          height: variables.$fieldsSizes.$xs,
        },
      },
      '& .arrow-icon': {
        width: '30px',
        position: 'absolute',
        right: '0',
        height: '30px',
        bottom: '0',
        top: '0',
        margin: 'auto',
        zIndex: '22',
        cursor: 'pointer',
      },
    },
    '& .error-line': {
      fontFamily: 'Arial',
      fontSize: '11px',
      lineHeight: '14px',
      color: variables.$danger,
      width: '100%',
      margin: '5px 0 0 0',
    },
    '&.phone--block__dark': {
      border: `1px solid ${variables.$input.$darkBorderColor}`,
      '& .phone-mask--block': {
        backgroundColor: variables.$input.$theme.$dark,
        color: variables.$input.$theme.$darkColor,
      },
      '& svg': {
        fill: '#fff',
      },
    },
    '&.phone--block__material': {
      '&.phone--block__material-focused': {
        '& .input-line--block': {
          '&::after': {
            transform: 'scaleX(1)',
          },
        },
      },
      '& .input-line--block': {
        '&::before': {
          content: '""',
          backgroundColor: '#D8D8D8',
          position: 'absolute',
          bottom: '0',
          width: '100%',
          height: '2px',
        },
        '&::after': {
          left: '0',
          right: '0',
          bottom: '0',
          content: '""',
          position: 'absolute',
          transform: 'scaleX(0)',
          transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          borderBottom: '2px solid #3f51b5',
        },
        '& .phone-mask--block': {
          backgroundColor: 'transparent',
          '&:hover, &:focus': {
            ...Shadow('none !important'),
          },
        },
      },
    },
  },
});
