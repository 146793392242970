import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  'input-wrap': {
    userSelect: 'none',
    '& .input-element-wrapper': {
      position: 'relative',
      fontFamily: 'Arial',
      '& .MuiFormHelperText-root': {
        display: 'none',
      },
      '& .MuiFilledInput-root:hover': {
        backgroundColor: '#fff',
      },
      '& .MuiFormControl-marginNormal': {
        margin: '0 !important',
      },
    },
    '&.wrapper-md': {
      '& .arrows-slide-datepicker': {
        '& svg': {
          width: '30px',
        },
      },
    },
    '&.wrapper-sm': {
      '& .arrows-slide-datepicker': {
        '& svg': {
          width: '25px',
        },
      },
    },
    '&.wrapper-xs': {
      '& .arrows-slide-datepicker': {
        '& svg': {
          width: '20px',
        },
      },
    },
  },
  'bottom-wrap': {
    lineHeight: '14px',
    position: 'absolute',
    bottom: '0px',
    fontSize: '11px',
    color: variables.$helperTextColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& .helper-text': {
      margin: '0px',
    },
  },
  'error-line': {
    fontFamily: 'Arial',
    position: 'absolute',
    left: '0',
    bottom: '0px',
    display: 'inline-block',
    transition: 'all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
    fontSize: '11px',
    lineHeight: '14px',
    color: variables.$danger,
    width: '100%',
  },
  'theme-light': {},
  'theme-dark': {},
});
