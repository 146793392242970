import { all, call, put, takeEvery } from 'redux-saga/effects';

import { MEDIA, ADVERTISEMENTS } from 'utils/constants';
import getApproveData from 'utils/getApproveData';

import API from 'services/api';

import Types from '../types';

export function* getStepFiveValues({
  setFormValuesofDate,
  setFormDataProlongAd,
  categoryId,
}) {
  try {
    const reqRespones = yield call(() =>
      (async () =>
        await Promise.allSettled([
          API.getProductsInventory(),
          API.getPricesInventory(categoryId),
        ]))(),
    );

    if (reqRespones[0].status === 'fulfilled') {
      const pricesObj = {};

      if (reqRespones[1].status === 'fulfilled') {
        reqRespones[1].value.data.prices.forEach(el => {
          pricesObj[el.product_name] = el;
        });
      } else {
        throw new Error(reqRespones[1].message);
      }

      yield put({
        type: Types.SAVE_STEP_FIVE_VALUES,
        payload: {
          products: reqRespones[0].value.data.products,
          prices: pricesObj,
        },
      });

      const newFormDataProlongAd = {
        ad: {
          periodCount: ADVERTISEMENTS.periodCountOptions[0].value,
          periodInterval: ADVERTISEMENTS.periodIntervalOptions[0].value,
        },
        website: {
          periodCountWeb: ADVERTISEMENTS.periodCountOptions[0].value,
          periodIntervalWeb: ADVERTISEMENTS.periodIntervalOptions[0].value,
        },
      };
      const newFormValuesofDate = {
        ad: {},
        website: {},
      };

      reqRespones[0].value.data.products.forEach(el => {
        newFormDataProlongAd[el.name] = {
          periodCount: ADVERTISEMENTS.periodCountOptions[0].value,
          periodInterval: ADVERTISEMENTS.periodIntervalOptions[0].value,
        };
        newFormValuesofDate[el.name] = {};
      });

      setFormValuesofDate(prevData => ({
        ...newFormValuesofDate,
        ...prevData,
      }));
      setFormDataProlongAd(newFormDataProlongAd);
    } else {
      throw new Error(reqRespones[0].message);
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message },
    });
  }
}

export function* getProfileAdList({
  paramsOfListing,
  setActiveTab,
  newActiveTab,
}) {
  try {
    const { data, message } = yield call(API.getProfileAds, paramsOfListing);
    if (message) {
      throw new Error(message);
    }
    setActiveTab(newActiveTab);
    yield put({ type: Types.SET_MY_AD, payload: { data, type: newActiveTab } });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* filterProfileAdList({ id, activeTab, body }) {
  try {
    const { data, message } = yield call(API.getProfileFilteredAds, {
      id,
      offset: 0,
      limit: 10,
      status: activeTab,
      body,
    });
    if (message) {
      throw new Error(message);
    }
    yield put({
      type: Types.SAVE_FILTERED_PROFILE_AD,
      payload: {
        data,
        type: activeTab === 'draft' ? 'concepten' : activeTab,
      },
    });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* getProfileAdsListing({
  paramsOfListing,
  setTabs,
  notFirstTime,
  setPage,
  activeTab,
}) {
  try {
    if (notFirstTime) {
      const { data } = yield call(API.getProfileAds, paramsOfListing);

      if (data) {
        yield put({
          type: Types.SET_MY_ADS_AFTER_CHANGE_PAGE,
          payload: {
            data,
            type:
              paramsOfListing.purpose === 'draft'
                ? 'concepten'
                : paramsOfListing.purpose,
          },
        });
        setPage(Number(paramsOfListing.offset + 10));
      }
    } else {
      const reqValues = yield call(() =>
        (async () =>
          await Promise.allSettled([
            // API.fetchMyAds(),
            API.getAdsCount(),
            API.getProfileAds(paramsOfListing),
          ]))(),
      );

      const allAdsValues = {
        countes: {
          online: 0,
          offline: 0,
          concepten: 0,
        },
        listing: {},
      };

      if (reqValues[0].status === 'fulfilled') {
        allAdsValues.countes.concepten = reqValues[0].value.data.draft;
        allAdsValues.countes.online = reqValues[0].value.data.online;
        allAdsValues.countes.offline = reqValues[0].value.data.offline;
      }

      // allAdsValues.lists = reqValues[0].value.data;

      if (reqValues[1].status === 'fulfilled') {
        allAdsValues.listing[activeTab] = reqValues[1].value.data;
      }
      // if (reqValues[2].status === "fulfilled") {

      // }

      setTabs(
        Object.keys(allAdsValues.countes).map(el => ({
          title: `${el} (${allAdsValues.countes[el]})`,
          key: el,
        })),
      );

      yield put({ type: Types.SET_MY_ADS, payload: allAdsValues });
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* fetchMyAds() {
  try {
    const data = yield call(API.fetchMyAds);
    yield put({ type: Types.SET_MY_ADS, payload: data });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* fetchProfileAds({ payload }) {
  try {
    const { id } = payload;
    const data = yield call(API.fetchProfileAds, { id });
    yield put({ type: Types.SET_PROFILE_ADS, payload: { id, data } });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* fetchAdConfigs({ payload }) {
  try {
    const { data } = yield call(API.fetchAdConfigs);
    yield put({ type: Types.SET_AD_CONFIGS, payload: { ...(data || {}) } });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  } finally {
    payload?.cb?.();
  }
}

export function* fetchCategoriesWithSections({ payload }) {
  try {
    const { data } = yield call(API.fetchCategoriesWithSections);
    yield put({
      type: Types.SET_CATEGORIES_WITH_SECTIONS,
      payload: data || [],
    });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  } finally {
    payload?.cb?.();
  }
}

export function* fetchAd({ payload }) {
  try {
    let modifiedCategory = '';
    let categoriesList = null;
    let stepThree = null;
    const { message, data } = yield call(API.getAd, { id: payload.id });
    if (message) throw new Error(message);

    if (data.category_id) {
      const categories = yield call(API.getProfileCategories);

      if (categories.message) throw new Error(categories.message);

      if (data.type === 'regular') {
        modifiedCategory =
          categories.data.categories[
            categories.data.categories.findIndex(
              cat => cat.id === data.category_id,
            )
          ].name;
      } else {
        modifiedCategory = data.type === 'event' ? 'events' : 'vacancies';
      }
      categoriesList = { category: categories.data.categories };
    }

    if (
      data.hasOwnProperty('_changes') &&
      data._changes.hasOwnProperty('fields') &&
      ((data._changes.fields.hasOwnProperty('title') &&
        data._changes.fields.title[0].value.value) ||
        data._changes.fields.labels?.length)
    ) {
      stepThree = {
        // ...data
        location: data.location,
        labels: getApproveData(data, 'labels'),
        title: data._changes.fields.title?.[0].value.value || null,
        description: data._changes.fields.description?.[0].value.value || null,
        country: data.country,
        city: data.city,
        prices: data.prices,
        phone_instruction: data.phone_instruction,
        websites: data.websites,
        employment_type: data.employment_type ? data.employment_type : '',
        work_type: data.work_type ? data.work_type : '',
        event_type: data.event_type,
        salary: data._changes.fields.hasOwnProperty('vacancy_salary')
          ? data._changes.fields.vacancy_salary.map(sal => sal.value)
          : data.vacancy_salary
          ? data.vacancy_salary
          : '',
        start_date: data.start_date,
        vacancy_working_hours: data.vacancy_working_hours
          ? data.vacancy_working_hours
          : null,
        location_types: data.location_types
          ? data.location_types.map(el => ({ ...el, checked: true }))
          : [],
        phone_numbers: data.phone_numbers ? data.phone_numbers : [],
        availability: data.working_hours
          ? data.working_hours.map(el => ({
              checked: true,
              title: el.week_day.charAt(0).toUpperCase() + el.week_day.slice(1),
              value: el.week_day,
              time: {
                start: {
                  title: el.from,
                  value: el.from,
                },
                end: {
                  title: el.to,
                  value: el.to,
                },
              },
            }))
          : [],
        services: data.services ? data.services : [],
      };
    }

    yield put({
      type: Types.SET_AD_DRAFTS,
      payload: {
        ...data,
        category: modifiedCategory,
        category_id: data.category_id ? data.category_id : null,

        categoriesList,
        stepThree,
      },
    });

    payload.cb({
      category: modifiedCategory,
    });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
    payload.cb({ error: { err_message: error.message || error } });
  }
}

export function* getStepFourImages({ payload, setImagesList }) {
  const { data, message } = yield call(API.getAdsImagesByProfileId, payload);
  if (message) throw new Error(message);

  const deleteids = {};

  const ids = data.map(el => {
    deleteids[el.image_id] = el._id;
    return el.image_id;
  });

  if (ids.length) {
    const imagesList = yield call(API.getImagesBulk, { ids });
    if (payload.id) {
      setImagesList(imagesList.data.filter(img => img));
    } else {
      const cloneImagesList = {};

      if (imagesList.data) {
        imagesList.data.forEach(img => {
          const currentImage = data.find(
            ({ image_id }) => image_id === img._id,
          );
          if (img) {
            cloneImagesList[img._id] = {
              ...img,
              status: 'uploaded',
              coverPhoto: currentImage?.is_main,
              allowToggling: false,
              imgURL: `${MEDIA.storagePath}/${
                img.path.split('.')[0]
              }_thumb_248.jpg?v=${img.v}`,
              isSelected: true,
              _id: img._id,
              deleteId: deleteids[img._id],
            };
          }
        });
      }

      yield put({
        type: Types.SAVE_STEP_FOUR_CHECKED_IMAGES,
        payload: cloneImagesList,
      });
      setImagesList(cloneImagesList);
    }
  }
}

export function* createAd({ payload }) {
  try {
    const { adData, cb, profileId } = payload;
    const { data, message } = yield call(API.createAd, {
      data: adData,
      id: profileId,
    });

    if (message) throw new Error(message);

    const { id } = data;
    yield put({
      type: Types.SET_MAIN_VALUES,
      payload: { draftId: id, profileId, audience: payload.saveAudience },
    });
    cb(id);
  } catch (error) {
    payload.cb('Error');
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* getCategories({ payload }) {
  try {
    const { data: categories } = yield call(
      API.getProfileCategoriesWithFilter,
      payload,
    );
    yield put({
      type: Types.SAVE_CATEGORIES,
      payload: { categories: categories.categories },
    });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* saveAdAsDraft({ payload }) {
  try {
    const { message } = yield call(API.saveAdAsDraft, { data: payload.adData });
    if (message) throw new Error(message);
    payload.cb({ success: true });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
    payload.cb({ error });
  }
}

export function* updateAd({ payload }) {
  try {
    const { adData, id, cb, categoryType } = payload;
    const { message, data } = yield call(API.updateAd, { data: adData, id });
    if (message) throw new Error(message);
    if (payload.step === 3) {
      yield put({
        type: Types.SAVE_DATA_STEP_THREE,
        payload:
          categoryType === 'vacancy'
            ? {
                ...adData,
                labels: adData.labels || [],
                // vacancy_salary: adData.salary ? adData.salary : []
                salary: adData.vacancy_salary ? adData.vacancy_salary : '',
              }
            : {
                ...adData,
                // TODO: LOOK INTO THIS ISSUE ?????
                // location_types: adData.location_types
                //   ? adData.location_types.map(el => ({ ...el, checked: true }))
                //   : [],
                // availability: adData.availability
                //   ? adData.availability
                //   : adData.working_hours
                //   ? adData.working_hours.map(el => ({
                //       title:
                //         el.week_day.charAt(0).toUpperCase() +
                //         el.week_day.slice(1),
                //       value: el.week_day,
                //       checked: true,
                //       time: {
                //         start: { title: el.from, value: el.from },
                //         end: { title: el.to, value: el.to },
                //       },
                //     }))
                //   : [],
                location_types: adData.location_types
                  ? adData.location_types.map(el => ({ ...el, checked: true }))
                  : [],
              },
      });
    } else if (payload.step !== 5) {
      yield put({
        type: Types.SAVE_CATEGORY,
        payload: {
          category: adData.category,
          labels: adData.labels,
          category_id: adData.category_id,
          type: adData.type,
          lastActiveStep: adData.lastActiveStep,
        },
      });
    }

    if (payload.step === 5) {
      yield put({ type: Types.SAVE_IMAGES_STEP_FOUR, payload: adData });
    }

    if (data) {
      cb();
    }
  } catch (error) {
    payload.cb('Error');
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* createAdvertenties({ payload }) {
  try {
    const { data } = yield call(API.updateAd, {
      data: {
        ...(payload.data || {}),
        ...(!payload.isDraft && {
          is_status: payload.isInUpdatePage ? 'Updated' : 'Created',
        }),
      },
      id: payload.id,
    });

    if (data.result) {
      payload.redirectHome();
    } else {
      yield put({
        type: Types.SOMETHING_WENT_WRONG,
        payload: { err_message: 'Error' },
      });
    }
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export function* resetAddValues() {
  yield put({ type: Types.RESET_PROFILE_ADD_FROM_STATE });
}

export default function* adsSaga() {
  yield all([
    // call(fetchMyAds),
    takeEvery(Types.FETCH_AD_DRAFT, fetchAd),
    takeEvery(Types.UPDATE_AD, updateAd),
    takeEvery(Types.CREATE_AD, createAd),
    takeEvery(Types.SAVE_AS_DRAFT, saveAdAsDraft),
    takeEvery(Types.GET_STEP_FOUR_IMAGES, getStepFourImages),
    takeEvery(Types.CREATE_ADVERTENTIES, createAdvertenties),
    takeEvery(Types.GET_DRAFT_AND_CATEGORIES, getCategories),
    takeEvery(Types.FETCH_PROFILE_ADS, fetchProfileAds),
    takeEvery(Types.FETCH_AD_CONFIGS, fetchAdConfigs),
    takeEvery(
      Types.FETCH_CATEGORIES_WITH_SECTIONS,
      fetchCategoriesWithSections,
    ),
    takeEvery(Types.RESET_PROFILE_ADD_VALUES, resetAddValues),
    takeEvery(Types.GET_PROFILE_ADS_LISTING, getProfileAdsListing),
    takeEvery(Types.GET_PROFILE_AD_LIST, getProfileAdList),
    takeEvery(Types.FILTER_PROFILE_AD_LIST, filterProfileAdList),
    takeEvery(Types.GET_STEP_FIVE_VALUES, getStepFiveValues),
    // takeEvery(Types.GET_ADS_CONFIGS, fetchMyAds)
  ]);
}
