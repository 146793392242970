import { useRouter } from 'next/router';
import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';
import usePrevious from 'hooks/usePrevious';

const TOP = 'top';

const ScrollContext = createContext();

const ScrollProvider = ({ children }) => {
  const ref = useRef();
  const dataRef = useRef({});
  const router = useRouter();
  const [selectedItem, setSelectedItem] = useState(null);

  const prevRouterQuery = usePrevious(router.query);

  const scrollHandler = useCallback(
    (updatedMeasure, behavior, direction = TOP) => {
      window?.scrollTo?.({
        ...(behavior && { behavior }),
        [direction]: updatedMeasure,
      });

      const timerId = setTimeout(() => {
        setSelectedItem(null);
        clearTimeout(timerId);
      }, 1000);
    },
    [],
  );

  useEffect(() => {
    const prevSubCategory = prevRouterQuery?.subCategory;
    const isPrevPageAdDetailed = /_\d+/.test(prevSubCategory);

    if (isPrevPageAdDetailed && !router.query.category) {
      setSelectedItem(null);
    }
  }, [prevRouterQuery?.subCategory, router.query]);

  const updateData = useCallback((id, offset) => {
    dataRef.current[id] = offset;
  }, []);

  const setSelectItem = useCallback(item => {
    setSelectedItem(dataRef.current[item]);
  }, []);

  const value = useMemo(
    () => ({
      ref,
      updateData,
      setSelectItem,
      scrollHandler,
      selectedItem,
    }),
    [scrollHandler, updateData, setSelectItem, selectedItem],
  );

  return (
    <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
  );
};

const useScroll = ({ deps }) => {
  const result = useContext(ScrollContext);
  const { scrollHandler, selectedItem } = result;

  useEffect(() => {
    scrollHandler(selectedItem);
  }, deps.concat(scrollHandler)); // eslint-disable-line react-hooks/exhaustive-deps

  return result;
};

export { useScroll, ScrollProvider };
