import { all, fork } from 'redux-saga/effects';

import baseSage from './sagas/base';
import homeSage from './sagas/home';
import adsSaga from './sagas/ads';
import authSaga from './sagas/auth';
import msgSaga from './sagas/messenger';
import cartSaga from './sagas/cart';
import creditsSaga from './sagas/credits';

export default function* rootSaga() {
  yield all([
    fork(baseSage),
    fork(homeSage),
    fork(adsSaga),
    fork(authSaga),
    fork(msgSaga),
    fork(cartSaga),
    fork(creditsSaga),
  ]);
}
