import { all, call, put, takeEvery } from 'redux-saga/effects';

import API from 'services/api';

import Types from '../types';

export function* getShoppingCartInformations() {
  try {
    const [paymentMethods, payNlPaymentMethods] = yield call(() =>
      (async () =>
        await Promise.allSettled([
          API.getPayments(),
          API.getPaymentsMethods(),
        ]))(),
    );

    const methods = {
      payment_methods: {},
    };

    if (paymentMethods.status === 'fulfilled') {
      methods.payment_methods = paymentMethods.value.data.methods.reduce(
        (acc, method) => {
          if (method.name !== 'credits') {
            acc[method.name] = method;
          }
          return acc;
        },
        {},
      );
    }

    if (payNlPaymentMethods.status === 'fulfilled') {
      if (methods.payment_methods.paynl) {
        methods.payment_methods.paynl.methods =
          payNlPaymentMethods.value.data.payment_methods;
      }
    }

    yield put({
      type: Types.SAVE_PAYMENT_METHODS,
      payload: methods,
    });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export default function* cartSaga() {
  yield all([
    takeEvery(Types.GET_PAYMENTS_METHODS, getShoppingCartInformations),
  ]);
}
