import Types from '../types';

export const toggleSelectedAds = payload => ({
  type: Types.TOGGLE_SELECTED_AD,
  payload,
});

export const fetchProfileAds = payload => ({
  type: Types.FETCH_PROFILE_ADS,
  payload,
});

export const fetchAdConfigs = payload => ({
  type: Types.FETCH_AD_CONFIGS,
  payload,
});

export const fetchCategoriesWithSections = payload => ({
  type: Types.FETCH_CATEGORIES_WITH_SECTIONS,
  payload,
});

export const removeProfileAds = payload => ({
  type: Types.REMOVE_PROFILE_ADS,
  payload,
});

export const createAd = payload => ({
  type: Types.CREATE_AD,
  payload,
});

export const saveAdAsDraft = payload => ({
  type: Types.SAVE_AS_DRAFT,
  payload,
});

export const createAdvertenties = payload => ({
  type: Types.CREATE_ADVERTENTIES,
  payload,
});

export const fetchAdAsDraft = payload => ({
  type: Types.FETCH_AD_DRAFT,
  payload,
});

export const updateAd = payload => ({
  type: Types.UPDATE_AD,
  payload,
});

export const resetAd = () => ({
  type: Types.RESET_ADS,
});

export const getCategories = payload => ({
  type: Types.GET_DRAFT_AND_CATEGORIES,
  payload,
});

export const resetAddValues = () => ({
  type: Types.RESET_PROFILE_ADD_VALUES,
});

export const setNeedToUpdateOnlineAd = payload => ({
  type: Types.NEED_TO_UPDATE_ONLINE_AD,
  payload,
});
