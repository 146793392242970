import { Component } from 'react';

import Boundary from 'Components/ErrorPages/Boundary';

import API from 'services/api';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    API.sendFrontError({ error: error.toString(), errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <Boundary />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
