import { useState, memo } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslations } from 'next-intl';
import MUISwitch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContentText from '@material-ui/core/DialogContentText';

import safeTranslate from 'utils/safeTranslate';

import Types from 'store/types';

import { stepsTranslations } from './config';
import { StyledDialogTitle, useLegalInformationStyles } from './styles';

const Switch = ({ name, checked, className, handleChange }) => (
  <FormControlLabel
    control={
      <MUISwitch
        name={name}
        checked={checked}
        className={className}
        onChange={handleChange}
      />
    }
  />
);

const RegistrationPermissions = ({ setPage, handleClose }) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const [steps, setSteps] = useState(1);
  const { root, linkBlock, labelSwitch, button, dialogTitle } =
    useLegalInformationStyles();
  const [state, setState] = useState({
    termsAndConditions: false,
    personalData: false,
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClickCloseButton = async () => {
    if (steps === 2) {
      dispatch({ type: Types.SET_ACCEPT_COOKIES });
      handleClose();
    } else {
      setSteps(2);
    }
  };

  return (
    <div className={root}>
      <StyledDialogTitle id="alert-dialog-title">
        {t('registration_permissions_popup_title')}
      </StyledDialogTitle>
      <DialogTitle className={dialogTitle} id="alert-dialog-title">
        {t(stepsTranslations[steps].subTitle)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={linkBlock} id="alert-dialog-description">
          {safeTranslate(stepsTranslations[steps].content, (_, id) =>
            setPage(id),
          )}
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <div>
          {steps === 1 ? (
            <Switch
              className={labelSwitch}
              name="termsAndConditions"
              handleChange={handleChange}
              checked={state.termsAndConditions}
            />
          ) : (
            <Switch
              name="personalData"
              className={labelSwitch}
              handleChange={handleChange}
              checked={state.personalData}
            />
          )}
          <span
            className={clsx(linkBlock, 'bold', { agreeContent: steps === 2 })}
          >
            {safeTranslate(
              stepsTranslations[steps].togglePermissions,
              (_, id) => setPage(id),
            )}
          </span>
        </div>
      </DialogContent>

      <DialogActions
        style={{
          padding: '8px 20px',
          justifyContent: steps === 1 ? 'flex-end' : 'space-between',
        }}
      >
        {steps === 2 && (
          <Button
            color="secondary"
            variant="contained"
            className={clsx(button, 'back')}
            onClick={() => setSteps(1)}
          >
            {t(stepsTranslations[steps].backButtonText)}
          </Button>
        )}
        <Button
          variant="contained"
          className={clsx(button, 'agree')}
          onClick={handleClickCloseButton}
          disabled={
            (steps === 1 && !state.termsAndConditions) ||
            (steps === 2 && !state.personalData)
          }
        >
          {t(stepsTranslations[steps].acceptButtonText)}
        </Button>
      </DialogActions>
    </div>
  );
};

export default memo(RegistrationPermissions);
