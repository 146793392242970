import { memo } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { setSelectedProvince } from 'store/actions';

import { useSuggestionItemStyle, useLocationStyles } from '../styles';

const Provinces = ({ provinces, suggestionListRef }) => {
  const dispatch = useDispatch();
  const { suggestionItem } = useSuggestionItemStyle();

  const {
    provinceStyle,
    locationWrapper,
    provinceInfoTextStyle,
    provinceArrowIconStyle,
  } = useLocationStyles();

  return provinces.map(province => (
    <a
      href="#"
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          dispatch(setSelectedProvince(province));
        }
      }}
    >
      <li
        key={province}
        className={clsx(suggestionItem, 'provincesList')}
        onClick={e => {
          e.preventDefault();
          dispatch(setSelectedProvince(province));
          suggestionListRef.current.scroll(0, 0);
        }}
      >
        <div>
          <div className={locationWrapper}>
            <span className={provinceStyle}>{province}</span>
            <span className={provinceInfoTextStyle}>
              Province, The Netherlands
            </span>
          </div>
        </div>
        <span
          className={clsx(
            provinceArrowIconStyle,
            'lnr lnr-chevron-right sj-slider_next-btn',
          )}
        ></span>
      </li>
    </a>
  ));
};

export default memo(Provinces);
