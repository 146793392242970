import Link from 'next/link';
import { Container } from 'reactstrap';
import { useTranslations } from 'next-intl';

import useStyles from './styles';

const Footer = () => {
  const t = useTranslations();
  const styles = useStyles();

  return (
    <footer>
      <Container className={styles['main-container_footer']}>
        <div className={styles['main-container_footer_item']}>
          <h4 className="title-content">{t('footer_title_1')}</h4>

          <Link href={'/about-us'} prefetch={false}>
            <a>{t('footer_title_1_subtext_1')}</a>
          </Link>
          <Link href={'/safety-information'} prefetch={false}>
            <a> {t('footer_title_1_subtext_2')}</a>
          </Link>
          <Link href={'/promotion'} prefetch={false}>
            <a>{t('footer_title_1_subtext_3')}</a>
          </Link>
        </div>

        <div className={styles['main-container_footer_item']}>
          <h4 className="title-content">{t('footer_title_2')}</h4>

          <a
            href={'https://klantenservice.sexjobs.nl/nl/support/tickets/new'}
            target="_blank"
            rel="noreferrer"
          >
            {t('footer_title_2_subtext_1')}
          </a>
          <a
            href={
              'https://klantenservice.sexjobs.nl/nl/support/solutions/42000064710'
            }
            target="_blank"
            rel="noreferrer"
          >
            {t('footer_title_2_subtext_2')}
          </a>
          <a
            href={'https://klantenservice.sexjobs.nl/nl/support/tickets/new'}
            target="_blank"
            rel="noreferrer"
          >
            {t('footer_title_2_subtext_3')}
          </a>
          <a
            href={
              'https://klantenservice.sexjobs.nl/nl/support/solutions/folders/42000102181'
            }
            target="_blank"
            rel="noreferrer"
          >
            {t('footer_title_2_subtext_4')}
          </a>
        </div>

        <div className={styles['main-container_footer_item']}>
          <h4 className="title-content">{t('footer_title_3')}</h4>

          <Link href={'/terms-condition'} prefetch={false}>
            <a> {t('footer_title_3_subtext_1')}</a>
          </Link>
          <Link href={'/cookie-statement'} prefetch={false}>
            <a>{t('footer_title_3_subtext_2')}</a>
          </Link>
          <Link href={'/privacy-policy'} prefetch={false}>
            <a>{t('footer_title_3_subtext_3')}</a>
          </Link>
        </div>
      </Container>
    </footer>
  );
};
Footer.propTypes = {};
export default Footer;
