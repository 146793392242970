import Types from '../types';

export const initialState = {
  profileDetailsToggle: false,
  emptyThreads: false,
  settings: {},
  unreadCount: 0,
  filters: [],
  threadsLoading: false,
  selectedThreadId: null,
  adDetails: null,
  adDetailsProfiles: [],
  configs: {},
  needToUpdate: false,
  discreteColor: undefined,
  undo: {
    deleteConversation: null,
    reportConversation: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.TOGGLE_THREADS_LOADING:
      return { ...state, threadsLoading: action.payload };
    case Types.SET_DISCRETE_COLOR:
      return { ...state, discreteColor: action.payload };
    case Types.SET_MESSENGER_CONFIGS:
      return { ...state, configs: action.payload };
    case Types.SET_FILTERS:
      return { ...state, filters: action.payload };
    case Types.SET_PROFILE_DETAILS_TOGGLE:
      return { ...state, profileDetailsToggle: action.payload };
    case Types.SET_UNREAD_COUNT:
      return { ...state, unreadCount: action.payload };
    case Types.SET_MSG_SETTINGS:
      return { ...state, settings: action.payload };
    case Types.NEED_TO_UPDATE:
      return { ...state, needToUpdate: action.payload };
    default:
      return state;
  }
};

export default reducer;
