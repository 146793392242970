import PropTypes from 'prop-types';

const Actions = props => (
  <>
    <button onClick={props.onClick}>{props.t('calendar_cancel_button')}</button>
    <button
      onClick={props.onSelect}
      className={props.disableSelect ? 'disable-select' : ''}
    >
      {props.t('calendar_select_button')}
    </button>
  </>
);
Actions.propTypes = {
  disableSelect: PropTypes.bool,
};
export default Actions;
