import React, { useState } from 'react';
import Link from 'next/link';
import Popover from '@material-ui/core/Popover';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'next-intl';
import useMount from 'hooks/use-mount';

import NotificationItem from 'Components/Notifications/Item';
import NoResult from 'Components/Dumb/NoResult';

import { unreadCountAsBadge } from 'utils/handlers';
import { NOTIFICATIONS } from 'utils/constants';

import {
  getNotificationList,
  unreadCounts,
  getLoggedUserData,
} from 'store/reselect';
import { getNotifications, getNotificationsList } from 'store/actions';

import useStyles from '../styles';

const notificationsLimit = 6;

const Notifications = () => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getLoggedUserData());
  const notificationsCounts = useSelector(unreadCounts('notifications'));
  const notifications = useSelector(getNotificationList());
  const classesDropDown = useStyles();

  useMount(() => {
    dispatch(
      getNotificationsList({
        accountId: user.id,
        page: 0,
        limit: 10,
      }),
    );
  });

  const outOfRange = notifications?.result.length > notificationsLimit;

  const limitedNotification = outOfRange
    ? notifications.result.slice(0, 6)
    : notifications?.result;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useMount(() => {
    dispatch(
      getNotifications({
        dataType: 'popup',
        page: 0,
        limit: NOTIFICATIONS.countLimit.popup,
        getUnreadCount: true,
      }),
    );
  });

  return (
    <div
      className="notifications-wrapper"
      data-icon-active={open}
      data-intro="notification-icon"
    >
      <span
        className="icon-Notification"
        onClick={event => setAnchorEl(event.currentTarget)}
      ></span>
      {unreadCountAsBadge(notificationsCounts)}
      <Popover
        classes={classesDropDown}
        id={id}
        open={open}
        anchorEl={anchorEl}
        PaperProps={{
          className: 'custom-notification-popover',
          style: { width: '400px' },
        }}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {notifications?.result.length ? (
          <>
            {limitedNotification?.map((item, index) => (
              <NotificationItem
                isDialogPopUp
                key={`${item.id}${index}`}
                {...item}
              />
            ))}
            <div onClick={() => setAnchorEl(null)}>
              <Link href={'/my-sexjobs/notifications'} prefetch={false}>
                <span className="see-all-notifications_popover">
                  {t('show_all_notifications_button')}
                </span>
              </Link>
            </div>
          </>
        ) : (
          <NoResult noBg style={{ color: '#868686', padding: '10px 0' }} />
        )}
      </Popover>
    </div>
  );
};
export default Notifications;
