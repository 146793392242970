import { Container, Row, Col } from 'reactstrap';

import { Button } from 'Components/Shared/UI';

import useStyles from './styles';

const Boundary = () => {
  const styles = useStyles();
  return (
    <Container>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={{ offset: 1, size: 10 }}
          lg={{ offset: 3, size: 6 }}
          xl={{ offset: 3, size: 6 }}
        >
          <div className={styles.root}>
            <h1 style={{ color: '#fff', fontWeight: 'bold' }}>Oops...</h1>
            <div className={styles.description}>
              <h3>Something went wrong</h3>
              <p>Please Refresh the page</p>
              <Button
                onClick={() => window.location.reload()}
                width={220}
                type="submit"
                direction="center"
                typeButton="primary"
                margin={[30, 0, 0, 0]}
                text="Refresh"
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Boundary;
