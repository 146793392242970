import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useMemoSelector from 'hooks/useMemoSelector';

import { getUserAccountId } from 'store/reselect';

import API from 'services/api';

const useFetchOnRouteChange = () => {
  const router = useRouter();

  const { accountId } = useMemoSelector(store => ({
    accountId: getUserAccountId(store),
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await API.updateLastActivityDate({ accountId });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const handleRouteChange = url => {
      const isInProfileRoute = url.includes('/my-sexjobs');

      if (isInProfileRoute) {
        fetchData();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [accountId, router.events]);
};

export default useFetchOnRouteChange;
