import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
// TODO:: need to remove
import thunk from 'redux-thunk';
import { createInjectorsEnhancer } from 'redux-injectors';

import { createReducer } from './rootReducer';
import rootSaga from './rootSaga';
import initialStore from './initialStore';

// TODO:::: Need to refactor
// eslint-disable-next-line import/no-mutable-exports
let store = {
  getState: (store = initialStore) => store,
  subscribe: () => {},
};

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const { run: runSaga } = sagaMiddleware;
  const enhancers = [
    applyMiddleware(sagaMiddleware, thunk),
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];
  store = createStore(
    createReducer,
    initialState,
    composeEnhancers(...enhancers),
  );

  store.sagaTask = runSaga(rootSaga);

  return store;
}

export default configureStore;

export { store };
