import React, { useCallback } from 'react';
import variablesJSS from 'static/styles/jss/abstracts/variables';
import { useTranslations } from 'next-intl';

import useStyles from './styles';

const InputLikeSelectWithScroll = props => {
  const t = useTranslations();
  const styles = useStyles({
    size: props.size,
    transitionDuration: true ? '400ms' : '0s',
    disabledColor: props.theme === 'dark' ? '#0a29559e' : '#ffffffa8',
    fontSize: variablesJSS.$input[`$${props.size}`].$fontSize,
    padding: variablesJSS.$input[`$${props.size || 'md'}`].$padding,
    textColor:
      props.theme === 'dark'
        ? variablesJSS.$select.$dark.$textColor
        : variablesJSS.$select.$textColor,
    iconColor:
      props.theme === 'dark' ? variablesJSS.$input.$theme.$darkColor : '#000',
    backgroundColor: props.customStylesInput.background,
    labelColor:
      (props.label && props.label.color) || variablesJSS.$input.$baseColor,
    marginBottom: '10px',
    placeholderColor:
      props.theme === 'dark'
        ? variablesJSS.$select.$dark.$emptyTextColor
        : variablesJSS.$select.$emptyTextColor,
  });

  const inlineStylesParent = () => ({
    ...props.marginForInput,
    width: props.fullWidth ? `calc(100% - 1px)` : props.width,
    ...(props.label
      ? {
          display: 'flex',
          flexDirection: 'column',
        }
      : {}),
    ...(!props.label
      ? {
          display: 'block',
        }
      : {}),
    ...(props.customStylesDiv ? props.customStylesDiv : {}),
  });

  const generateClassNameParent = () =>
    `${
      styles[props.theme ? `theme-${props.theme}` : '']
    } ${`wrapper-${props.size}`} ${styles['input-wrap']} ${
      props.errors ? 'error-field' : ''
    }`;

  return (
    <div style={inlineStylesParent()} className={generateClassNameParent()}>
      {props.label && (
        <label className={`label-${props.size}`}>
          {props.label.title + (props.required ? ' *' : '')}
        </label>
      )}
      <div
        className="input-element-wrapper"
        style={props.customStylesInput}
        data-disabled={props.disabled}
      >
        {!props.value ? (
          <span className="placeholder-option">{props.placeholder}</span>
        ) : null}
        {props.options.map(span => (
          <span
            key={span.value}
            className={`${
              span.value === props.value ? 'active-option' : ''
            } every-option`}
          >
            {t(span.title)}
          </span>
        ))}
        {
          <div className={'arrows-scroll-select'}>
            <svg
              onClick={() => {
                props.value !== props.options[0].value &&
                  props.change(Number(props.value) - 1);
              }}
              className={`left-icon ${
                props.value === props.options[0].value ? 'disable-left' : ''
              }`}
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M7 10l5 5 5-5z"></path>
            </svg>
            <svg
              onClick={() => {
                props.value !== props.options[props.options.length - 1].value &&
                  props.change(Number(props.value) + 1);
              }}
              className={`right-icon ${
                props.value === props.options[props.options.length - 1].value
                  ? 'disable-right'
                  : ''
              }`}
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M7 10l5 5 5-5z"></path>
            </svg>
          </div>
        }
      </div>
      {(props.helperText || props.required) && !props.errors && (
        <div className="bottom-wrap">
          {
            <p className="helper-text">
              {props.helperText ||
                (props.required ? t('required_field_text') : '')}
            </p>
          }
        </div>
      )}
      {props.errors && <span className={'error-line'}>{props.errors}</span>}
    </div>
  );
};

InputLikeSelectWithScroll.defaultProps = {
  size: 'md',
  type: 'text',
  fullWidth: false,
  margin: 0,
  required: false,
  theme: 'light',
  customStylesInput: {},
  customStylesDiv: {},
  options: [],
};

export default React.memo(InputLikeSelectWithScroll);
