import { all, call, put, takeEvery } from 'redux-saga/effects';

import API from 'services/api';

import Types from '../types';

export function* getCredits({ id }) {
  try {
    const { data, message } = yield call(API.getPricesInventory, id);

    if (message) {
      throw new Error(message);
    }

    yield put({
      type: Types.SAVE_CREDITS,
      payload: data.prices,
    });
  } catch (error) {
    yield put({
      type: Types.SOMETHING_WENT_WRONG,
      payload: { err_message: error.message || error },
    });
  }
}

export default function* creditsSaga() {
  yield all([takeEvery(Types.GET_CREDITS, getCredits)]);
}
