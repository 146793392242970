export default {
  online: [
    {
      id: 1,
      accountType: 'company',
      title: 'Gepassioneerde stoute dame',
      category: 'Dames van plaizer',
      position: 140,
      status: 1,
      avatarSrc: '/images/profileAvatar.jpg',
      advertisers: [
        {
          avatarSrc: '/images/profileAvatar.jpg',
          title: 'Lisa',
          email: 'lisa@hotmail.com',
        },
        { avatarSrc: '', title: 'Katerina', email: 'katriencupd@hotmail.com' },
        { avatarSrc: '', title: 'Coravan', email: 'coravanmora@hotmail.com' },
      ],
      labels: ['Trio (v/v)', 'Cardate', 'GFE'],
      viewed: 10,
      installed: '22-08-2019',
      activeAds: [
        {
          type: 'gold',
          start: 1562276040000,
          end: 1562448840000,
        },
        {
          type: 'silver',
          start: 1562276040000,
          end: 1562448840000,
        },
        {
          type: 'sticker',
          stickers: ['BEREGEIL'],
          start: 1562276040000,
          end: 1562448840000,
        },
        {
          type: 'sticker',
          stickers: ['BEREGEIL 2'],
          start: 1562276040000,
          end: 1562448840000,
        },
      ],
    },
    {
      id: 2,
      accountType: 'person',
      title: 'Gepassioneerde stoute dame 2',
      category: 'Dames van plaizer',
      position: 140,
      avatarSrc: '',
      status: 1,
      labels: ['Trio (v/v)', 'Cardate', 'GFE'],
      viewed: 10,
      installed: '22-08-2019',
      activeAds: [
        {
          type: 'gold',
          start: 1562276040000,
          end: 1562448840000,
        },
        {
          type: 'silver',
          start: 1562276040000,
          end: 1562448840000,
        },
      ],
    },
    {
      id: 3,
      accountType: 'company',
      title: 'Gepassioneerde stoute dame',
      category: 'Dames van plaizer',
      position: 140,
      avatarSrc: '',
      status: 1,
      advertisers: [
        { avatarSrc: '', title: 'Lisa', email: 'lisa@hotmail.com' },
        { avatarSrc: '', title: 'Katerina', email: 'katriencupd@hotmail.com' },
        { avatarSrc: '', title: 'Coravan', email: 'coravanmora@hotmail.com' },
      ],
      labels: ['Trio (v/v)', 'Cardate', 'GFE'],
      viewed: 10,
      installed: '22-08-2019',
    },
  ],
  offline: [
    {
      id: 4,
      accountType: 'company',
      title: 'Gepassioneerde stoute dame',
      category: 'Dames van plaizer',
      position: 140,
      status: 0,
      avatarSrc: '',
      advertisers: [
        { avatarSrc: '', title: 'Lisa', email: 'lisa@hotmail.com' },
        { avatarSrc: '', title: 'Katerina', email: 'katriencupd@hotmail.com' },
        { avatarSrc: '', title: 'Coravan', email: 'coravanmora@hotmail.com' },
      ],
      labels: ['Trio (v/v)', 'Cardate', 'GFE'],
      viewed: 10,
      installed: '22-08-2019',
      activeAds: [
        {
          type: 'gold',
          start: 1562276040000,
          end: 1562448840000,
        },
        {
          type: 'silver',
          start: 1562276040000,
          end: 1562448840000,
        },
        {
          type: 'sticker',
          stickers: ['BEREGEIL'],
          start: 1562276040000,
          end: 1562448840000,
        },
      ],
    },
  ],
  concepts: [
    {
      id: 5,
      avatarSrc: '',
      accountType: 'suppose',
      title: 'Gepassioneerde stoute dame3',
      category: 'Dames van plaizer',
      position: 140,
      status: 3,
      labels: ['Trio (v/v)', 'Cardate', 'GFE'],
      viewed: 10,
      installed: '22-08-2019',
      activeAds: [
        {
          type: 'gold',
          start: 1562276040000,
          end: 1562448840000,
        },
        {
          type: 'silver',
          start: 1562276040000,
          end: 1562448840000,
        },
        {
          type: 'sticker',
          stickers: ['BEREGEIL'],
          start: 1562276040000,
          end: 1562448840000,
        },
      ],
    },
    {
      id: 6,
      avatarSrc: '',
      accountType: 'suppose',
      title: 'Gepassioneerde stoute dame222',
      category: 'Dames van plaizer',
      position: 140,
      status: 3,
      labels: ['Trio (v/v)', 'Cardate', 'GFE'],
      viewed: 10,
      installed: '22-08-2019',
      activeAds: [
        {
          type: 'gold',
          start: 1562276040000,
          end: 1562448840000,
        },
        {
          type: 'silver',
          start: 1562276040000,
          end: 1562448840000,
        },
        {
          type: 'sticker',
          stickers: ['BEREGEIL'],
          start: 1562276040000,
          end: 1562448840000,
        },
      ],
    },
  ],
};
