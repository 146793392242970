import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  'date-of-birth-container': {
    width: '100%',
    '& .like-field-label': {
      color: 'rgb(255, 255, 255)',
      marginBottom: '0px',
      paddingBottom: '7px',
      fontWeight: 'bold',
      fontSize: '13px',
    },
    '& .wrapper-fields': {
      display: 'flex',
      marginBottom: '5px',
    },
    '&.with-error-fields': {
      '& .like-field-label': {
        color: variables.$danger,
      },
    },
  },
});
