import { memo } from 'react';
import { BsFillGeoAltFill } from 'react-icons/bs';
import useMemoSelector from 'hooks/useMemoSelector';

import { getPopularCities } from 'store/reselect';

import { useLocationStyles } from '../styles';

import Location from './Location';

const PopularCities = () => {
  const popularCities = useMemoSelector(getPopularCities);

  const { countryStyle, locationWrapper, settlementStyle, locationIconStyle } =
    useLocationStyles();

  return popularCities.map(({ city, count }) => (
    <Location adCount={count} key={city} name={city} locationKey="popular_city">
      <BsFillGeoAltFill className={locationIconStyle} />
      <div>
        <div className={locationWrapper}>
          <span className={settlementStyle}>{city}</span>
          <span className={countryStyle}>The Netherlands</span>
        </div>
      </div>
    </Location>
  ));
};

export default memo(PopularCities);
