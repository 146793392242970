import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'next-intl';

import { setErrorMessage } from 'store/actions';
import { baseSelector } from 'store/reselect';

const ErrorHandlingToast = props => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const baseState = useSelector(baseSelector());
  const { disableErrorToast, errorMessage } = baseState;

  useEffect(() => {
    !disableErrorToast &&
      errorMessage &&
      toast(t(errorMessage.err_message), {
        autoClose: 4000,
        type: errorMessage.type || 'error',
        onClose: () => {
          dispatch(setErrorMessage(null));
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return props.children;
};
export default ErrorHandlingToast;
