import { memo } from 'react';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import useMount from 'hooks/use-mount';
import useMemoSelector from 'hooks/useMemoSelector';

import { getUnreadCount } from 'store/actions';
import { getUnreadMessageCount, getUserAccountId } from 'store/reselect';

const MAX_COUNT = 9;

const Messages = () => {
  const dispatch = useDispatch();

  const { unreadCount, accountId } = useMemoSelector(store => ({
    accountId: getUserAccountId(store),
    unreadCount: getUnreadMessageCount(store),
  }));

  useMount(() => {
    if (accountId) {
      dispatch(getUnreadCount(accountId));
    }
  });

  return (
    <Link href="/my-sexjobs/chat">
      <div className="messages-wrapper">
        <span className="icon-Message-_-Mail"></span>
        {unreadCount ? (
          <span className="count--block">
            {unreadCount > MAX_COUNT ? `${MAX_COUNT}+` : unreadCount}
          </span>
        ) : null}
      </div>
    </Link>
  );
};
export default memo(Messages);
