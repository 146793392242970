import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import useOnClickOutside from 'hooks/use-onClick-outside';
import useMemoSelector from 'hooks/useMemoSelector';

import { Select } from 'Components/Shared/UI';

import { NEARBY, ALL_DISTANCES, DEFAULT_DISTANCE } from 'utils/constants';

import { getIsSearchSuggestionsOpen } from 'store/reselect';

import Suggestions from './suggestions';
import SearchBar from './components/SearchBar';
import { useSelectStyle } from './styles';

const SearchWithSuggestions = ({
  onFocus,
  isMobile,
  autoFocus,
  searchValue,
  suggestions,
  headerHeight,
  onClickOutside,
  setSearchValue,
  setSuggestions,
  hasDistancesOption,
  isMobileSearchOpen,
  setIsSuggestionsOpen,
}) => {
  const searchInputRef = useRef();
  const searchWrapperRef = useRef();
  const router = useRouter();
  const t = useTranslations();

  const DISTANCES = [
    { title: t('distances_all'), value: 'all' },
    { title: '<5 KM', value: '5' },
    { title: '<10 KM', value: '10' },
    { title: '<15 KM', value: '15' },
    { title: '<25 KM', value: '25' },
    { title: '<50 KM', value: '50' },
  ];

  const { isSuggestionsOpen } = useMemoSelector(store => ({
    isSuggestionsOpen: getIsSearchSuggestionsOpen(store),
  }));

  const { searchSuggestions } = useSelectStyle({
    isSuggestionsOpen,
    isMobileSearchOpen,
  });

  const [shouldRemoveDistance, setShouldRemoveDistance] = useState(true);
  const [selectedDistance, setSelectedDistance] = useState(
    router.query.distance || DISTANCES[0].value,
  );

  const isSearch = router.query.slug?.includes('search');
  const isNearby = router.query.type === 'nearby';

  // useLockBodyScroll([isSuggestionsOpen]);

  useOnClickOutside(searchWrapperRef, () => onClickOutside());

  useEffect(() => {
    if (autoFocus) {
      searchInputRef.current?.focus();
    }
  });

  useEffect(() => {
    if (!searchValue) {
      setSelectedDistance(ALL_DISTANCES);
    }
  }, [searchValue]);

  useEffect(() => {
    if (selectedDistance !== ALL_DISTANCES) {
      router.push({
        query: {
          ...router.query,
          distance: selectedDistance,
        },
      });
    } else if (router.query.distance && shouldRemoveDistance) {
      setSelectedDistance(ALL_DISTANCES);
      const { distance, ...rest } = router.query;
      router.push({
        query: {
          ...rest,
        },
      });
    }
    return () => setShouldRemoveDistance(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistance]);

  useEffect(() => {
    if (isNearby) {
      const nearbyText = t(NEARBY);
      setSearchValue(nearbyText);
      setSelectedDistance(DEFAULT_DISTANCE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNearby]);

  return (
    <div ref={searchWrapperRef} className={searchSuggestions}>
      <div style={{ display: 'flex' }}>
        <SearchBar
          onFocus={onFocus}
          isSearch={isSearch}
          isMobile={isMobile}
          ref={searchInputRef}
          suggestions={suggestions}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setSuggestions={setSuggestions}
          isSuggestionsOpen={isSuggestionsOpen}
          hasDistancesOption={hasDistancesOption}
          setIsSuggestionsOpen={setIsSuggestionsOpen}
          setShouldRemoveDistance={setShouldRemoveDistance}
        />
        {hasDistancesOption && (
          <Select
            width={140}
            name="distances"
            options={DISTANCES}
            value={selectedDistance}
            className="distances-select"
            onChange={e => setSelectedDistance(e.target.value)}
          />
        )}
      </div>

      <div className="suggestions-wrapper">
        <Suggestions
          isMobile={isMobile}
          searchValue={searchValue}
          suggestions={suggestions}
          headerHeight={headerHeight}
          setSearchValue={setSearchValue}
        />
      </div>
    </div>
  );
};

export default SearchWithSuggestions;
