import PropTypes from 'prop-types';

import useStyles from './styles';
import Logged from './logged';
import NotLogged from './not-logged';

const AuthPanel = ({ isLogged }) => {
  const styles = useStyles();

  return (
    <div
      className={`${styles['auth-wrapper']} ${
        isLogged ? 'logged-auth-panel' : 'guest-auth-panel'
      }`}
    >
      {isLogged ? <Logged /> : <NotLogged />}
    </div>
  );
};
AuthPanel.propTypes = {
  isLogged: PropTypes.bool,
};
export default AuthPanel;
