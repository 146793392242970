import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { BsFillGeoAltFill } from 'react-icons/bs';

import { Cookie } from 'utils/handlers';

import { setUserGeolocation } from 'store/actions/base';

import { useSuggestionItemStyle } from '../styles';

const Nearby = ({ t, handleSubmit }) => {
  const dispatch = useDispatch();
  const { suggestionItem, nearbyIcon, nearbyText } = useSuggestionItemStyle();

  const handleClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const userGeolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          Cookie.setCookieByKey('location', userGeolocation);

          dispatch(setUserGeolocation(userGeolocation));

          handleSubmit({ type: 'nearby' });
        },
        error => {
          console.error('Error getting geolocation:', error);
        },
      );
    } else {
      console.log('Geolocation is not available in this browser.');
    }
  };

  return (
    <a
      href="#"
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          handleClick();
        }
      }}
      onClick={e => {
        e.preventDefault();
        handleClick();
      }}
    >
      <li className={suggestionItem} onClick={handleClick}>
        <BsFillGeoAltFill className={nearbyIcon} />
        <div>
          <span className={nearbyText}>{t('nearby')}</span>
        </div>
      </li>
    </a>
  );
};

export default memo(Nearby);
