import DialogTitle from '@material-ui/core/DialogTitle';
import { styled, makeStyles } from '@material-ui/core/styles';

export const useCookiesStatementStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
    width: '100%',
    '& .MuiTypography-root': {
      margin: 0,
      color: '#000',
    },
    '& .MuiDialogContentText-root': {
      fontWeight: 'bold',
    },
    '& .MuiAccordion-root': {
      boxShadow: 'none',
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      padding: '5px 12px',
    },
    '& .MuiAccordionSummary-content': {
      margin: '5px 0',
    },
    '& .MuiAccordion-root::before': {
      backgroundColor: '#fff',
    },
    '& .MuiAccordionSummary-root': {
      padding: '0 4px',
      minHeight: '30px',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cookieBody: {
    width: '100%',
    display: 'flex',
    marginBottom: 10,
    '& p:nth-of-type(1)': {
      width: '130px',
      fontWeight: 'bold',
    },
    '& p:nth-of-type(2)': {
      width: '80%',
    },
  },
}));

export const useFormControlStyle = makeStyles({
  formControl: {
    marginBottom: 20,
    '& label': {
      margin: 0,
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      padding: '0 15px 0 0',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#b0f2f1',
    },
  },
});

export const useLegalInformationStyles = makeStyles({
  dialogTitle: {
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  legalInformationDialogContent: {
    '& .legal-information-wrapper': {
      padding: '15px',
      '& ol': {
        padding: 0,
        '& .list-content': {
          margin: '15px 0',
        },
      },

      '& .list-title ol': {
        paddingInlineStart: 0,
      },
      '& ol.privacy-parent-list ol': {
        paddingInlineStart: '15px',
      },
      '& ol .list-item-title': {
        fontWeight: 'bold',
        margin: '20px 0',
      },
      '& ol.parent-list, & ol.privacy-parent-list, & .parent-list ol': {
        counterReset: 'section',
        listStyleType: 'none',
      },

      '&  ol.parent-list li::before, & ol.privacy-parent-list > li::before': {
        counterIncrement: 'section',
        content: "counters(section, '.') '. '",
      },
    },
  },
  linkBlock: {
    '& span': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '&.bold, &.agreeContent': {
      fontWeight: 600,
    },
  },
  labelSwitch: {
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: '#b0f2f1',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#b0f2f1',
    },
  },
  button: {
    '&.MuiButtonBase-root': {
      fontSize: 12,
      borderRadius: 0,
      color: '#fff',
    },
    '&.agree': {
      background:
        'linear-gradient(0deg, rgba(93,158,0,1) 0%, rgba(121,208,3,1) 100%)',
    },
    '&.red': {
      background: '#ed1c25',
    },
    '&.back': {
      background:
        'linear-gradient(0deg, rgba(3,74,208,1) 0%, rgba(52,120,209,1) 100%)',
    },
    '&:disabled': {
      boxShadow: 'none',
      color: 'rgba(0, 0, 0, 0.26)',
      background: 'rgba(0, 0, 0, 0.12)',
    },
  },
});

export const StyledDialogTitle = styled(DialogTitle)({
  '& > h2': {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    '& .language-wrap img': {
      width: '22px',
      border: '2px solid #fff',
      cursor: 'pointer',
      height: '22px',
      objectFit: 'cover',
      borderRadius: '50%',
    },
  },
});
