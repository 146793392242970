import { memo } from 'react';
import PropTypes from 'prop-types';

import { UI } from 'utils/constants';

import useStyles from './styles';

const InlineNotification = props => {
  const styles = useStyles();

  return (
    <div
      style={props.style || {}}
      className={`${
        styles['inline-notification-container']
      } ${`notification-${props.type}`} custom-toast-inline`}
    >
      {props.type !== 'default' ? (
        <img src={`/images/${props.type}.png`} />
      ) : (
        ''
      )}
      <div className="notification-label-content">
        <h5>{props.title}</h5>
        <p>{props.description}</p>
      </div>
    </div>
  );
};

InlineNotification.defaultTypes = {
  closeIcon: false,
  type: 'default',
  title: '',
  description: '',
};
InlineNotification.propTypes = {
  // closeIcon: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf([...UI.toastTypes]),
};
export default memo(InlineNotification);
