import Types from '../types';

export const setProfiles = payload => ({
  type: Types.SET_ACCOUNT_PROFILES,
  payload,
});

export const setUserData = payload => ({
  type: Types.SET_USER_DATA,
  payload,
});

export const setLoginByDialog = payload => ({
  type: Types.SET_LOGIN_BY_DIALOG,
  payload,
});

export const updateProfileData = payload => ({
  type: Types.UPDATE_PROFILE_DATA,
  payload,
});

export const deleteProfile = payload => ({
  type: Types.DELETE_PROFILE,
  payload,
});

export const createProfile = payload => ({
  type: Types.CREATE_PROFILE,
  payload,
});

export const changeEmail = payload => ({
  type: Types.CHANGE_EMAIL,
  payload,
});

export const setLanguage = payload => ({
  type: Types.SET_LANGUAGE,
  payload,
});

export const deleteAccount = payload => ({
  type: Types.DELETE_ACCOUNT,
  payload,
});

export const addPW = payload => ({
  type: Types.ADD_P_W,
  payload,
});

export const deletePW = payload => ({
  type: Types.DELETE_P_W,
  payload,
});

export const acceptCookies = payload => ({
  type: Types.ACCEPT_COOKIES,
  payload,
});
