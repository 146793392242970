import { memo } from 'react';
import { useTranslations } from 'next-intl';

import { useSearchByTextStyles } from '../styles';

const SearchByText = ({ handleSubmit, searchValue }) => {
  const t = useTranslations();

  const { searchByTextStyle } = useSearchByTextStyles();

  const searchByText = `${t('search_by_text')}: ${searchValue}`;

  return (
    <a
      href="#"
      style={{ color: 'black', opacity: '70%', fontWeight: 'bold' }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          handleSubmit({ term: searchValue });
        }
      }}
    >
      <li
        className={searchByTextStyle}
        onClick={() => handleSubmit({ term: searchValue })}
        style={{
          padding: '9px 20px',
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
      >
        {searchByText}
      </li>
    </a>
  );
};

export default memo(SearchByText);
