import { createSelector } from 'reselect';

import { createAdsFilterObject } from 'utils/handlers';

import { _msgState } from './selectors';

const msgSelector = () => createSelector([_msgState], items => items);
export const getUnreadMessageCount = store => _msgState(store).unreadCount;

const msgSettings = () => createSelector([_msgState], items => items.settings);
export const getMessageSettings = store => _msgState(store).settings;

export const getIsDiscreteMode = store => getMessageSettings(store).discrete;

const discreteMode = () =>
  createSelector([_msgState], items => !!items.discreteColor);

const isAdDetailsOpen = () =>
  createSelector([_msgState], items => items.profileDetailsToggle);

const getFilters = () => createSelector([_msgState], items => items.filters);

const threadsLoading = () =>
  createSelector([_msgState], items => items.threadsLoading);

const discreteColor = () =>
  createSelector([_msgState], items => items.discreteColor);

const getMutatedFilters = (filters, isCompany) =>
  createSelector([_msgState], () =>
    filters.reduce(
      (acc, item) => {
        if (isCompany) {
          acc.advertisers.push(createAdsFilterObject(item));
          if (item.ads.length) {
            item.ads.forEach(e => {
              acc.advertisements.push({
                ...createAdsFilterObject(e),
                parentId: item._id,
              });
            });
          }
        } else {
          acc.advertisements.push(createAdsFilterObject(item));
        }
        return acc;
      },
      { advertisers: [], advertisements: [] },
    ),
  );

export {
  msgSelector,
  msgSettings,
  discreteMode,
  isAdDetailsOpen,
  getFilters,
  threadsLoading,
  getMutatedFilters,
  discreteColor,
};
