import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'btn-actions-line--block': {
    '& button': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '13px',
    },
  },
});
