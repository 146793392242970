import dynamic from 'next/dynamic';

import SimpleInputSSR from './SimpleInput';

const Input = dynamic(() => import('./SimpleInput').then(_ => _.default), {
  ssr: false,
});

export { SimpleInputSSR };

export default Input;
