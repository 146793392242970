import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  '@keyframes filters-content': {
    from: { transform: 'translate(-376px)' },
    to: { transform: 'translate(0)' },
  },
  asideMenuContainer: {
    background: 'rgba(0,0,0,0.3)',
    backdropFilter: 'blur(3px)',
    width: '100%',
    position: 'fixed',
    top: 0,
    zIndex: 999,
    height: '100%',
  },
  asideMenuContent: {
    animation: '$filters-content 0.4s',
    position: 'relative',
  },
});
