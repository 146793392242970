import { memo } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FaArrowLeft } from 'react-icons/fa';

import safeTranslate from 'utils/safeTranslate';

import { StyledDialogTitle, useLegalInformationStyles } from './styles';

// terms_and_conditions_content
function TermsAndConditions({ handleGoBack }) {
  const { legalInformationDialogContent } = useLegalInformationStyles();
  return (
    <>
      <StyledDialogTitle id="alert-dialog-title">
        <FaArrowLeft style={{ marginBottom: 5 }} onClick={handleGoBack} />{' '}
        {safeTranslate('terms_and_conditions_dialog_title')}
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className={legalInformationDialogContent}
        >
          {safeTranslate('terms_and_conditions_content')}
        </DialogContentText>
      </DialogContent>
    </>
  );
}

export default memo(TermsAndConditions);
