import {
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useScrollDirection } from 'hooks/use-scroll';
import { useSelector, useDispatch } from 'react-redux';
import { dispatch as dispatchBus } from 'hooks/use-bus';
import useResizeNodeObserver from 'hooks/use-resize-node-observer';

import { PAGE_NAMES } from 'utils/constants';

import {
  baseSelector,
  authSelector,
  deviceParams,
  getStickyMySXJBarPosition,
} from 'store/reselect';
import {
  toggleCategoriesMenuMobile,
  setPageData,
  setHeaderRect,
  setTopMySJBar,
} from 'store/actions';

import AuthPanel from './components/auth-panel';
import Search from './components/search';
import useStyles from './styles';

const Header = ({ isAuthLayout, isDiscreteMode, chatLayout }) => {
  const dispatch = useDispatch();
  const { pageData, headerRect = {} } = useSelector(baseSelector());
  const { user } = useSelector(authSelector());
  const stickyMySXJBarPosition = useSelector(getStickyMySXJBarPosition());
  const { deviceType } = useSelector(deviceParams());
  const { nodeWidth } = useResizeNodeObserver();
  const { scrollDir, scrollY } = useScrollDirection();
  const router = useRouter();
  const headerRef = useRef();
  const classes = useStyles();
  const [searchMobileToggle, setSearchMobileToggle] = useState(false);
  const [loginProcessing, setLoginProcessing] = useState(true);
  const [isLogged, setIsLogged] = useState(null); // Need to use as prop instead of state

  const confirmDiscrete = () => {
    dispatchBus({ type: 'EMIT_REQUESTS_LEAVE_CHAT', payload: { path: '/' } });
  };

  const toggleSearchBar = () => {
    setSearchMobileToggle(!searchMobileToggle);
  };

  const gridCols = useMemo(
    () => ({
      'col-1': {
        xs: isAuthLayout ? 4 : 6,
        sm: 6,
        md: 6,
        lg: isAuthLayout ? 6 : 3,
        xl: chatLayout ? 4 : isAuthLayout ? 6 : 2,
      },
      'col-2': {
        xs: { order: 'last', size: 12 },
        sm: { order: 'last', size: 12 },
        md: { order: 'last', size: 12 },
        lg: { order: 0, size: chatLayout ? 6 : 6 },
        xl: { order: 0, size: chatLayout ? 5 : 7 },
      },
      'col-3': {
        xs: isAuthLayout ? 8 : 6,
        sm: 6,
        md: 6,
        lg: isAuthLayout ? 6 : 3,
        xl: isAuthLayout ? 6 : 3,
      },
    }),
    [isAuthLayout, chatLayout],
  );

  useEffect(() => {
    setIsLogged(!!user.id);
    user.hasOwnProperty('processLogin') &&
      setLoginProcessing(user.processLogin);
  }, [user]);

  useEffect(() => {
    const page = PAGE_NAMES.find(e =>
      router.pathname.split('/').find(l => l.indexOf(e) > -1),
    );
    dispatch(
      setPageData({
        ...pageData,
        page: page || 'any',
        layout: isAuthLayout ? 'auth' : 'default',
      }),
    );
  }, [isAuthLayout, router.pathname]);

  useEffect(() => {
    if (typeof window !== undefined && headerRef.current) {
      const _height = headerRef.current.getBoundingClientRect().height;
      const nextWrapper = document.querySelector('#__next');
      dispatch(setHeaderRect(headerRef.current.getBoundingClientRect()));
      nextWrapper.style.paddingTop = `${_height}px`;
      headerRef.current.style.maxWidth = `${
        headerRef.current.parentNode.getBoundingClientRect().width
      }px`;
    }
  }, [headerRef.current, nodeWidth]);

  useEffect(() => {
    if (typeof window !== undefined) {
      const isOpenMobile = searchMobileToggle
        ? headerRef.current.querySelector('form')?.getBoundingClientRect()
            .height + 16 /* MarginTop - absolute margin */
        : 0;
      if (deviceType === 'mobile' && scrollDir) {
        if (
          scrollY > headerRect.height + isOpenMobile &&
          scrollDir === 'down' &&
          stickyMySXJBarPosition !== 0
        ) {
          headerRef.current.style.top = `${
            -headerRect.height - isOpenMobile
          }px`;
          dispatch(setTopMySJBar(0));
        }
        if (
          scrollY > headerRect.height + isOpenMobile &&
          scrollDir === 'up' &&
          stickyMySXJBarPosition !== headerRect.height - isOpenMobile
        ) {
          headerRef.current.style.position = 'fixed';
          headerRef.current.style.top = '0px';
          dispatch(setTopMySJBar(headerRect.height - isOpenMobile));
        }
        if (scrollY === 0) headerRef.current.style.position = 'absolute';
      }
    }
  }, [
    scrollDir,
    scrollY,
    deviceType,
    headerRef.current,
    headerRect,
    searchMobileToggle,
    stickyMySXJBarPosition,
  ]);

  useEffect(() => {
    const handleRouteChange = url => {
      const page = PAGE_NAMES.find(e =>
        url.split('/').find(l => l.indexOf(e) > -1),
      );
      dispatch(
        setPageData({ page: page || 'any', layout: page ? 'auth' : 'default' }),
      );
      setSearchMobileToggle(false);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useLayoutEffect(() => {
    document.body.classList[isDiscreteMode ? 'add' : 'remove']('discrete-mode');
  }, [isDiscreteMode]);

  return (
    <header
      ref={headerRef}
      className={
        classes[
          isDiscreteMode ? 'header-container--discrete' : 'header-container'
        ]
      }
    >
      <div className="base-header-wrapper">
        <Container fluid={chatLayout}>
          <Row>
            <Col
              xs={gridCols['col-1'].xs}
              sm={gridCols['col-1'].sm}
              md={gridCols['col-1'].md}
              lg={gridCols['col-1'].lg}
              xl={gridCols['col-1'].xl}
            >
              <div className={classes['logo-wrapper']} suppressHydrationWarning>
                {/* Todo:: */}
                {!isAuthLayout ? (
                  <span
                    className="icon-Hamburger toggle-categories_menu"
                    onClick={() => dispatch(toggleCategoriesMenuMobile(true))}
                  ></span>
                ) : null}
                <Link href="/" prefetch={false}>
                  <a>
                    <span className="logo-elem">
                      <img alt="logo" src="/images/logo2.svg" />
                    </span>
                  </a>
                </Link>
              </div>
            </Col>
            {!isAuthLayout && (
              <Col
                xs={gridCols['col-2'].xs}
                sm={gridCols['col-2'].sm}
                md={gridCols['col-2'].md}
                lg={gridCols['col-2'].lg}
                xl={gridCols['col-2'].xl}
              >
                <Search headerHeight={headerRef.current?.clientHeight} />
              </Col>
            )}
            <Col
              xs={gridCols['col-3'].xs}
              sm={gridCols['col-3'].sm}
              md={gridCols['col-3'].md}
              lg={gridCols['col-3'].lg}
              xl={gridCols['col-3'].xl}
            >
              <div
                className={classes['mobile-toggle_search-bar']}
                suppressHydrationWarning
              >
                {!loginProcessing && <AuthPanel isLogged={isLogged} />}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="discrete-header-wrapper">
        <p>Messenger Box</p>
        <i className="icon-Home" onClick={confirmDiscrete}></i>
      </div>
    </header>
  );
};
Header.defaultProps = {
  isAuthLayout: false,
  isDiscreteMode: false,
  chatLayout: false,
};
Header.propTypes = {
  chatLayout: PropTypes.bool,
  isAuthLayout: PropTypes.bool,
  isDiscreteMode: PropTypes.bool,
};
export default memo(Header);
